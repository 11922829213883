import { Editor } from "@tinymce/tinymce-react";
import imageCompression from "browser-image-compression";
import { useState } from "react";
import { getCompletions } from "../api";
import SpinnerCustom from "./SpinnerCustom";

type TinyMCEEditorProps = {
  value: any;
  handleEditorChange: (stringifiedHtmlValue: string, fileSize?: number) => void;
};

const TinyMCEEditor = ({ value, handleEditorChange }: TinyMCEEditorProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isEditorLoaded, setIsEditorLoaded] = useState(false);
  const [uploadFileSize, setUploadFileSize] = useState<number>(0);

  const handleEditorSetup = (editor: any) => {
    editor.ui.registry.addButton("AskVAL", {
      text: "Ask VAL",
      icon: "ai",
      tooltip:
        "Highlight your text and click this button to query VAL, your Vertex AI Assistant",
      onAction: (_: any) => {
        const selection = editor.selection.getContent({ format: "text" });

        setIsLoading(true);
        editor.selection.collapse();
        editor.execCommand("InsertHTML", false, '<div class="loading"></div>');
        const loadingWithColour = `<div style="font-weight: bold; color: blue">Processing...</div>`;
        editor.insertContent(loadingWithColour);

        getCompletions(selection)
          .then((response) => {
            const reply = response.data.data;
            const loadingElement = editor.dom.select("div.loading");
            if (loadingElement) {
              editor.dom.remove(loadingElement);
              const replyWithLineBreaks = reply.replace(/\n/g, "<br>");
              const replyWithColour = `<div style="color: blue; white-space: pre-wrap">${replyWithLineBreaks}</div><br/><br/>`;
              editor.insertContent(replyWithColour);
            }
          })
          .catch((error) => {
            const response = error.response;
            const loadingElement = editor.dom.select("div.loading");
            if (loadingElement) {
              const errorMessage = response.data.error.message;
              editor.dom.remove(loadingElement);
              const errorWithColour = `<div style="color: red; white-space: pre-wrap">${errorMessage}</div><br/>`;
              editor.insertContent(errorWithColour);
            }
          })
          .finally(() => {
            setIsLoading(false);
          });
      },
    });

    // Define a function for handling actions
    function handleAction(prompt: any, editor: any, setIsLoading: any) {
      return (_: any) => {
        const selection = editor.selection.getContent({ format: "text" });

        setIsLoading(true);
        editor.selection.collapse();
        editor.execCommand("InsertHTML", false, '<div class="loading"></div>');
        const loadingWithColour = `<div style="font-weight: bold; color: blue">Processing...</div>`;
        editor.insertContent(loadingWithColour);

        getCompletions(prompt + selection)
          .then((response) => {
            const reply = response.data.data;
            const loadingElement = editor.dom.select("div.loading");
            if (loadingElement) {
              editor.dom.remove(loadingElement);
              const replyWithLineBreaks = reply.replace(/\n/g, "<br>");
              const replyWithColour = `<div style="color: blue; white-space: pre-wrap">${replyWithLineBreaks}</div><br/><br/>`;
              editor.insertContent(replyWithColour);
            }
          })
          .catch((error) => {
            const response = error.response;
            const loadingElement = editor.dom.select("div.loading");
            if (loadingElement) {
              const errorMessage = response.data.error.message;
              editor.dom.remove(loadingElement);
              const errorWithColour = `<div style="color: red; white-space: pre-wrap">${errorMessage}</div><br/>`;
              editor.insertContent(errorWithColour);
            }
          })
          .finally(() => {
            setIsLoading(false);
          });
      };
    }

    // Add dropdown menu items
    editor.ui.registry.addMenuButton("QuickVal", {
      text: "Quick VAL",
      icon: "ai-prompt",
      fetch: function (callback: any) {
        const items = [
          {
            type: "menuitem",
            text: "Summarise into Bullet Points",
            onAction: handleAction(
              "Summarise into bullet-points",
              editor,
              setIsLoading,
            ),
          },
          {
            type: "menuitem",
            text: "Translate to English",
            onAction: handleAction(
              "Translate to English: ",
              editor,
              setIsLoading,
            ),
          },
          {
            type: "menuitem",
            text: "Improve Writing",
            onAction: handleAction(
              "Improve the writing, including correcting grammatical and spelling errors and improving sentence structures: ",
              editor,
              setIsLoading,
            ),
          },
          {
            type: "menuitem",
            text: "Change to Professional Tone",
            onAction: handleAction(
              "Change the tone of writing to professional tone in business context: ",
              editor,
              setIsLoading,
            ),
          },
        ];
        callback(items);
      },
    });

    editor.on("init", () => {
      setIsEditorLoaded(true);
    });
  };

  const handleFilePickerCallback = (callback: Function, meta: any) => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");

    input.onchange = (event: Event) => {
      const file = (event.target as HTMLInputElement).files![0];

      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      };

      imageCompression(file, options).then((compressedFile: any) => {
        setUploadFileSize(compressedFile.size / 1024 / 1024);

        if (meta.filetype === "image") {
          const reader = new FileReader();

          reader.onload = () => {
            const base64 = (reader.result as string).split(",")[1];
            callback(`data:${compressedFile.type};base64,${base64}`, {
              alt: compressedFile.name,
            });
          };

          reader.readAsDataURL(compressedFile);
        } else {
          callback(URL.createObjectURL(compressedFile), {
            text: compressedFile.name,
          });
        }
      });
    };

    input.click();
  };

  return (
    <>
      {!isEditorLoaded && <SpinnerCustom />}
      <div className={`${!isEditorLoaded ? "hidden" : ""}`}>
        <Editor
          apiKey={process.env.NEXT_PUBLIC_TINY_MCE_API_KEY}
          value={value}
          init={{
            height: 700,
            menubar: false,
            plugins: [
              "lists",
              "checklist",
              "autosave",
              "powerpaste",
              "table",
              "emoticons",
              "charmap",
              "preview",
              "pagebreak",
              "hr",
              "image",
            ],
            toolbar:
              "undo redo | formatselect | " +
              "fontfamily fontsize blocks bold italic underline strikethrough superscript subscript forecolor backcolor removeformat |" +
              "alignleft aligncenter alignright alignjustify | outdent indent lineheight pagebreak hr | bullist numlist checklist |" +
              "image charmap emoticons table | preview save print |" +
              "restoredraft | AskVAL QuickVal",
            toolbar_mode: "wrap",
            content_style:
              "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }" +
              `.val-message {
                display:flex;
                flex-direction:row;
                margin-top: 2px;
               
                p, h3, ul, ol { margin: 0; }
              
                .heading { font-weight:bold; font-style: italic; }
              
                .question-detail { margin-left:21px; }
              
                .response-detail { 
                  margin-left:15px; 
                  display: flex;
                  flex-direction: column;
                  row-gap: 10px;
                }
              }`,
            relative_urls: false,
            autosave_restore_when_empty: false,
            autosave_retention: "3600m",
            autosave_interval: "5s",
            autosave_prefix: "tinymce-autosave-",
            powerpaste_allow_local_images: true,
            powerpaste_word_import: "prompt",
            color_default_background: "yellow",
            file_picker_callback: handleFilePickerCallback,
            setup: handleEditorSetup,
          }}
          onEditorChange={(a) => handleEditorChange(a, uploadFileSize)}
        />
      </div>
    </>
  );
};

export default TinyMCEEditor;
