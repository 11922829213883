import { ClipboardDocumentIcon } from "@heroicons/react/24/outline";
import Link from "next/link";
import { ReactNode } from "react";
import Markdown from "react-markdown";
import SyntaxHighlighter from "react-syntax-highlighter";
import { atomOneDark } from "react-syntax-highlighter/dist/cjs/styles/hljs";
import remarkGfm from "remark-gfm";

export const VAL_WIDTH_KEY = "widthValStatus";
export const VAL_STATUS_KEY = "showValStatus";
export const VAL_HISTORY_STATUS_KEY = "showValHistory";
export const CHAT_VAL_HISTORY_KEY = "chatValHistory";
export const CHAT_VAL_AGENT_KEY = "chatbotSelectedAgent";
export const SHOW_NOTIFICATION_DELAY = 2000;
export const COPY_STATUS_DELAY = 2000;

export type Command = {
  title: string;
  description: string;
};

export const replaceDoubleBraces = (str: string) => {
  return str
    .replace(/(?:\r\n|\r|\n)/g, "<br>")
    .replace(/{{(.+?)}}/g, (_, g1) => {
      return `<span class="px-1.5 py-0.5 rounded-sm bg-blue-100 text-blue-700">${g1.trim()}</span>`;
    });
};

export const getInternalLinkFromUrl = (hrefUrl: string, hostName: string) => {
  let hostIndex = -1;

  //Check if host has port number
  const port = window.location.port;
  if (port.length > 0) {
    hostIndex =
      hrefUrl.indexOf(`${hostName}:${port}`) + `${hostName}:${port}`.length;
  } else {
    hostIndex = hrefUrl.indexOf(hostName) + hostName.length;
  }

  // url path length
  const pathLength = hrefUrl.length;

  // Just get local url
  const internalLink = hrefUrl.substring(hostIndex, pathLength);

  return internalLink;
};

export const MarkdownContent = ({
  message,
  copyStatus,
  onCopyHandler,
}: {
  message: any;
  copyStatus?: string;
  onCopyHandler?: (children: ReactNode) => void;
  removeMarkdown?: boolean;
}) => {
  const languageRegex = /language-(\w+)/;
  var parsedMessage = message.replace(/<br\s*\/?>/g, " ");
  return (
    <Markdown
      remarkPlugins={[remarkGfm]}
      components={{
        code(props) {
          const { children, className, ...rest } = props;
          const match = className && className.match(languageRegex);
          const language = match ? match[1] : "";
          if (language === "") {
            return (
              <span className="w-fit font-medium whitespace-pre-wrap text-red-800">
                {children}
              </span>
            );
          }
          return (
            <div className="relative my-3 grid">
              {language && (
                <div className="bg-black px-1 text-xs text-gray-300">
                  {language.toUpperCase()}
                </div>
              )}
              {onCopyHandler && (
                <div className="relative">
                  <div
                    className="z-top absolute top-2 right-1 h-4 min-w-[24px] cursor-pointer"
                    title="Copy to clipboard"
                    onClick={() => onCopyHandler(children)}
                  >
                    {copyStatus ? (
                      <span className="text-xs text-gray-500">
                        {copyStatus}
                      </span>
                    ) : (
                      <ClipboardDocumentIcon className="h-5 w-5 cursor-pointer stroke-gray-400 hover:stroke-gray-500" />
                    )}
                  </div>
                  <SyntaxHighlighter
                    language={language}
                    PreTag="div"
                    lineProps={{
                      style: {
                        wordBreak: "break-all",
                        whiteSpace: "pre-wrap",
                      },
                    }}
                    wrapLines={true}
                    style={atomOneDark}
                  >
                    {String(children)}
                  </SyntaxHighlighter>
                </div>
              )}
            </div>
          );
        },
        p(props) {
          return (
            <table className="whitespace-pre-wrap">{props.children}</table>
          );
        },
        table(props) {
          return <table className="table-auto">{props.children}</table>;
        },
        thead(props) {
          return <thead className="bg-gray-100">{props.children}</thead>;
        },
        tbody(props) {
          return <>{props.children}</>;
        },
        tr(props) {
          return <tr className="border-b border-gray-200">{props.children}</tr>;
        },
        td(props) {
          return (
            <td className="border border-gray-200 px-4 py-2">
              {props.children}
            </td>
          );
        },
        th(props) {
          return (
            <th className="border-b border-gray-200 px-4 py-2 text-left font-bold">
              {props.children}
            </th>
          );
        },
        a(props) {
          let internalLink = "";

          // Internal Link => navigate in local
          const hostName = window.location.hostname;
          var isInternal = props.href?.includes(hostName);
          if (isInternal && props.href) {
            internalLink = getInternalLinkFromUrl(props.href, hostName);
            return <Link href={internalLink}>{props.children}</Link>;
          }

          // External Link => open new tab
          return (
            <a href={props.href} target="_blank">
              {props.children}
            </a>
          );
        },
        img(props) {
          return (
            <img
              src={props.src}
              className="h-20 w-20"
              alt={props.alt}
              loading="lazy"
            />
          );
        },
      }}
    >
      {parsedMessage}
    </Markdown>
  );
};
