import { useState } from "react";
import useSWR from "swr";
import { useDebounce } from "use-debounce";
import { getter } from "../../api";
import {
  AutocompletePeopleParams,
  PersonAutocompleteResult,
  UserAutocompleteResult,
} from "../../api/types";
import Avatar from "../Avatar";
import Select from "./Select";

export function formatPersonOption(result: PersonAutocompleteResult) {
  return (
    <div className="text-2xs flex items-center gap-x-3 text-left">
      <div>
        <Avatar name={result?.label} size={32} url={result?.image_url} />
      </div>
      <div>
        <h1 className="font-bold">{result?.label}</h1>
        <p className="text-2xs">{result?.organization}</p>
      </div>
    </div>
  );
}

type PersonSelectProps = {
  filters?: Omit<AutocompletePeopleParams, "q">;
  id?: string;
  showAddButton?: boolean;
  fixWidth?: boolean;
  fullWidth?: boolean;
  placeholder?: string;
  value?: PersonAutocompleteResult[];
  setValue?: (value: PersonAutocompleteResult[]) => void;
  borderSelected?: boolean;
  className?: string;
  onChange?: (value: any) => void;
  isMulti?: boolean;
  isClearable?: boolean;
};

export default function PersonSelect({
  filters,
  showAddButton = true,
  fixWidth = false,
  fullWidth = false,
  placeholder,
  value,
  setValue,
  borderSelected,
  isClearable,
  ...props
}: PersonSelectProps) {
  const [query, setQuery] = useState("");
  const [debouncedQuery] = useDebounce(query, 500);
  const { data: options } = useSWR<UserAutocompleteResult[]>(
    `/api/people_map/autocomplete/people?q=${debouncedQuery}`,
    getter,
    { revalidateOnFocus: false },
  );

  return (
    <Select
      key={filters?.organization_pks}
      options={options}
      formatOptionLabel={formatPersonOption}
      isClearable={isClearable}
      {...props}
      className={`${
        fixWidth ? "w-11/12" : fullWidth ? "w-full" : "w-full max-w-md"
      } ${
        borderSelected !== undefined
          ? borderSelected
            ? "rounded-md border border-blue-900"
            : "rounded-md border border-gray-300"
          : ""
      } ${props.className}`}
      value={value}
      placeholder={placeholder}
      onChange={props.onChange}
      onInputChange={(value) => {
        setQuery(value);
      }}
    />
  );
}
