import { XCircleIcon } from "@heroicons/react/24/solid";
import { ICellRendererParams } from "ag-grid-community";
import Link from "next/link";
import { useState } from "react";
import { deleteOrganizationResearchResult } from "../../../../api";
import { OrganizationResearchResult } from "../../../../api/types";
import Avatar from "../../../Avatar";
import { ConfirmationModal } from "../../../ConfirmationModal";
import { ShowSnackBar } from "../../../Utils/supportMessage";

type ConfigModalProps = {
  params: ICellRendererParams<OrganizationResearchResult>;
};

const OrganizationResearchResultsDeletePopUp = ({
  params,
}: ConfigModalProps) => {
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const deleteOrgSearchResult = () => {
    if (params.data) {
      setIsLoading(true);
      deleteOrganizationResearchResult(params.data.id)
        .then(() => {
          setConfirmDelete(false);
          params.api.applyServerSideTransaction({ remove: [params.node] });
          ShowSnackBar(`You deleted ${params.data?.organization.name}.`);
          setConfirmDelete(false);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => setIsLoading(false));
    }
  };
  return (
    <>
      <XCircleIcon
        className="h-5 w-5 cursor-pointer fill-blue-900"
        onClick={() => {
          setConfirmDelete(true);
        }}
      />
      <ConfirmationModal
        open={confirmDelete}
        title={`Are you sure to delete ${params.data?.organization.name}?`}
        subtitle="This action cannot be undone"
        processing={isLoading}
        processingText={"Deleting..."}
        submitButtonText={"Delete"}
        onClose={() => {
          setConfirmDelete(false);
        }}
        onSubmit={deleteOrgSearchResult}
      />
    </>
  );
};

export const OrganizationResearchResultsNameRenderer = (
  params: ICellRendererParams<OrganizationResearchResult>,
) => {
  if (params.data) {
    return (
      <div className="flex w-full items-center gap-x-4">
        <div className="h-5 w-5">
          <OrganizationResearchResultsDeletePopUp params={params} />
        </div>
        <Link
          className="flex w-full cursor-pointer flex-row items-center gap-x-2"
          href={`/organizations/${params.data?.organization.id}`}
          rel="noreferrer"
        >
          <Avatar
            name={params.data?.organization.name}
            size={32}
            url={params.data?.organization.image_url}
            shape="square"
            orgId={params.data?.organization.id}
            agGridProps={{
              agGrid: params,
              fieldNameUpdate: "organization.image_url",
              dataSourceType: "server",
            }}
          />
          <span className="text-black">{params.data?.organization.name}</span>
        </Link>
      </div>
    );
  } else {
    return <></>;
  }
};
