import { ColDef } from "ag-grid-community";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import Avatar from "../../../Avatar";
import { dateFormatISO } from "../../../utils";
import { OpportunityResearchResultsNameRenderer } from "./OpportunityResearchResultsNameRenderer";

export const OpportunityResearchResultsColumns = () => {
  const colDefs: ColDef[] = [
    {
      field: "Opportunity",
      headerName: "Opportunity",
      sortable: false,
      filter: false,
      minWidth: 300,
      maxWidth: 300,
      cellRenderer: OpportunityResearchResultsNameRenderer,
    },
    {
      field: "content",
      headerName: "Content",
      sortable: false,
      width: 300,
      minWidth: 300,
      filter: false,
      cellRenderer: function (params: any) {
        return <div className="my-1 line-clamp-3">{params.data.content}</div>;
      },
    },
    {
      field: "created_by",
      headerName: "Created By",
      sortable: false,
      unSortIcon: true,
      filter: false,
      minWidth: 140,
      maxWidth: 140,
      cellRenderer: function (params: any) {
        return (
          <div className="flex h-full items-center justify-start">
            <Avatar
              name={params.data?.created_by.name}
              size={32}
              url={params.data?.created_by.image_url}
              className="hidden sm:flex"
            />
            <span className="ml-2 max-w-xs text-ellipsis text-black">
              {params.data?.created_by.name}
            </span>
          </div>
        );
      },
    },
    {
      field: "created_at",
      headerName: "Created On",
      sortable: false,
      unSortIcon: true,
      filter: false,
      minWidth: 140,
      maxWidth: 140,
      cellRenderer: function (params: any) {
        return <div>{dateFormatISO(params.data.created_at)}</div>;
      },
    },
    {
      field: "updated_by",
      headerName: "Updated By",
      sortable: false,
      unSortIcon: true,
      filter: false,
      minWidth: 140,
      maxWidth: 140,
      cellRenderer: function (params: any) {
        return (
          <div className="flex h-full items-center justify-start">
            <Avatar
              name={params.data?.updated_by.name}
              size={32}
              url={params.data?.updated_by.image_url}
              className="hidden sm:flex"
            />
            <span className="ml-2 max-w-xs text-ellipsis text-black">
              {params.data?.updated_by.name}
            </span>
          </div>
        );
      },
    },
    {
      field: "updated_at",
      headerName: "Updated On",
      sortable: false,
      unSortIcon: true,
      filter: false,
      minWidth: 140,
      maxWidth: 140,
      cellRenderer: function (params: any) {
        return <div>{dateFormatISO(params.data.updated_at)}</div>;
      },
    },
  ];
  return colDefs;
};
