type AvatarProps = {
  number: number;
  size: number;
  className?: string;
};

const Avatar = ({ number, size, className }: AvatarProps) => {
  return (
    <div
      className={`flex flex-row items-center justify-center rounded-full bg-slate-300 text-black ${className}`}
      style={{
        width: size,
        height: size,
        fontSize: size / 3,
      }}
    >
      {"+".concat(number.toString())}
    </div>
  );
};

export default Avatar;
