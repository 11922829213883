import { ArrowRightIcon } from "@heroicons/react/24/outline";
import { AxiosError } from "axios";
import { FormikErrors } from "formik";
import Link from "next/link";
import {
  BasicOrganizationDetail,
  Error,
  OrganizationFormValues,
  PydanticError,
} from "../../../api/types";
import Avatar from "../../Avatar";
import { parseAxiosError } from "../../utils";
import { SUPPORT_MESSAGE } from "../../Utils/supportMessage";

export function isValidLinkedInCompanyURL(url: string) {
  const regex = /^(https?:\/\/)?(www\.)?linkedin\.com\/.+/;
  return regex.test(url);
}

type ErrorMessageProps = {
  error: AxiosError | undefined;
  setErrors: (errors: FormikErrors<any>) => void;
};

export const ErrorMessage = ({ error, setErrors }: ErrorMessageProps) => {
  let errorMessage = SUPPORT_MESSAGE;
  if (error && error.response) {
    const res = error.response;
    if (res.status === 422) {
      // Data does not pass backend validation
      const data: PydanticError = res.data;
      let errors: FormikErrors<OrganizationFormValues> = {};
      data.detail.map((value) => {
        errors[value.loc[1] as keyof OrganizationFormValues] = value.msg;
      });
      setErrors(errors);
      errorMessage = <p>Please check values entered</p>;
    } else if (res.status === 404) {
      // No organization
      errorMessage = <p>Organization not found</p>;
    } else if (res.status === 400 || res.status === 403) {
      errorMessage = <p>{parseAxiosError(error)}</p>;
    } else if (res.status === 500) {
      errorMessage = <p>{error.message}</p>;
    } else if (res.status === 409) {
      // Org already exists
      const data: Error<BasicOrganizationDetail> = res.data;
      const organization = data.data;
      errorMessage = (
        <div>
          <p>This organization already exists!</p>
          <Link
            href={`/organizations/${organization.id}`}
            passHref
            legacyBehavior
          >
            <div className="mx-auto mt-3 flex h-28 w-96 cursor-pointer items-center rounded-3xl bg-white p-4 drop-shadow-md">
              <Avatar
                name={organization.name}
                size={64}
                url={organization.image_url}
                orgId={organization.id}
              />
              <div className="m-5 grow">
                <h4 className="font-semibold">{organization.name}</h4>
              </div>
              <div className="mx-auto flex h-6 w-6 items-center justify-center rounded-full bg-blue-900 sm:h-10 sm:w-10">
                <ArrowRightIcon className="h-4 w-4 text-white sm:h-6 sm:w-6" />
              </div>
            </div>
          </Link>
        </div>
      );
    }
  }

  return errorMessage;
};
