import { CheckIcon } from "@heroicons/react/24/outline";
import { PaperClipIcon, XCircleIcon } from "@heroicons/react/24/solid";
import {
  ErrorMessage,
  ErrorMessageProps,
  Field,
  FieldProps,
  FormikErrors,
  FormikTouched,
} from "formik";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import useSWR from "swr";
import { getter } from "../../api";
import {
  AutocompleteResult,
  GroupAutocompleteResult,
  MeetingNoteValues,
  NoteKind,
  Option,
  UserAutocompleteResult,
  Visibility,
} from "../../api/types";
import FileThumbnail from "../FileThumbnail";
import FormCompanySelectField from "../Form/FormCompanySelectField";
import FormPersonSelectField from "../Form/FormPersonSelectField";
import FormTextField from "../Form/FormTextField";
import FormUserSelectField from "../Form/FormUserSelectField";
import { useOpportunity } from "../Hook/useOpportunity";
import AutocompleteMultiselect from "../Select/AutocompleteMultiselect";
import OpportunitySelect from "../Select/OpportunitySelect";
import TinyMCEEditor from "../TinyMCEEditor";
import Tooltip from "../Tooltip";
import { classNames } from "../utils";
import { GLOBAL_GROUP_KEY } from "../Utils/constant";
import VisibilityFieldCustom from "../VisibilityFieldCustom";

interface FieldErrorProps extends ErrorMessageProps {
  name: keyof CreateMeetingNoteParams;
}

export const FieldError = ({ name }: FieldErrorProps) => (
  <ErrorMessage name={name}>
    {(error) => (
      <div className="text-2xs border-red-500 text-red-500">{error}</div>
    )}
  </ErrorMessage>
);

export interface CreateMeetingNoteParams {
  noteKind: NoteKind;
  isMeetingNote: boolean;
  isDraft: boolean;
  title: string;
  types: string[];
  date: string;
  owner: UserAutocompleteResult | null;
  attendees: Option[];
  organizations: Option[];
  opportunities: Option[];
  content: string;
  tags: Option[];
  visibility: Visibility;
  groups: GroupAutocompleteResult[];
  users: UserAutocompleteResult[];
  attachments: File[] | undefined;
  added_attachments: AutocompleteResult[];
  audioAttachments: File[] | undefined;
  added_audioAttachment: AutocompleteResult[];
  index_note: boolean;
  hasBigFileSize: boolean;
}

interface MeetingNoteFormProps {
  values: CreateMeetingNoteParams;
  errors: FormikErrors<MeetingNoteValues>;
  touched: FormikTouched<MeetingNoteValues>;
}

export default function MeetingNoteForm({
  values,
  errors,
  touched,
}: MeetingNoteFormProps) {
  const { types: opportunityTypes } = useOpportunity();
  const router = useRouter();
  const { pk } = router.query;
  const { data: selectedGroup } = useSWR<any>(GLOBAL_GROUP_KEY);
  const { data: autoCompleteMeetingTypes, error } = useSWR<
    AutocompleteResult[]
  >("/api/people_map/autocomplete/meeting_type?q=", getter, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
  });
  const [meetingTypes, setMeetingTypes] = useState<string[]>([]);

  useEffect(() => {
    if (!pk && selectedGroup && values.groups?.length === 0) {
      values.groups = Array.of(selectedGroup);
    }
    // eslint-disable-next-line
  }, [selectedGroup]);

  useEffect(() => {
    if (autoCompleteMeetingTypes) {
      setMeetingTypes(
        autoCompleteMeetingTypes.map((type: AutocompleteResult) => type.label),
      );
    }
    // eslint-disable-next-line
  }, [autoCompleteMeetingTypes]);

  return (
    <>
      <div className="item-centre mt-2 flex flex-col gap-y-6">
        <div className="flex flex-col items-start gap-y-1">
          <label className="mb-1 flex flex-row">
            <div className="text-xs font-semibold">Type(s)</div>
            <div className="h-4 w-4 text-red-600">*</div>
          </label>
          <div className="col-span-3 flex flex-wrap items-center gap-x-4 gap-y-3 text-xs">
            {meetingTypes.map((meetingType) => (
              <Field
                type="checkbox"
                value={meetingType}
                name="types"
                key={meetingType}
              >
                {({ field }: FieldProps<string[]>) => (
                  <label key={meetingType}>
                    <input type="checkbox" {...field} className="peer hidden" />
                    <div
                      className={`flex items-center gap-x-2 ${
                        field.checked
                          ? "cursor-pointer border border-blue-900 bg-blue-100 pr-4 pl-2 text-blue-900"
                          : "border-1 border-gray-500 px-4 hover:border-gray-900 hover:bg-gray-200"
                      } cursor-pointer rounded-lg py-2 text-xs font-medium`}
                    >
                      {field.checked && <CheckIcon className="h-4 w-4" />}
                      {meetingType}
                    </div>
                  </label>
                )}
              </Field>
            ))}
            <FieldError name="types" />
          </div>
        </div>

        <div className="grid grid-cols-1 items-start md:grid-cols-2 md:gap-x-10">
          <div className="flex flex-col gap-y-6">
            <Field
              as={FormTextField}
              customClass={"sm:w-full!"}
              name="title"
              label="Title"
              errors={errors.title}
              touched={touched.title}
              required
            />
            <div className="text-xs">
              <Field name="organizations">
                {({ form: { errors, touched } }: FieldProps) => (
                  <Field
                    id="meeting-note-form-organizations-field"
                    as={FormCompanySelectField}
                    name="organizations"
                    label="Organization"
                    placeholder={"Select Organization"}
                    errors={errors.organizations}
                    touched={touched.organizations}
                    flexible={true}
                    withNinja={true}
                    required={false}
                    isMulti={true}
                    directCreate={false}
                    showAddOrganizationButton={true}
                    onChange={(sourceValue: any) => {
                      if (sourceValue) {
                        values.organizations = sourceValue;
                      } else {
                        values.organizations = [];
                      }
                    }}
                    validate={() => {
                      touched.organizations = true;
                    }}
                  />
                )}
              </Field>
            </div>
            <div className="flex flex-col">
              <div className="text-xs">
                <Field name="contacts">
                  {({
                    field: { name, value },
                    form: { setFieldValue, touched },
                  }: FieldProps) => {
                    if (values.attendees?.length > 0) {
                      touched.attendees = true;
                    }

                    return (
                      <FormPersonSelectField
                        name="attendees"
                        label="Attendees"
                        placeholder="Select Attendees"
                        errors={
                          errors.attendees ? String(errors.attendees) : ""
                        }
                        touched={Boolean(touched.attendees)}
                        isClearable={true}
                        required={false}
                        isMulti={true}
                        fixWidth={false}
                      />
                    );
                  }}
                </Field>
              </div>
              <div>
                <VisibilityFieldCustom values={values.visibility} />
              </div>
              <div className="row-start-4 my-6 flex h-full flex-row items-center gap-x-3">
                <div className="flex flex-col">
                  <div className="flex flex-row items-center">
                    <label className="text-xs font-bold">Index Note</label>
                    <div className="relative mr-2">
                      <Tooltip
                        tooltipClass="w-96"
                        title={""}
                        content={
                          "Indexing a note will cause the content of the note to be ingested by LLMs and searchable via AskVAL. Notes with sensitive and confidential information should not be indexed."
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="flex flex-row items-center">
                  <Field name="index_note">
                    {({ field, form: { setFieldValue } }: FieldProps) => (
                      <label className="relative inline-flex cursor-pointer items-center">
                        <input
                          type="checkbox"
                          defaultChecked={values.index_note}
                          className="peer sr-only"
                          onChange={(event) => {
                            setFieldValue(
                              field.name,
                              event.currentTarget.checked,
                            );
                          }}
                        />
                        <div className="peer h-5 w-9 rounded-full bg-gray-200 peer-checked:bg-blue-900 peer-focus:ring-0 peer-focus:outline-hidden after:absolute after:top-[2px] after:left-[2px] after:h-4 after:w-4 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:after:translate-x-full peer-checked:after:border-white"></div>
                      </label>
                    )}
                  </Field>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-y-6">
            <div>
              <label
                className="mb-1 flex text-xs font-semibold"
                htmlFor="dateField"
              >
                Date
              </label>
              <div>
                <Field name="date">
                  {({ field }: FieldProps) => (
                    <input
                      id="dateField"
                      type="date"
                      className={`form-field w-full ${values.date && "border-blue-900"}`}
                      {...field}
                    />
                  )}
                </Field>
                <FieldError name="date" />
              </div>
            </div>
            <Field>
              {({ form: { values, touched, errors } }: FieldProps) => {
                if (values.owner) {
                  touched.owner = true;
                }
                return (
                  <FormUserSelectField
                    id="owner-field"
                    name="owner"
                    label="Owner"
                    customClass={""}
                    errors={errors.owner ? String(errors.owner) : ""}
                    touched={Boolean(touched.owner)}
                    required={false}
                    isClearable={false}
                  />
                );
              }}
            </Field>

            <div className="flex flex-col">
              <div className="relative">
                <label
                  className="mb-1 flex items-center text-xs font-semibold"
                  htmlFor="opportunitiesField"
                >
                  Opportunities
                  <Tooltip
                    title="What are opportunities?"
                    content="Opportunities are trackable pipeline items with its own individual track. Adding opportunities are essential to ensure accurate pipeline monitoring."
                  />
                </label>
                <div className="text-xs">
                  <Field name="opportunities">
                    {({
                      field,
                      form: { setFieldValue, touched },
                    }: FieldProps) => (
                      <OpportunitySelect
                        id="opportunitiesField"
                        {...field}
                        onChange={(newValue: any) =>
                          setFieldValue(field.name, newValue)
                        }
                        isMulti
                        setValue={(value) => setFieldValue(field.name, value)}
                        opportunityTypes={opportunityTypes}
                        className={`${
                          touched && values.opportunities?.length
                            ? "border border-blue-900!"
                            : ""
                        }`}
                      />
                    )}
                  </Field>
                  <FieldError name="opportunities" />
                </div>
              </div>
            </div>
            <div className="grid-col grid">
              <div className="w-full">
                <label
                  className="mb-1 flex items-center text-xs font-semibold"
                  htmlFor="tagsField"
                >
                  Tags
                  <Tooltip
                    title="What are tags?"
                    content="Tags are free-form entries for easy categorisation of your notes. Tags will be seen for all users if your privacy settings are set to all."
                  />
                </label>
                <div className="text-xs">
                  <Field name="tags" className="">
                    {({
                      field: { name, value },
                      form: { setFieldValue, touched, errors, setFieldTouched },
                    }: FieldProps) => (
                      <>
                        <AutocompleteMultiselect
                          id="tags"
                          placeholder="Select Tags"
                          autocompleteEndpoint="/api/people_map/autocomplete/meeting_note_tags"
                          selected={value}
                          onChange={(newValue) => setFieldValue(name, newValue)}
                          creatable={true}
                          isClearable
                          className={`${
                            touched && value?.length
                              ? "border border-blue-900!"
                              : ""
                          }`}
                          onBlur={() => {
                            if (!values.tags?.length) {
                              setFieldTouched("tags", false);
                            } else {
                              setFieldTouched("tags", true);
                            }
                          }}
                        />
                        <div
                          className={classNames(
                            touched && errors
                              ? "absolute ml-2 text-xs"
                              : "hidden",
                          )}
                        >
                          {errors.tags as any}
                        </div>
                      </>
                    )}
                  </Field>
                  <FieldError name="tags" />
                </div>
              </div>
            </div>
            <div className="flex flex-col">
              <div className="flex flex-col">
                <div className="flex space-x-3">
                  <div className="block text-sm font-semibold">
                    Attachments (32 MB)
                  </div>
                  <PaperClipIcon className="h-4 w-4" />
                </div>
                <Field name="attachments">
                  {({
                    field: { name, value },
                    form: { setFieldValue },
                  }: FieldProps<File[]>) => (
                    <div>
                      <div>
                        <Field name="added_attachments">
                          {({
                            field: { name: fieldName, value: fieldValues },
                            form: { setFieldValue: setAttachmentFieldValues },
                          }: FieldProps<AutocompleteResult[]>) => (
                            <div className="grid-col-auto grid space-y-1">
                              {fieldValues &&
                                fieldValues.map((file) => (
                                  <FileThumbnail
                                    file={file.label}
                                    field={fieldName}
                                    value={fieldValues}
                                    setValue={setAttachmentFieldValues}
                                    key={file.value}
                                  />
                                ))}
                            </div>
                          )}
                        </Field>
                        <div className="mb-1 flex flex-col gap-y-2">
                          {value &&
                            value.map((file) => (
                              <FileThumbnail
                                file={file}
                                field={name}
                                value={value}
                                setValue={setFieldValue}
                                key={file.name}
                              />
                            ))}
                        </div>
                      </div>
                      <Dropzone
                        onDrop={(acceptedFiles) => {
                          // do nothing if no files
                          if (acceptedFiles.length === 0) {
                            return;
                          }
                          // on drop we add to the existing files
                          if (value) {
                            setFieldValue(name, value.concat(acceptedFiles));
                          } else {
                            setFieldValue(name, acceptedFiles);
                          }
                        }}
                      >
                        {({
                          isDragActive,
                          isDragReject,
                          getRootProps,
                          getInputProps,
                        }) => {
                          if (isDragActive) {
                            return (
                              <div
                                className="flex cursor-pointer flex-col items-center justify-evenly rounded-md border-2 border-dashed border-blue-900 bg-blue-100 p-4"
                                {...getRootProps()}
                              >
                                <input {...getInputProps()} />
                                <div className="text-center">
                                  <div className="text-xs font-semibold text-blue-900">
                                    Drop in this file!
                                  </div>
                                </div>
                              </div>
                            );
                          }

                          if (isDragReject) {
                            return (
                              <div
                                className="border-grey-900 flex cursor-pointer flex-col items-center justify-evenly rounded-md border-2 border-dashed border-sky-200 bg-blue-50 p-4"
                                {...getRootProps()}
                              >
                                <input {...getInputProps()} />
                                <XCircleIcon className="h-6 w-6 fill-red-500" />
                                <div className="text-center">
                                  <div className="text-xs font-semibold text-blue-900">
                                    This file cannot be uploaded!
                                  </div>
                                </div>
                              </div>
                            );
                          }
                          return (
                            <div
                              className="flex cursor-pointer flex-col items-center justify-evenly rounded-md border-2 border-dashed border-blue-900 p-4"
                              {...getRootProps()}
                            >
                              <input {...getInputProps()} />
                              <div className="text-center">
                                <div className="text-xs text-blue-900">
                                  Drop files here or{" "}
                                  <span className="text-blue-900 underline">
                                    browse
                                  </span>
                                </div>
                              </div>
                            </div>
                          );
                        }}
                      </Dropzone>
                    </div>
                  )}
                </Field>
                <FieldError name="attachments" />
              </div>
            </div>
            <div className="flex flex-col">
              <div className="flex">
                <div className="block text-sm font-semibold">
                  Audio Transcription Clip
                </div>
                <PaperClipIcon className="ml-3 h-4 w-4" />
                <Tooltip
                  title="Audio Transcription"
                  content=" Once the transcription is completed, an email notification will be sent to you. The transcription service leverages OpenAI's Whisper LLM. Do not use this if your audio clip contains confidential or sensitive information."
                />
              </div>
              <Field name="audioAttachments">
                {({
                  field: { name, value },
                  form: { setFieldValue },
                }: FieldProps<File[]>) => (
                  <div>
                    <div>
                      <Field name="added_audioAttachments">
                        {({
                          field: { name: fieldName, value: fieldValues },
                          form: { setFieldValue: setAttachmentFieldValues },
                        }: FieldProps<AutocompleteResult[]>) => (
                          <div className="grid-col-auto grid space-y-1">
                            {fieldValues &&
                              fieldValues.map((file) => (
                                <FileThumbnail
                                  file={file.label}
                                  field={fieldName}
                                  value={fieldValues}
                                  setValue={setAttachmentFieldValues}
                                  key={file.value}
                                />
                              ))}
                          </div>
                        )}
                      </Field>
                      <div className="mb-1 flex flex-col gap-y-2">
                        {values.audioAttachments &&
                          values.audioAttachments.length > 0 &&
                          values.audioAttachments.map((file) => (
                            <FileThumbnail
                              file={file}
                              field={name}
                              value={value}
                              setValue={setFieldValue}
                              key={file.name}
                            />
                          ))}
                      </div>
                    </div>
                    <Dropzone
                      accept={{
                        "audio/x-m4a": [".m4a"],
                        "audio/mpeg": [".mp3"],
                        "audio/webm": [".webm"],
                        "video/mp4": [".mp4"],
                        "audio/wav": [".wav"],
                        "video/mpeg": [".mpeg"],
                        "audio/mpga": [".mpga"],
                      }}
                      multiple={false}
                      onDrop={(acceptedFiles) => {
                        // do nothing if no files
                        if (acceptedFiles.length === 0) {
                          return;
                        }

                        setFieldValue(name, acceptedFiles);
                      }}
                    >
                      {({
                        isDragActive,
                        isDragReject,
                        getRootProps,
                        getInputProps,
                      }) => {
                        if (isDragActive) {
                          return (
                            <div
                              className="flex cursor-pointer flex-col items-center justify-evenly rounded-md border-2 border-dashed border-blue-900 bg-blue-100 p-4 text-blue-900"
                              {...getRootProps()}
                            >
                              <input {...getInputProps()} />
                              <div className="text-center">
                                <div className="text-xs font-semibold text-blue-900">
                                  Drop in this audio file!
                                </div>
                              </div>
                            </div>
                          );
                        }

                        if (isDragReject) {
                          return (
                            <div
                              className="border-grey-500 flex cursor-pointer flex-col items-center justify-evenly rounded-md border-2 border-dashed border-sky-200 bg-blue-50 p-4"
                              {...getRootProps()}
                            >
                              <input {...getInputProps()} />
                              <XCircleIcon className="h-6 w-6 fill-red-500" />
                              <div className="text-center">
                                <div className="text-xs font-semibold text-blue-900">
                                  This audio file cannot be uploaded!
                                </div>
                              </div>
                            </div>
                          );
                        }
                        return (
                          <div
                            className="flex cursor-pointer flex-col items-center justify-evenly rounded-md border-2 border-dashed border-blue-900 p-4"
                            {...getRootProps()}
                          >
                            <input {...getInputProps()} />
                            <div className="text-center">
                              <div className="text-xs text-blue-900">
                                Drop audio files here or{" "}
                                <span className="text-blue-900 underline">
                                  browse
                                </span>
                                <div>
                                  (Accepts m4a, mp3, webm, wav, mpeg, mpga.)
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      }}
                    </Dropzone>
                  </div>
                )}
              </Field>
              <FieldError name="attachments" />
            </div>
          </div>
        </div>
      </div>

      <div className="z-0 mt-6">
        <label className="mb-1 flex text-xs font-semibold">Content</label>
        <Field name="content">
          {({
            field: { name, value },
            form: { setFieldValue, setErrors, errors },
          }: FieldProps) => (
            <TinyMCEEditor
              value={value}
              handleEditorChange={(stringifiedHtmlValue, fileSize) => {
                setFieldValue("hasBigFileSize", fileSize && fileSize > 5);
                setFieldValue("content", stringifiedHtmlValue);
              }}
            />
          )}
        </Field>
        <FieldError name="content" />
      </div>
    </>
  );
}
