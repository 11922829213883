import { PlusIcon } from "@heroicons/react/24/solid";
import { useField } from "formik";
import { useState } from "react";
import { PersonAutocompleteResult, PersonDetail } from "../../api/types";
import ContactForm from "../ContactForm";
import NewModal from "../NewModal";
import PersonSelect from "../Select/PersonSelect";
import { classNames } from "../utils";

type FormPersonSelectFieldProps = {
  name: string;
  label: string;
  required: boolean;
  errors: string | undefined;
  touched: boolean;
  isMulti?: boolean;
  isClearable?: boolean;
  showAddButton?: boolean;
  customClass?: string;
  fixWidth?: boolean;
  placeholder?: string;
  onBlur?: () => void;
};
const FormPersonSelectField = ({
  name,
  required,
  label,
  errors,
  touched,
  isMulti = false,
  isClearable = false,
  showAddButton = true,
  fixWidth = false,
  customClass,
  placeholder,
  onBlur,
}: FormPersonSelectFieldProps) => {
  const [input, meta, helpers] = useField(name);

  const { value, checked } = input;
  const { setValue } = helpers;

  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="">
      <div className="flex items-end">
        <label className="text-xs font-semibold" htmlFor={name}>
          {label}
        </label>
        <div>
          {required ? (
            <div className="text-sm font-semibold text-red-600">*</div>
          ) : (
            ""
          )}
        </div>
      </div>
      <div className="flex gap-x-2">
        <div
          className={`w-full rounded-md ${customClass} ${
            touched
              ? errors
                ? "border border-red-500"
                : (!isMulti && value) || (isMulti && value?.length > 0)
                  ? "border border-blue-900!"
                  : "border-gray-300"
              : ""
          }`}
          onBlur={onBlur}
        >
          <PersonSelect
            id={name}
            value={value}
            onChange={(newValue) => setValue(newValue)}
            isMulti={isMulti}
            isClearable={isClearable}
            showAddButton={showAddButton}
            fullWidth={true}
            fixWidth={fixWidth}
            placeholder={placeholder}
          />
        </div>
        {showAddButton && (
          <div
            className="mt-2 flex h-5 w-5 cursor-pointer items-center justify-center rounded-full bg-blue-900"
            onClick={() => setIsOpen(true)}
          >
            <PlusIcon className="h-5 w-5 fill-white" />
          </div>
        )}
      </div>
      <div
        className={classNames(
          touched && errors ? "text-2xs text-red-600" : "hidden",
        )}
      >
        {errors}
      </div>
      <NewModal open={isOpen} onClose={setIsOpen}>
        <ContactForm
          callBack={(contact: PersonDetail) => {
            const newContact: PersonAutocompleteResult = {
              label: contact.name,
              value: contact.id,
              organization: contact.organization.name,
              image_url: contact.image_url,
            };
            setValue && setValue([...(value || []), newContact]);
            setIsOpen(false);
          }}
          title="Create Contact"
          isModal={true}
          icon="PlusCircleIcon"
        />
      </NewModal>
    </div>
  );
};

export default FormPersonSelectField;
