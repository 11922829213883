import {
  BoltIcon,
  BriefcaseIcon,
  DocumentTextIcon,
  FunnelIcon,
  GlobeAltIcon,
  HomeIcon,
  MagnifyingGlassIcon,
  SparklesIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";

import { getQuarter, getYear } from "date-fns";
import * as types from "../../api/types";
import { GLOBAL_GROUP_KEY } from "./constant";

const envString = `${process.env.NEXT_PUBLIC_LABS_INSTANCE}` || "default";

type Navigation = {
  name: string;
  key: string;
  icon: any;
  href: string;
  prefixes?: Array<string>;
  query?: string;
};

type NavigationArray = Array<Navigation>;

export let navigation: NavigationArray = [
  { name: "Dashboard", key: "dashboard", icon: HomeIcon, href: "/" },
  {
    name: "People & Organizations",
    key: "people",
    icon: UsersIcon,
    href: "/discover/organizations",
    prefixes: [
      "/discover/people",
      "/discover/organizations",
      "/organizations/[pk]",
      "/people/[pk]",
    ],
  },
  {
    name: "Portfolio",
    key: "portfolio",
    icon: BriefcaseIcon,
    href: "/portfolio",
    prefixes: [
      "/portfolio/financials_summary",
      "/forms",
      "/portfolio/forms_summary",
      "/portfolio/settings",
    ],
  },
  {
    name: "DealFlow",
    key: "dealflow",
    icon: FunnelIcon,
    href: "/dealflow",
    prefixes: ["/dealflow"],
    query: "page=dealflow",
  },
  {
    name: "Partnerships",
    key: "partnerships",
    icon: SparklesIcon,
    href: "/partnerships",
    prefixes: ["/partnerships"],
    query: "page=partnerships",
  },
  {
    name: "Notes",
    key: "notes",
    icon: DocumentTextIcon,
    href: "/meeting_notes",
    prefixes: ["/meeting_notes", "/meeting_notes/[pk]"],
  },
  {
    name: "Vertex Platform",
    key: "vertexplatform",
    icon: GlobeAltIcon,
    href: "/vertex_platform",
    prefixes: ["/vertex_platform"],
  },
  {
    name: "Deal Sourcing",
    key: "dealsourcing",
    icon: MagnifyingGlassIcon,
    href: "/deal_sourcing",
    prefixes: [
      "/deal_sourcing/[pk]",
      "/deal_sourcing/config",
      "/deal_sourcing/dealroom",
      "/deal_sourcing/prompts",
      "/deal_sourcing/techinasia",
    ],
  },
  {
    name: "Signals",
    key: "linkedinsignal",
    icon: BoltIcon,
    href: "/signals",
    prefixes: ["/signals"],
  },
];

export const isValidJson = (str: any) => {
  try {
    JSON.parse(str);
    return true;
  } catch (error) {
    return false;
  }
};

export const getInitialGlobalGroupSelected = () => {
  const option = window.localStorage.getItem(GLOBAL_GROUP_KEY);
  return option && isValidJson(option) ? JSON.parse(option) : "";
};

export const setInitialGlobalGroupSelected = (option: types.Option) => {
  window.localStorage.setItem(GLOBAL_GROUP_KEY, JSON.stringify(option));
};

export const dateFormatISO = (date: string) => {
  if (date && date.length > 0) {
    return new Date(date).toLocaleDateString("en-SG", {
      day: "numeric",
      month: "short",
      year: "numeric",
    });
  }
  return "";
};

export const getMonthYearFromQuarter = (quarterYear: string) => {
  const data = quarterYear.match(/Q(\d)FY(\d{4})/);
  if (quarterYear.length > 0 && data) {
    return {
      quarter: parseInt(data[1]),
      year: parseInt(data[2]),
    };
  }
  const today = new Date();
  return {
    quarter: getQuarter(today),
    year: getYear(today),
  };
};

export const handleFileClick = (fileId: number) => {
  const fileUrl = `/api/people_map/attachment/${fileId}`;
  const anchor = document.createElement("a");
  anchor.href = fileUrl;
  anchor.target = "_blank";
  anchor.click();
};

type NavigationItem = {
  [key: string]: string[];
};

type NavigationItemMap = {
  [key: string]: NavigationItem;
};

export const NavigationKeys: NavigationItemMap = {
  default: {
    "Partnership Group": [
      "dashboard",
      "people",
      "portfolio",
      "partnerships",
      "notes",
      "vertexplatform",
      "linkedinsignal",
    ],
    "Vertex Growth": [
      "dashboard",
      "people",
      "portfolio",
      "dealflow",
      "partnerships",
      "notes",
      "vertexplatform",
      "dealsourcing",
      "linkedinsignal",
    ],
    "Vertex Ventures US": [
      "vertexplatform",
      "people",
      "partnerships",
      "portfolio",
      "dealflow",
      "notes",
      "dealsourcing",
      "linkedinsignal",
    ],
    "Vertex Ventures China": [
      "vertexplatform",
      "people",
      "partnerships",
      "portfolio",
      "dealflow",
      "notes",
      "dealsourcing",
      "linkedinsignal",
    ],
    "Vertex Ventures Israel": [
      "vertexplatform",
      "people",
      "partnerships",
      "portfolio",
      "dealflow",
      "notes",
      "dealsourcing",
      "linkedinsignal",
    ],
    "Vertex Ventures HC": [
      "vertexplatform",
      "people",
      "partnerships",
      "notes",
      "dealsourcing",
      "linkedinsignal",
    ],
    "Vertex Ventures SEA & India": [
      "vertexplatform",
      "people",
      "partnerships",
      "notes",
      "dealsourcing",
      "linkedinsignal",
    ],
    default: [
      "dashboard",
      "people",
      "portfolio",
      "dealflow",
      "partnerships",
      "notes",
      "vertexplatform",
      "dealsourcing",
      "linkedinsignal",
    ],
  },
  vvseai: {
    default: [
      "dealflow",
      "portfolio",
      "notes",
      "people",
      "partnerships",
      "dealsourcing",
      "linkedinsignal",
    ],
  },
  vvhc: {
    default: ["dealflow", "portfolio", "notes", "people", "partnerships"],
  },
};

export const setNavigation = (
  user_group: string = "default",
): NavigationArray => {
  let navigationOrder = NavigationKeys["default"]["default"];
  let instance = NavigationKeys["default"];
  if (envString in NavigationKeys) {
    instance = NavigationKeys[envString];
  }
  if (user_group in instance) {
    navigationOrder = instance[user_group];
  } else {
    navigationOrder = instance["default"];
  }

  return navigationOrder
    .map((key) => navigation.find((item) => item.key === key))
    .filter(Boolean) as Navigation[];
};

export const serverSideTables = ["dealflow"];

export const FunnelStageColor = [
  ["#fde047", "black"], // yellow-300
  ["#86efac", "black"], // green-300
  ["#93C5FD", "black"], // blue-300
  ["#7dd3fc", "black"], // light blue-300
  ["#a5b4fc", "black"], // indigo-300
  ["#d8b4fe", "black"], // purple-300
  ["#fdba74", "black"], // orange-300
  ["#fda4af", "black"], // rose-300
  ["#cbd5e1", "black"], // slate-300
  ["#881337", "white"], // rose-600
];

export const FunnelStageDarkColor = [
  ["#EAB308", "black"], // yellow-600
  ["#22C55E", "black"], // green-600
  ["#3B82F6", "black"], // blue-600
  ["#0EA5E9", "black"], // light blue-600
  ["#6366F1", "black"], // indigo-600
  ["#A855F7", "black"], // purple-600
  ["#71717A", "black"], // slate-600
  ["#F97316", "black"], // orange-600
  ["#E11D48", "black"], // rose-600
];

export const InvestorsColor = [
  ["#FDA4AF", "black"], // rose-300
  ["#93C5FD", "black"], // blue-300
  ["#86EFAC", "black"], // green-300
  ["#FDE047", "black"], // yellow-300
  ["#FB923C", "black"], // orange-300
  ["#D8B4FE", "black"], // purple-300
  ["#cbd5e1", "black"], // slate-300
];

export const FinancialSummaryTableOrder: Record<string, number> = {
  Revenue: 0,
  EBITDA: 1,
  "Net Profit/Loss": 2,
  "Cash Burn": 3,
  "LTM Revenue": 4,
  "LTM EBITDA": 5,
  "Cash Balance": 6,
  "Debt Amount": 7,
  "Net Debt": 8,
  Leverage: 9,
  "No. of Employees": 10,
};

// String key name for table state used for sessionStorage / localStorage
export const OpportunityTableName: string = "OPPORTUNITY_TABLE_NAME";

export const PreviousTableStateId: string = "PREVIOUS_TABLE_STATE_ID";

export const CountryList: string[] = [
  "Afghanistan",
  "Albania",
  "Algeria",
  "Andorra",
  "Angola",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bhutan",
  "Bolivia",
  "Bosnia and Herzegovina",
  "Botswana",
  "Brazil",
  "Brunei",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cabo Verde",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Central African Republic",
  "Chad",
  "Chile",
  "China",
  "Colombia",
  "Comoros",
  "Congo",
  "Costa Rica",
  "Croatia",
  "Cuba",
  "Cyprus",
  "Czechia",
  "Côte d'Ivoire",
  "Democratic Republic of the Congo",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Eswatini",
  "Ethiopia",
  "Fiji",
  "Finland",
  "France",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Greece",
  "Grenada",
  "Guatemala",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Holy See",
  "Honduras",
  "Hong Kong",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran",
  "Iraq",
  "Ireland",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Kosovo",
  "Kuwait",
  "Kyrgyzstan",
  "Laos",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Mauritania",
  "Mauritius",
  "Mexico",
  "Micronesia",
  "Moldova",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Morocco",
  "Mozambique",
  "Myanmar",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "North Korea",
  "North Macedonia",
  "Norway",
  "Oman",
  "Other",
  "Pakistan",
  "Palau",
  "Palestine State",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Poland",
  "Portugal",
  "Qatar",
  "Romania",
  "Russia",
  "Rwanda",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Vincent and the Grenadines",
  "Samoa",
  "San Marino",
  "Sao Tome and Principe",
  "Saudi Arabia",
  "Singapore",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
];

export const getTitle = (navigation: string, title?: string): string => {
  const MAP: Record<string, string> = {
    "/": "Dashboard",
    "/all_opportunity": "Opportunities",
    "/deal_sourcing": "Deal Sourcing",
    "/dealflow": "Dealflow",
    "/discover/organizations": "Discover Organizations",
    "/discover/people": "Discover People",
    "/gtm_project/": `${title}`,
    "/gtm_projects": "GTM Projects",
    "/meeting_notes": "Notes",
    "/meeting_notes/": `${title}`,
    "/opportunity/": `${title}`,
    "/organizations/": `${title}`,
    "/partnerships": "Partnerships",
    "/people/": `${title}`,
    "/portfolio": "Portfolio",
    "/vertex_platform": "VPC",
    "/collab": "Collaboration",
    "/activity": "Activities",
    "/tasks": "Tasks",
    "/activity_logs": "Activities",
    "/help": "Help",
    "/changelog": "Changelog",
    "/settings": "Settings",
    "/askval": "Ask Val",
    "/signals": "Signals",
  };

  return MAP[navigation] ? MAP[navigation] + " | Vertex Labs" : "Vertex Labs";
};
