import { ArrowPathIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import { useMemo, useState } from "react";
import { OpportunityResearch } from "../../../../api/types";
import ErrorModal from "../../../ErrorModal";
import OpportunityResearchResultsTable from "./OpportunityResearchResultsTable";
import { OpportunityResearchResultsContext } from "./OpportunityResearchResultsUtils";

type OpportunityResearchResultsViewProps = {
  OpportunityResearch: OpportunityResearch;
};

export const OpportunityResearchResultsView = ({
  OpportunityResearch,
}: OpportunityResearchResultsViewProps) => {
  const [refreshResult, setRefreshResult] = useState<boolean>(false);
  const [errorModal, setErrorModal] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const contextValue = useMemo(
    () => ({
      refreshResult,
      setRefreshResult,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [refreshResult, OpportunityResearch],
  );

  return (
    <OpportunityResearchResultsContext.Provider value={contextValue}>
      <div className="h-full w-full">
        <div className="flex flex-col">
          <div className="flex flex-row items-center justify-between">
            <div className="flex flex-row items-center gap-x-1">
              <div className="flex flex-row items-center gap-x-2 text-xs">
                <div
                  className="cursor-pointer text-xl font-bold text-blue-700"
                  onClick={() => {
                    document.dispatchEvent(
                      new CustomEvent("SHOW_Opportunity_RESEARCH_RESULTS", {
                        detail: {
                          showOpportunityResearchResults: false,
                          OpportunityResearch: null,
                        },
                      }),
                    );
                  }}
                >
                  Opportunity Research
                </div>
              </div>
              <div className="h-4 w-4">
                <ChevronRightIcon className="h-4 w-4" />
              </div>

              <div className="text-xl font-bold">
                {OpportunityResearch.name}
              </div>
            </div>
            <div className="flex flex-row items-center gap-x-2">
              <div className="group relative h-7 w-7 rounded-full bg-yellow-200 p-1">
                <ArrowPathIcon
                  className="h-5 w-5 cursor-pointer"
                  onClick={() => setRefreshResult(true)}
                />
                <div className="z-top text-2xs invisible absolute top-8 right-0 inline-block w-max rounded-lg bg-gray-900 px-2 py-1 font-medium text-white shadow-sm group-hover:visible">
                  Reload Results
                </div>
              </div>
            </div>
          </div>
        </div>
        <OpportunityResearchResultsTable />
      </div>
      <ErrorModal
        open={errorModal}
        setOpen={setErrorModal}
        errorMessage={errorMessage}
      />
    </OpportunityResearchResultsContext.Provider>
  );
};
