import { ColDef } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { OpportunityResearch } from "../../../api/types";
import { ContentViewer } from "../../ContentViewer";
import NewModal from "../../NewModal";
import { useTotalBar } from "../../Utils/agGridUtils";
import { OpportunityResearchContext } from "./OpportunityResearchUtils";

type OpportunityResearchTableProps = {
  columnDefs: ColDef[];
  paginationPageSize: number;
  serverSideDataSource: any;
};

export const OpportunityResearchTable = ({
  columnDefs,
  paginationPageSize,
  serverSideDataSource,
}: OpportunityResearchTableProps) => {
  const {
    agGrid,
    setAgGrid,
    refreshOpportunityResearch,
    setRefreshOpportunityResearch,
  } = useContext(OpportunityResearchContext);

  const [showFullPrompt, setShowFullPrompt] = useState<boolean>(false);
  const [OpportunityResearch, setOpportunityResearch] =
    useState<OpportunityResearch>();

  const statusBar = useMemo(() => {
    return {
      statusPanels: [
        {
          statusPanel: useTotalBar,
          statusPanelParams: {
            paginationPageSize: paginationPageSize,
          },
        },
      ],
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (refreshOpportunityResearch) {
      agGrid.api.updateGridOptions({
        serverSideDatasource: serverSideDataSource,
      });
      setRefreshOpportunityResearch(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshOpportunityResearch]);

  // Initial Data
  const onGridReady = useCallback(async (params: any) => {
    setAgGrid(params);
    params.toolPanelSuppressValues = true;
    params.api.updateGridOptions({
      serverSideDatasource: serverSideDataSource,
    });
    // eslint-disable-next-line
  }, []);

  const getRowId = (params: { data: { id: string } }) =>
    params.data.id.toString();

  return (
    <div
      className={`ag-theme-quartz ag-status-bar-top mt-3`}
      style={{
        height: "88%",
        width: "100%",
      }}
    >
      <AgGridReact
        getRowId={getRowId}
        rowModelType="serverSide"
        columnDefs={columnDefs}
        defaultColDef={{
          flex: 1,
          wrapText: true,
          autoHeight: true,
          sortable: true,
          resizable: true,
          wrapHeaderText: true,
          autoHeaderHeight: true,
          cellStyle: { display: "flex", alignItems: "center" },
        }}
        columnMenu={"legacy"}
        onCellDoubleClicked={(event: any) => {
          if (["prompt"].includes(event.column.getColId())) {
            setShowFullPrompt(true);
            setOpportunityResearch(event.data);
          }
        }}
        animateRows={true}
        tooltipShowDelay={100}
        paginationPageSize={paginationPageSize}
        cacheBlockSize={paginationPageSize}
        onGridReady={onGridReady}
        maxConcurrentDatasourceRequests={4}
        alwaysShowVerticalScroll={true}
        cacheOverflowSize={paginationPageSize * 2}
        multiSortKey={"ctrl"}
        statusBar={statusBar}
        maintainColumnOrder={true}
        stopEditingWhenCellsLoseFocus={true}
        tooltipHideDelay={1000000}
      ></AgGridReact>

      <NewModal
        open={showFullPrompt}
        onClose={setShowFullPrompt}
        customClass="overflow-visible!"
        expanded={false}
      >
        {OpportunityResearch && (
          <ContentViewer
            title={OpportunityResearch.name}
            date={OpportunityResearch.updated_on}
            content={OpportunityResearch.prompt}
            user={OpportunityResearch.updated_by}
          />
        )}
      </NewModal>
    </div>
  );
};
