import * as HeroIcons from "@heroicons/react/24/solid";
import {
  BuildingOfficeIcon,
  ExclamationTriangleIcon,
  XCircleIcon,
} from "@heroicons/react/24/solid";
import { AxiosError } from "axios";
import { Field, FieldProps, Form, Formik, FormikErrors } from "formik";
import { useRouter } from "next/router";
import React, { useEffect, useMemo, useState } from "react";
import Dropzone from "react-dropzone";
import useSWR, { KeyedMutator, useSWRConfig } from "swr";
import {
  deleteOrganization,
  getApolloOrganization,
  getter,
  instance,
} from "../../../api";
import {
  Option,
  OrganizationDetail,
  OrganizationFormValues,
  Success,
  VertexPlatform,
} from "../../../api/types";
import ApolloOrganizationSuggestion from "../../ApolloOrganizationSuggestion";
import Avatar from "../../Avatar";
import ErrorModal from "../../ErrorModal";
import FormCountriesSelectField from "../../Form/FormCountriesSelectField";
import FormIndustriesSelectField from "../../Form/FormIndustriesSelectField";
import FormTechnologiesSelectField from "../../Form/FormTechnologiesSelectField";
import FormTextAreaField from "../../Form/FormTextAreaField";
import FormTextField from "../../Form/FormTextField";
import { ModalHeader } from "../../ModalHeader";
import NewModal from "../../NewModal";
import AutocompleteMultiselect from "../../Select/AutocompleteMultiselect";
import Spinner from "../../Spinner";
import SpinnerCustom from "../../SpinnerCustom";
import Thumbnail from "../../Thumbnail";
import { isValidJson } from "../../Utils/commons";
import { GLOBAL_GROUP_KEY } from "../../Utils/constant";
import { useScrollToFirstError } from "../../Utils/formUtils";
import { ShowSnackBar } from "../../Utils/supportMessage";
import {
  classNames,
  downloadImageAndConvertToFile,
  getDomainFromUrl,
  isValidUrl,
} from "../../utils";
import {
  ErrorMessage,
  isValidLinkedInCompanyURL,
} from "./OrganizationFormUtils";

let emptyForm: OrganizationFormValues = {
  org_name: "",
  description: "",
  org_website: "",
  country: null,
  linkedin: "",
  industry: [],
  image_url: "",
  image: undefined,
  technologies: [],
  ticker: "",
  tags: [],
  website_unknown: false,
};

type IconNames = keyof typeof HeroIcons;

type OrganizationFormProps = {
  callBack?: (organiation: OrganizationDetail) => void;
  pk?: string | number;
  isOpen?: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  mutate?: KeyedMutator<Success<OrganizationDetail[]>>;
  newOrganization?: (organiation: OrganizationDetail) => void;
  title?: string;
  isModal?: boolean;
  icon?: IconNames;
};

const OrganizationForm = ({
  pk,
  isOpen,
  setIsOpen,
  callBack,
  mutate,
  newOrganization,
  title = "Create Organization",
  isModal = false,
  icon,
}: OrganizationFormProps) => {
  const router = useRouter();
  const [completedForm, setCompletedForm] = useState<OrganizationFormValues>();
  const { cache } = useSWRConfig();
  const { backUrl } = router.query;
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false);
  const [error, setError] = useState<AxiosError>();
  const optionalFields = [
    "image",
    "linkedin",
    "ticker",
    "is_public",
    "bd_description",
    "investment_partners",
    "is_vpc",
  ];
  const [deleteError, setDeleteError] = useState(false);
  const [errorTitle, setErrorTitle] = useState("");
  const [deleting, setDeleting] = useState<boolean>(false);
  const { data: selectedGlobalGroup } = useSWR<any>(GLOBAL_GROUP_KEY);
  const [isWebsiteEdited, setIsWebsiteEdited] = useState<boolean>(false);
  const [previousFormData, setPreviousFormData] = useState<any>(null);
  const [isClearForm, setIsClearForm] = useState<boolean>(false);

  const [formErrors, setFormErrors] =
    useState<FormikErrors<OrganizationFormValues>>();
  const [submit, setSubmit] = useState<boolean>(false);

  //Apollo
  const [isApolloLoading, setIsApolloLoading] = useState<boolean>(false);
  const [openApollo, setOpenApollo] = useState<boolean>(false);
  const [apolloData, setApolloData] = useState<OrganizationDetail | null>(null);
  const [fillApolloData, setFillApolloData] =
    useState<OrganizationDetail | null>(null);
  const [apolloModalMessage, setApolloModalMessage] = useState("");
  const [apolloStatus, setApolloStatus] = useState<number | null>(null);
  const [loadingFill, setLoadingFill] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [errorModalOpen, setErrorModalOpen] = useState<boolean>(false);
  const [numberInvestors, setNumberInvestors] = useState<number>(0);

  const industryToolTip = {
    title: "What are Industries?",
    content:
      "Industries are extracted and based on LinkedIn. You can find the industry of a company by referencing its LinkedIn profile page",
  };
  const options = {
    revalidateOnFocus: false,
  };
  const [id, setId] = useState<string | number | null>();
  const [initialData, setInitialData] =
    useState<OrganizationFormValues>(emptyForm);
  useScrollToFirstError(formErrors, submit, setSubmit);

  useEffect(() => {
    if (pk) {
      setId(pk);
    } else {
      setId(null);
      const organizationFormStr = sessionStorage.getItem("organizationForm");
      if (organizationFormStr && isValidJson(organizationFormStr)) {
        setPreviousFormData(JSON.parse(organizationFormStr));
      }
    }
    // eslint-disable-next-line
  }, [pk]);

  const { data, error: orgDetailError } = useSWR<Success<OrganizationDetail>>(
    id ? `/api/people_map/organizations/${id}?update_from_ninja=False` : null,
    getter,
    options,
  );
  const { data: datavpc } = useSWR<Success<VertexPlatform>>(
    data && data.data.investors.length > 0
      ? `/api/people_map/organizations/${id}/vpc`
      : null,
    getter,
    options,
  );

  const { data: hostedDomains } = useSWR<Success<string[]>>(
    `/api/people_map/hosted_domains`,
    getter,
  );

  useEffect(() => {
    if (data) {
      const org_data = data.data;
      const tags = org_data.tags.map((tag) => {
        return { value: tag.id, label: tag.name } as Option;
      });
      setCompletedForm({
        org_name: org_data.name.trim(),
        description: org_data.description,
        org_website: org_data.domain ?? "",
        country: org_data.headquarters,
        linkedin: org_data.linkedin,
        industry: org_data.industries,
        image_url: org_data.logo_url ?? org_data.image_url,
        image: undefined,
        technologies: org_data.technologies,
        ticker: org_data?.ticker,
        tags: tags,
        website_unknown: false,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useMemo(() => {
    if (data) {
      setInitialData(
        id && !isClearForm
          ? completedForm
          : previousFormData
            ? previousFormData
            : emptyForm,
      );
    }
    //eslint-disable-next-line
  }, [data, datavpc, completedForm]);

  const handleSubmit = async (values: OrganizationFormValues) => {
    setIsProcessing(true);
    const formData = new FormData();
    if (values.image) {
      formData.append("image", values.image);
    }

    let payload: any = {
      name: getOrganizationName(values.org_name),
      description: values.description,
      website: values.org_website,
      country_id: String(values.country?.value),
      linkedin: values.linkedin ?? "",
      technology_id: values.technologies
        .map((option) => option.value)
        .join(","),
      ticker: values.ticker,
      industry_id: (values.industry || [])
        .map((option) => option.value)
        .join(","),
      tags: values.tags.map((option) => option.label),
    };

    formData.append("organization", JSON.stringify(payload));

    const submission_url = id
      ? `/api/people_map/organizations/${id}`
      : "/api/people_map/organizations/create";

    instance
      .post(submission_url, formData)
      .then((res) => {
        if (sessionStorage.getItem("organizationForm")) {
          sessionStorage.removeItem("organizationForm");
        }
        if (res.status === 201) {
          newOrganization && newOrganization(res.data.data);
          if (!isOpen) {
            router.push(`/organizations/${res.data.data.id}`);
          } else {
            setIsOpen(false);
            if (callBack) {
              callBack(res.data.data);
            }
            if (mutate) {
              mutate();
            }
          }
        } else if (res.status === 200) {
          if (callBack) {
            setIsOpen(false);
            callBack(res.data.data);
          } else {
            router.push(`/organizations/${res.data.data.id}`);
          }
        }
        ShowSnackBar(
          id
            ? "Organization Updated Successfully"
            : "Organization Created Successfully",
          true,
        );
        cache.delete(
          `/api/people_map/organizations/${id}?update_from_ninja=False`,
        );
      })
      .catch((err: AxiosError) => {
        switch (err.response?.status) {
          case 413:
            setErrorModalOpen(true);
            setErrorMessage(
              "Error: File size is more than the permitted limit of 32MB.",
            );
        }
        setError(err);
        setShowErrorModal(true);
        setIsProcessing(false);
      })
      .finally(() => {
        setIsProcessing(false);
      });
  };

  const validate = (values: OrganizationFormValues) => {
    let errors: FormikErrors<OrganizationFormValues> = {};
    const org_name = getOrganizationName(values.org_name);

    Object.entries(values).forEach(([key, value]) => {
      if (values.website_unknown && org_name && org_name.trim().length === 0) {
        errors.org_name = "Please fill in the organization name";
      }
      if (values.org_website) {
        if (!isValidUrl(values.org_website)) {
          errors.org_website = "Invalid website format";
        } else if (values.org_website && hostedDomains?.data) {
          const inputDomain = getDomainFromUrl(values.org_website);
          if (inputDomain && hostedDomains.data.includes(inputDomain)) {
            errors.org_website = `Do not use hosted domains for the company website - ${inputDomain}`;
          }
        }
      } else if (
        !org_name &&
        org_name?.trim()?.length === 0 &&
        values.website_unknown
      ) {
        errors.org_website = "Organization Name is Required";
      } else {
        errors.org_website = "Website is required";
      }
      if (values.linkedin && !isValidLinkedInCompanyURL(values.linkedin)) {
        errors.linkedin =
          "URL should be https://www.linkedin.com/company/<company-name>";
      }
      if (
        !values.org_name ||
        (values.org_name && values.org_name.trim().length < 3)
      ) {
        errors.org_name = "Organization name needs to be at least 3 characters";
      }
      if (
        !values.description ||
        (values.description && values.description.length < 20)
      ) {
        errors.description = "Description has to be at least 20 characters";
      }
      if (values.industry.length === 0) {
        errors.industry = "At least one industry should be selected";
      }
      if (!values.country) {
        errors.country = "Country is required";
      }
    });
    if (!pk && !isClearForm) {
      values.website_unknown =
        (org_name &&
          org_name.trim().length > 0 &&
          values.org_website.trim().length > 0 &&
          values.org_website.includes(
            `https://unknown-${org_name
              .trim()
              .replaceAll(/\s+/g, "-")
              .toLowerCase()}.com`,
          )) ||
        false;
      sessionStorage.setItem(
        "organizationForm",
        JSON.stringify({ ...values, image: undefined }),
      );
    }

    setFormErrors(errors);
    return errors;
  };

  if (id && orgDetailError) {
    return (
      <div className="m-16">
        <h3 className="text-5xl font-bold">
          Error {orgDetailError.response.status}
        </h3>
        <p className="text-sm font-light">
          {orgDetailError.response.data.message}
        </p>
      </div>
    );
  }

  if ((!data && (pk || id)) || (!completedForm && (pk || id))) {
    return <SpinnerCustom />;
  }

  const getApolloDetails = (values: OrganizationFormValues) => {
    setIsApolloLoading(true);
    setApolloModalMessage("");
    setApolloStatus(null);

    const params = new URLSearchParams();
    values.org_website.trim().length > 0 &&
      params.set("domain", values.org_website.toLowerCase().trim());

    getApolloOrganization(params)
      .then((resp) => {
        setApolloData(resp.data);
      })
      .catch((err: AxiosError) => {
        switch (err.response?.status) {
          case 400:
            setApolloStatus(400);
            setApolloModalMessage(
              "This function has been disabled for you. Please contact Labs Support for more info.",
            );
            return;
          case 404:
            setApolloStatus(404);
            setApolloModalMessage(
              "Sorry, we could not find any organization based on your query.",
            );
            return;
        }
      })
      .finally(() => {
        setIsApolloLoading(false);
      });
  };

  const formatDomain = (domain: string) => {
    const cleanedCompanyName = domain.trim().replace(/[^a-zA-Z0-9- ]/g, "");
    return cleanedCompanyName.toLowerCase().replace(/ /g, "_") + ".com";
  };

  const getOrganizationName = (orgValue: any) => {
    return typeof orgValue === "string" ? orgValue : orgValue?.label;
  };

  return (
    <div className={`grid`}>
      <Formik
        initialValues={
          fillApolloData
            ? {
                ...initialData,
                org_name: fillApolloData.name,
                description: fillApolloData.description,
                org_website:
                  fillApolloData.website &&
                  fillApolloData.website.trim().length > 0
                    ? fillApolloData.website.trim()
                    : fillApolloData.domain.trim(),
                country: fillApolloData.headquarters,
                linkedin: fillApolloData.linkedin,
                industry: fillApolloData.industries,
                image_url: fillApolloData.logo_url,
                image: fillApolloData.image_file,
                technologies: fillApolloData.technologies,
                ticker: fillApolloData.ticker,
              }
            : (initialData ?? emptyForm)
        }
        onSubmit={handleSubmit}
        validate={validate}
        enableReinitialize
      >
        {({
          errors,
          touched,
          setErrors,
          setFieldTouched,
          values,
          resetForm,
        }) => {
          const enableApolloQuery: boolean =
            values.org_website.trim().length > 0;
          return (
            <>
              <div className="flex items-center space-x-4">
                {!isModal ? (
                  <>
                    <div className="h-8 w-2 rounded-full bg-blue-900" />
                    <h2 className="my-3 text-base font-bold tracking-wide md:text-2xl">
                      {title}
                    </h2>
                  </>
                ) : (
                  <ModalHeader title={title} icon={icon} />
                )}

                <div className="group relative flex">
                  <button
                    onClick={() => {
                      setFillApolloData(null);
                      setApolloData(null);
                      getApolloDetails(values);
                      setOpenApollo(true);
                    }}
                    className={`btn-primary ${
                      !enableApolloQuery ? "disabled" : ""
                    }`}
                  >
                    {isApolloLoading ? (
                      <div className="flex flex-row items-center gap-x-2 text-white">
                        <div>
                          <Spinner className="h-4 w-4 text-white" />
                        </div>
                        <div>Querying</div>
                      </div>
                    ) : (
                      "Query Organization"
                    )}
                  </button>
                  <div className="z-top invisible absolute top-9 w-max rounded-lg bg-gray-800 p-2 text-xs font-medium whitespace-pre-wrap text-white group-hover:visible">
                    {`Fill the organization's website field and query our external \ndatabases to populate the remaining details`}
                  </div>
                </div>
              </div>
              <Form>
                <div className="relative grid grid-cols-1 gap-y-4 sm:pr-7 md:grid-cols-2 lg:gap-y-6">
                  <div className="relative text-xs">
                    <Field
                      as={FormTextField}
                      placeholder={""}
                      name="org_name"
                      label="Organization Name"
                      customClass="w-full!"
                      errors={
                        errors.org_name ? String(errors.org_name) : undefined
                      }
                      touched={touched.org_name}
                      required
                    />
                  </div>
                  <div className="relative flex md:justify-end">
                    <Field>
                      {({
                        form: { setFieldValue, errors, touched },
                      }: FieldProps) => {
                        const org_name = getOrganizationName(values.org_name);

                        if (!isWebsiteEdited && values.website_unknown) {
                          if (org_name && org_name.trim().length > 0) {
                            values.org_website = `https://unknown-${formatDomain(
                              org_name,
                            )}`;
                          } else {
                            values.org_website = "";
                          }
                        }

                        return (
                          <FormTextField
                            name="org_website"
                            label="Website"
                            placeholder="https://"
                            errors={
                              errors.org_website
                                ? String(errors.org_website)
                                : undefined
                            }
                            touched={Boolean(touched.org_website)}
                            value={
                              values.org_website &&
                              values.org_website.trim().length > 0
                                ? values.org_website
                                : ""
                            }
                            onChange={(event: any) => {
                              setIsWebsiteEdited(true);
                              let value = event.target.value;
                              if (
                                values.org_website.trim().length === 0 &&
                                !value.includes("https://")
                              ) {
                                value = `https://${event.target.value}`;
                              }
                              setFieldValue("org_website", value);
                              touched.org_website = true;
                            }}
                            required={true}
                            onBlur={() => {
                              setFieldTouched("org_website", true);
                            }}
                          />
                        );
                      }}
                    </Field>
                    <Field name="website_unknown">
                      {({ field, form: { setFieldValue } }: FieldProps) => {
                        const org_name = getOrganizationName(values.org_name);
                        values.website_unknown =
                          (org_name &&
                            org_name.trim().length > 0 &&
                            values.org_website.trim().length > 0 &&
                            values.org_website ===
                              `https://unknown-${formatDomain(org_name)}`) ||
                          false;

                        return (
                          <div className="absolute top-0 right-1 flex flex-row items-center gap-x-1">
                            <input
                              type="checkbox"
                              className="h-3.5 w-3.5 cursor-pointer rounded-full border-gray-500 text-blue-600 focus:ring-white"
                              checked={values.website_unknown}
                              onChange={(event) => {
                                const org_name = getOrganizationName(
                                  values.org_name,
                                );
                                setFieldValue(field.name, event.target.checked);
                                touched.org_name = true;
                                if (org_name && org_name.trim().length > 0) {
                                  setFieldValue(
                                    "org_website",
                                    event.target.checked
                                      ? `https://unknown-${formatDomain(
                                          org_name,
                                        )}`
                                      : "",
                                  );
                                  if (event.target.checked) {
                                    setIsWebsiteEdited(false);
                                  }
                                }
                                initialData.website_unknown =
                                  event.target.checked;
                                touched.org_website = true;
                              }}
                            />
                            <div className="text-xs font-normal">
                              Website unknown
                            </div>
                          </div>
                        );
                      }}
                    </Field>
                  </div>
                  <div className="relative">
                    <Field
                      as={FormCountriesSelectField}
                      id="organization-form-country-field"
                      name="country"
                      label="Country"
                      placeholder="Select Country"
                      customClass="w-full!"
                      errors={errors.country}
                      touched={touched.country}
                      required={true}
                      onBlur={() => {
                        setFieldTouched("country", true);
                      }}
                    />
                  </div>
                  <div className="relative flex md:justify-end">
                    <Field
                      as={FormTextField}
                      name="linkedin"
                      label="LinkedIn"
                      errors={errors.linkedin}
                      touched={touched.linkedin}
                      placeholder="https://www.linkedin.com/company/"
                    />
                  </div>
                  <div className="relative text-xs">
                    <Field
                      as={FormIndustriesSelectField}
                      id="organization-form-industry-field"
                      name="industry"
                      label="Industry"
                      placeholder="Select Industry"
                      isMulti={true}
                      customClass="w-full!"
                      errors={errors.industry}
                      touched={touched.industry}
                      required
                      tooltip={industryToolTip}
                      onBlur={() => {
                        setFieldTouched("industry", true);
                      }}
                    />
                  </div>
                  <div className="flex text-xs md:justify-end">
                    <Field
                      as={FormTechnologiesSelectField}
                      name="technologies"
                      label="Technologies"
                      placeholder="Select Technologies"
                      errors={errors.technologies}
                      touched={touched.technologies}
                    />
                  </div>
                  <div className="relative flex">
                    <Field
                      as={FormTextField}
                      placeholder={""}
                      customClass="w-full!"
                      name="ticker"
                      label="Ticker"
                      errors={errors.ticker}
                      touched={touched.ticker}
                    />
                  </div>
                  <div className="relative flex md:justify-end">
                    <Field name="image">
                      {({ form: { setFieldValue } }: FieldProps<File[]>) => (
                        <div className="grid w-full md:w-11/12">
                          <Dropzone
                            accept={{
                              "image/jpeg": [".jpg", ".jpeg"],
                              "image/png": [".png"],
                            }}
                            maxFiles={1}
                            onDrop={(acceptedFiles) => {
                              // do nothing if no files
                              if (acceptedFiles.length === 0) {
                                return;
                              }
                              setFieldValue("image", acceptedFiles[0]);
                            }}
                          >
                            {({
                              isDragActive,
                              isDragReject,
                              getRootProps,
                              getInputProps,
                            }) => {
                              if (isDragActive) {
                                return (
                                  <div
                                    className="flex cursor-pointer flex-col items-center justify-evenly rounded-md border-2 border-dashed border-blue-900 bg-blue-100 p-4"
                                    {...getRootProps()}
                                  >
                                    <input {...getInputProps()} />
                                    <div className="text-center">
                                      <h3 className="text-lg font-semibold text-blue-900">
                                        Drop in this file!
                                      </h3>
                                    </div>
                                  </div>
                                );
                              }

                              if (isDragReject) {
                                return (
                                  <div
                                    className="flex h-64 w-full flex-col items-center justify-evenly rounded-3xl border-2 border-dashed border-red-500 p-4"
                                    {...getRootProps()}
                                  >
                                    <input {...getInputProps()} />
                                    <XCircleIcon className="h-10 w-10 fill-red-500" />
                                    <div className="text-center">
                                      <h3 className="text-lg font-semibold text-blue-900">
                                        This file is not an image!
                                      </h3>
                                      <p className="text-xs font-light">
                                        Supported Formats: PNG, JPEG
                                      </p>
                                    </div>
                                  </div>
                                );
                              }
                              return (
                                <div
                                  className="flex cursor-pointer flex-col items-center justify-evenly rounded-md border-2 border-dashed border-blue-900 p-2"
                                  {...getRootProps()}
                                >
                                  <input {...getInputProps()} />
                                  <div className="flex items-center space-x-2 text-center">
                                    <div className="flex h-10 w-10 items-center justify-center rounded-full bg-blue-900">
                                      {values.image ? (
                                        <Thumbnail
                                          file={values.image}
                                          className={
                                            "h-11 w-11 shrink-0 rounded-full"
                                          }
                                        />
                                      ) : data?.data.logo_url &&
                                        !isClearForm ? (
                                        <Avatar
                                          name="Image"
                                          size={44}
                                          url={data?.data.logo_url}
                                          orgId={data?.data.id}
                                        />
                                      ) : (
                                        <BuildingOfficeIcon className="h-6 w-6 fill-white" />
                                      )}
                                    </div>
                                    <div className="text-xs font-semibold text-blue-900">
                                      Drag and Drop Organization Logo
                                    </div>
                                  </div>
                                </div>
                              );
                            }}
                          </Dropzone>
                        </div>
                      )}
                    </Field>
                  </div>
                  <div className="relative flex md:justify-end">
                    <div className="relative w-full md:w-full">
                      <div className="flex items-end">
                        <label className="text-xs font-semibold">Tags</label>
                      </div>
                      <div className="block text-xs">
                        <Field name="tags" className="">
                          {({
                            field: { name, value },
                            form: { setFieldValue, touched, errors },
                          }: FieldProps) => (
                            <>
                              <AutocompleteMultiselect
                                id="tags"
                                placeholder="Select Tags"
                                autocompleteEndpoint="/api/people_map/autocomplete/meeting_note_tags"
                                selected={value}
                                onChange={(newValue) =>
                                  setFieldValue(name, newValue)
                                }
                                creatable={true}
                                isClearable
                                className={`${
                                  touched && value?.length
                                    ? "border border-blue-900!"
                                    : ""
                                }`}
                                onBlur={() => {
                                  if (!values.tags?.length) {
                                    setFieldTouched("tags", false);
                                  } else {
                                    setFieldTouched("tags", true);
                                  }
                                }}
                              />
                              <div
                                className={classNames(
                                  touched && errors
                                    ? "absolute ml-2 text-xs"
                                    : "hidden",
                                )}
                              >
                                {errors.tags as any}
                              </div>
                            </>
                          )}
                        </Field>
                      </div>
                    </div>
                  </div>
                  <NewModal
                    title="Error"
                    open={showErrorModal}
                    onClose={setShowErrorModal}
                  >
                    <ErrorMessage error={error} setErrors={setErrors} />
                  </NewModal>
                  <div className="md:col-span-2">
                    <Field
                      as={FormTextAreaField}
                      placeholder={"Description..."}
                      name="description"
                      label="Description"
                      errors={errors.description}
                      touched={touched.description}
                      required
                      rows={4}
                    />
                  </div>
                </div>
                <div className="mt-6 mb-20 flex flex-row justify-end space-x-4 md:col-start-2">
                  <span
                    className="flex-column flex cursor-pointer items-center text-xs text-blue-900"
                    onClick={() => {
                      setFillApolloData(null);
                      setIsClearForm(true);
                      setPreviousFormData(null);
                      resetForm({ values: emptyForm });
                      if (sessionStorage.getItem("organizationForm")) {
                        sessionStorage.removeItem("organizationForm");
                      }
                    }}
                  >
                    Clear Form
                  </span>
                  <button
                    className="btn-primary"
                    onClick={() => {
                      setSubmit(true);
                    }}
                  >
                    {isProcessing ? (
                      <div className="flex flex-row items-center gap-x-2 text-white">
                        <div>
                          <Spinner className="text-blue h-4 w-4" />
                        </div>
                        <div>Submitting</div>
                      </div>
                    ) : (
                      "Submit"
                    )}
                  </button>
                </div>
                <NewModal
                  onClose={setIsProcessing}
                  open={isProcessing}
                  showExpandButton={false}
                  expanded={false}
                >
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex h-12 w-12 shrink-0 items-center justify-center rounded-full sm:mx-0 sm:h-10 sm:w-10">
                      <div
                        className="inline-block h-8 w-8 animate-spin rounded-full border-[3px] border-current border-t-transparent text-blue-900"
                        role="status"
                        aria-label="loading"
                      ></div>
                    </div>
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                      <div className="text-sm leading-6 font-semibold text-gray-900">
                        Loading
                      </div>
                      <div className="mt-2">
                        <p className="text-xs text-gray-500">
                          We are searching multiple databases to check if the
                          organization exists. Thank you for your patience.
                        </p>
                      </div>
                    </div>
                  </div>
                </NewModal>
                <NewModal
                  open={deleteError}
                  expanded={false}
                  onClose={setDeleteError}
                >
                  <div className="ml-2 flex flex-row">
                    <div>
                      <ExclamationTriangleIcon className="h-14 w-14 fill-red-500" />
                    </div>
                    <div className="ml-3.5 flex w-full flex-col justify-center text-base">
                      <div className="font-medium">{errorTitle}</div>
                      <div className="font-normal text-gray-500">
                        If you delete this organization, all the linked objects
                        will be deleted.
                      </div>
                      <div className="mt-5 font-medium">
                        Do you still want to delete it anyway?
                      </div>
                      <div className="mt-8 flex flex-row justify-end gap-x-5">
                        <button
                          className="btn-plain"
                          onClick={() => setDeleteError(false)}
                        >
                          Back
                        </button>
                        <button
                          type="button"
                          className={`btn-error`}
                          onClick={() => {
                            setDeleting(true);
                            deleteOrganization(parseInt(id as string), true)
                              .then(() => {
                                if (backUrl) {
                                  router.push(backUrl as string);
                                }
                              })
                              .catch((error: AxiosError) => {
                                if (error && error.response) {
                                  const res = error.response;
                                  const res_data = res.data;
                                  setErrorTitle(res_data.error.message);
                                }
                              })
                              .finally(() => {
                                setDeleteError(false);
                              });
                          }}
                        >
                          {deleting ? (
                            <div className="flex flex-row items-center gap-x-2 text-white">
                              <div>
                                <Spinner className="h-4 w-4 text-white" />
                              </div>
                              <div>Deleting...</div>
                            </div>
                          ) : (
                            "Delete anyway"
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </NewModal>
                <NewModal
                  open={openApollo}
                  title={
                    isApolloLoading
                      ? "Searching..."
                      : !apolloStatus
                        ? "Here's what we found!"
                        : apolloStatus === 404
                          ? "No organization found"
                          : "Error"
                  }
                  onClose={() => setOpenApollo(false)}
                  expanded={false}
                >
                  {isApolloLoading && <SpinnerCustom />}
                  {apolloData ? (
                    <ApolloOrganizationSuggestion
                      loading={loadingFill}
                      organizationDetail={apolloData}
                      handleClickFill={async (
                        organizationDetail: OrganizationDetail,
                      ) => {
                        setLoadingFill(true);
                        if (
                          organizationDetail.logo_url &&
                          organizationDetail.logo_url.length > 0 &&
                          !organizationDetail.logo_url.includes("static")
                        ) {
                          await downloadImageAndConvertToFile(
                            organizationDetail.logo_url,
                          )
                            .then((resp) => {
                              organizationDetail.image_file = resp;
                              setFillApolloData(organizationDetail);
                            })
                            .catch((err) => {
                              setFillApolloData(organizationDetail);
                              console.log(err);
                            });
                        } else {
                          setFillApolloData(organizationDetail);
                        }
                        setLoadingFill(false);
                        setOpenApollo(false);
                      }}
                    />
                  ) : (
                    <div className="text-xs text-gray-600">
                      {apolloModalMessage}
                    </div>
                  )}
                </NewModal>
                <NewModal
                  title="Error"
                  open={showErrorModal}
                  onClose={setShowErrorModal}
                >
                  <ErrorMessage error={error} setErrors={setErrors} />
                </NewModal>
                <ErrorModal
                  open={errorModalOpen}
                  setOpen={setErrorModalOpen}
                  errorMessage={errorMessage}
                />
              </Form>
            </>
          );
        }}
      </Formik>
    </div>
  );
};

export default OrganizationForm;
