import {
  CheckIcon,
  EyeIcon,
  PencilIcon,
  PlusIcon,
  SparklesIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { AxiosError } from "axios";
import Link from "next/link";
import { useEffect, useRef, useState } from "react";
import {
  deleteTemplatePrompt,
  getAgentPersonaById,
  getAllTemplatePrompt,
} from "../../api";
import {
  AgentPersona,
  AgentPersonaStub,
  TemplatePrompt,
} from "../../api/types";
import Avatar from "../Avatar";
import { ConfirmationModal } from "../ConfirmationModal";
import ErrorModal from "../ErrorModal";
import NewModal from "../NewModal";
import { parseAxiosError } from "../utils";
import { ShowSnackBar } from "../Utils/supportMessage";
import { TemplatePromptForm } from "./TemplatePromptForm";

type PromptsGuideViewProps = {
  onPromptSelected: (prompt: TemplatePrompt) => void;
  onGetPrompts: (prompts: TemplatePrompt[]) => void;
  promptSelecting: TemplatePrompt | undefined;
  agent: AgentPersonaStub;
  showAskVal: boolean;
};

export const PromptsGuideView = ({
  onPromptSelected,
  onGetPrompts,
  promptSelecting,
  agent,
  showAskVal,
}: PromptsGuideViewProps) => {
  const [agentDetail, setAgentDetail] = useState<AgentPersona>();
  const [prompts, setPrompts] = useState<TemplatePrompt[]>([]);
  const [showPromptForm, setShowPromptForm] = useState(false);
  const [promptDetail, setPromptDetail] = useState<TemplatePrompt>();

  const [errorMessage, setErrorMessage] = useState<string>("");
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false);

  const [showConfirmDeletionPrompt, setShowConfirmDeletionPrompt] =
    useState<boolean>(false);
  const [deletingPrompt, setDeletingPrompt] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<number>(0);

  const personaContainer = useRef<HTMLDivElement>(null);
  const [showMoreLess, setShowMoreLess] = useState<boolean>(false);
  const [more, setMore] = useState<boolean>(false);
  const updatePrompts = (prompts: TemplatePrompt[]) => {
    setPrompts(prompts);
    onGetPrompts(prompts);
  };

  useEffect(() => {
    if (showAskVal) {
      Promise.all([
        getAgentPersonaById(agent.id),
        getAllTemplatePrompt(agent.id),
      ])
        .then((response) => {
          const [agentResponse, promptsResponse] = response;
          setAgentDetail(agentResponse.data.data);
          updatePrompts(promptsResponse.data.data);
        })
        .catch((error: AxiosError) => {
          setErrorMessage(parseAxiosError(error));
          setShowErrorModal(true);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agent, showAskVal]);

  useEffect(() => {
    if (personaContainer.current) {
      const isMore =
        personaContainer.current.getBoundingClientRect().height > 180;
      setShowMoreLess(isMore);
      setMore(isMore);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personaContainer, agent]);

  const deletePrompt = (promptId: number) => {
    setDeletingPrompt(true);
    deleteTemplatePrompt(promptId)
      .then((response: any) => {
        if (response.status === 200) {
          updatePrompts(prompts.filter((prompt) => prompt.id !== promptId));
          setDeletingPrompt(false);
          ShowSnackBar("You deleted this prompt.");
          setShowConfirmDeletionPrompt(false);
        }
      })
      .catch((error: AxiosError) => {
        setErrorMessage(parseAxiosError(error));
        setShowErrorModal(true);
        setDeletingPrompt(false);
      });
  };

  if (!agentDetail) {
    return <></>;
  }

  const policyGroups =
    agentDetail.access_policy?.groups &&
    agentDetail.access_policy?.groups.length > 0 &&
    agentDetail.access_policy?.users &&
    agentDetail.access_policy?.users.length > 1
      ? agentDetail.access_policy?.groups
          .map((group) => group.name)
          .join(", ") + " & Selected Users"
      : agentDetail.access_policy?.groups &&
          agentDetail.access_policy?.groups.length > 0
        ? agentDetail.access_policy?.groups
            .map((group) => group.name)
            .join(", ")
        : agentDetail.access_policy?.users &&
            agentDetail.access_policy?.users.length > 0
          ? "Selected Users"
          : "all";

  return (
    <div className="flex h-full flex-col items-start gap-y-4 overflow-y-auto p-3">
      <div className="flex flex-col gap-y-2">
        <div className="flex flex-row items-center gap-x-5">
          <div className="text-xl font-bold">{agent.name}</div>
          <div className="group relative">
            <EyeIcon className="group mr-1 h-6 w-6 cursor-pointer stroke-gray-500 hover:fill-blue-500 hover:stroke-white" />
            <div className="z-top invisible absolute left-0 w-max max-w-[200px] rounded-md border bg-black p-2 text-xs text-white shadow-md group-hover:visible">
              <div className="text-2xs flex w-full flex-wrap">
                {policyGroups.toLowerCase()[0].toUpperCase() +
                  policyGroups.toLowerCase().substring(1)}
              </div>
              {agentDetail.access_policy?.users &&
                agentDetail.access_policy?.users.length > 0 &&
                agentDetail.access_policy?.users.map((user) => (
                  <div key={user.id}>
                    {user.person ? (
                      <Link
                        key={user.id}
                        href={`/people/${user.person}`}
                        passHref
                        legacyBehavior
                      >
                        <div className="owner mt-2 flex cursor-pointer gap-x-2">
                          <Avatar
                            key={user.id}
                            className="bg-gray-300"
                            name={user.name}
                            size={24}
                            url={user.image_url}
                          />
                          <span className="text-2xs justify-center rounded-md border bg-black px-2 py-1 text-white">
                            {user.name}
                          </span>
                        </div>
                      </Link>
                    ) : (
                      <div className="owner mt-2 flex cursor-pointer gap-x-2">
                        <Avatar
                          key={user.id}
                          name={user.name}
                          size={24}
                          url={user.image_url}
                        />
                        <span className="text-2xs justify-center rounded-md border bg-black px-2 py-1 text-white">
                          {user.name}
                        </span>
                      </div>
                    )}
                  </div>
                ))}
            </div>
          </div>
        </div>
        <div className="text-2xs text-gray-500">
          Created by: {agentDetail.created_by?.name ?? "Default"}
        </div>
        <div
          ref={personaContainer}
          className={`text-xs ${more ? "line-clamp-10" : ""}`}
        >
          {agent.persona}
        </div>
        {showMoreLess && (
          <div className="flex w-full flex-row justify-start text-xs font-semibold">
            <button
              className="mr-2 text-blue-900"
              onClick={() => setMore(!more)}
            >
              {more ? "View more" : "View less"}
            </button>
          </div>
        )}
      </div>
      {agentDetail.tools.length > 0 && (
        <div className="flex flex-col gap-y-2">
          <div className="text-xl font-bold">Tools</div>
          <div className="grid grid-cols-2 gap-x-2">
            {agentDetail.tools.map((tool) => {
              return (
                <div
                  key={tool.name}
                  className="flex flex-row items-center gap-x-2 text-xs"
                >
                  <div className="h-5 w-5">
                    <CheckIcon className="h-5 w-5" />
                  </div>
                  <div>{tool.display}</div>
                </div>
              );
            })}
          </div>
        </div>
      )}
      <div className="mt-5 flex flex-row items-center">
        <SparklesIcon className="mr-2 h-5 w-5" />
        <div className="text-xl font-bold">Prompts</div>
        <div
          className="ml-5 flex cursor-pointer flex-row items-center gap-x-1 rounded-full bg-white px-4 py-2 text-xs shadow-md hover:shadow-slate-400"
          onClick={() => {
            setPromptDetail(undefined);
            setShowPromptForm(true);
          }}
        >
          <PlusIcon className="h-3 w-3 stroke-2" />
          <div className="font-semibold">Create Prompt</div>
        </div>
      </div>
      <div className="flex w-full flex-wrap gap-2">
        {prompts
          .sort((a: TemplatePrompt, b: TemplatePrompt) =>
            a.title.localeCompare(b.title),
          )
          .map((item: TemplatePrompt) => (
            <div
              key={item.id}
              className={`group flex cursor-pointer flex-row items-center rounded-full bg-white px-4 py-2 text-xs font-medium shadow-md hover:shadow-slate-400 ${
                item.id === promptSelecting?.id &&
                "bg-blue-100 font-semibold text-blue-900"
              }`}
              onClick={() => onPromptSelected(item)}
            >
              <div>{item.title}</div>
              <div className="ml-2 flex flex-row gap-x-1">
                <div
                  className="h-4 w-4"
                  onClick={() => {
                    setPromptDetail(item);
                    setShowPromptForm(true);
                  }}
                >
                  <PencilIcon className="h-4 w-4 stroke-gray-500 hover:stroke-blue-900" />
                </div>
                <div
                  className="h-4 w-4"
                  onClick={() => {
                    setDeleteId(item.id);
                    setShowConfirmDeletionPrompt(true);
                  }}
                >
                  <TrashIcon className="h-4 w-4 stroke-gray-500 hover:stroke-red-500" />
                </div>
              </div>
            </div>
          ))}
      </div>
      <NewModal
        title={promptDetail ? "Edit Prompt" : "Create Prompt"}
        expanded={false}
        open={showPromptForm}
        onClose={setShowPromptForm}
      >
        <TemplatePromptForm
          agentId={agent.id}
          templatePrompt={promptDetail}
          onSuccess={(prompt, isCreateNew) => {
            if (promptDetail && !isCreateNew) {
              prompts.forEach((item, index) => {
                if (item.id === promptDetail.id) {
                  prompts[index] = prompt;
                  return false;
                }
              });
              updatePrompts(prompts);
            } else {
              updatePrompts([...prompts, prompt]);
            }
            setPromptDetail(undefined);
            setShowPromptForm(false);
          }}
        />
      </NewModal>
      <ErrorModal
        open={showErrorModal}
        setOpen={setShowErrorModal}
        errorMessage={errorMessage}
      />
      <ConfirmationModal
        open={showConfirmDeletionPrompt}
        title="Delete prompt?"
        subtitle="This action cannot be undone"
        processing={deletingPrompt}
        processingText={"Deleting..."}
        submitButtonText={"Delete"}
        onClose={() => {
          setDeleteId(0);
          setShowConfirmDeletionPrompt(false);
        }}
        onSubmit={() => deletePrompt(deleteId)}
      />
    </div>
  );
};
