import {
  BookmarkIcon,
  EllipsisVerticalIcon,
  PencilIcon,
  PlusIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { AxiosError } from "axios";
import { useEffect, useRef, useState } from "react";
import * as api from "../../api/index";
import { AgentPersona, AgentPersonaStub } from "../../api/types";
import { ConfirmationModal } from "../ConfirmationModal";
import ErrorModal from "../ErrorModal";
import NewModal from "../NewModal";
import SpinnerCustom from "../SpinnerCustom";
import { parseAxiosError } from "../utils";
import { ShowSnackBar } from "../Utils/supportMessage";
import { AgentForm } from "./AgentForm";

type AgentsViewProps = {
  currentAgent: AgentPersonaStub | undefined;
  onItemChanged: (agent: AgentPersonaStub) => void;
  onDeleteItem: () => void;
  showAskVal: boolean;
};

export const AgentsView = ({
  currentAgent,
  onItemChanged,
  onDeleteItem,
  showAskVal,
}: AgentsViewProps) => {
  const [agents, setAgents] = useState<AgentPersonaStub[]>([]);
  const [showAgentForm, setShowAgentForm] = useState(false);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [agentDetail, setAgentDetail] = useState<AgentPersona>();

  const [errorMessage, setErrorMessage] = useState<string>("");
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false);

  const [showConfirmDeletionAgent, setShowConfirmDeletionAgent] =
    useState<boolean>(false);
  const [deletingAgent, setDeletingAgent] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<number>(0);

  const popoverRefs = useRef<any>([]);

  const getAllAgents = () => {
    setIsLoading(true);
    api
      .getAllAgentPersona()
      .then((response: any) => {
        if (response.data) {
          setAgents(response.data.data);
        }
      })
      .catch((error: AxiosError) => {
        setErrorMessage(parseAxiosError(error));
        setShowErrorModal(true);
      })
      .then(() => setIsLoading(false));
  };

  useEffect(() => {
    if (showAskVal) {
      getAllAgents();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showAskVal]);

  const deleteAgent = (agentId: number) => {
    setDeletingAgent(true);
    api
      .deleteAgentPersona(agentId)
      .then((response: any) => {
        if (response.status === 200) {
          setAgents(agents.filter((agent) => agent.id !== agentId));
          setDeletingAgent(false);
          onDeleteItem();
          ShowSnackBar("You deleted this agent.");
          setShowConfirmDeletionAgent(false);
        }
      })
      .catch((error: AxiosError) => {
        setErrorMessage(parseAxiosError(error));
        setShowErrorModal(true);
        setDeletingAgent(false);
      });
  };

  const loadAgent = (agentId: number) => {
    if (showAskVal) {
      api
        .getAgentPersonaById(agentId)
        .then((response: any) => {
          if (response.data) {
            setAgentDetail(response.data.data);
            setShowAgentForm(true);
          }
        })
        .catch((error: AxiosError) => {
          setErrorMessage(parseAxiosError(error));
          setShowErrorModal(true);
        });
    }
  };

  const favoriteAgent = (agent: AgentPersonaStub) => {
    api.pinAgent(agent.id, !agent.pinned).then((response) => {
      if (response.data) {
        agents.forEach((ag, index) => {
          if (ag.id === response.data.data.id) {
            agents[index] = response.data.data;
          }
        });
        setAgents([...[], ...agents]);
      }
    });
  };

  const closePopup = () => {
    agents.forEach((item) => {
      if (
        popoverRefs.current[item.id] &&
        popoverRefs.current[item.id].ariaExpanded === "true"
      ) {
        popoverRefs.current[item.id].click();
      }

      const pop = document.getElementById(`pop_agent_${item.id}`);
      if (pop) {
        if (!pop.classList.contains("hidden")) {
          pop.classList.add("hidden");
        }
      }
    });
  };

  return (
    <div
      id={`agent_view`}
      className={`relative mx-2 flex h-full flex-col py-3`}
    >
      <div className="flex flex-row items-center gap-x-2 pl-1">
        <div className="text-sm font-semibold">Agents</div>
        <div className={`group relative`}>
          <PlusIcon
            className="h-4 w-4 cursor-pointer"
            onClick={() => {
              setAgentDetail(undefined);
              setShowAgentForm(true);
            }}
          />
          <div
            id="tooltip-bottom"
            role="tooltip"
            className="tooltip z-top text-2xs invisible absolute top-5 left-0 inline-block w-max rounded-lg bg-gray-900 px-2 py-1 font-medium text-white shadow-xs group-hover:visible"
          >
            Create New Agent
          </div>
        </div>
      </div>
      {isLoading ? (
        <SpinnerCustom />
      ) : (
        <div className="show-scrollbar text-2xs mt-2 flex h-full flex-col overflow-y-auto">
          {agents.map((agent: AgentPersonaStub) => (
            <div
              key={agent.id}
              className={`group flex h-6 flex-row items-center justify-between rounded pl-0.5 hover:cursor-pointer hover:bg-gray-100 hover:text-black ${
                currentAgent?.id === agent.id && "bg-blue-100 text-blue-900"
              }`}
              onMouseLeave={() => {
                closePopup();
              }}
            >
              <div
                id={`agent-${agent.id}`}
                className={`line-clamp-1 h-[22px] w-full p-1 ${
                  currentAgent?.id === agent.id && "font-semibold"
                }`}
                style={{ wordBreak: "break-word" }}
                onClick={() => onItemChanged(agent)}
              >
                {agent.name}
              </div>
              {!agent.default_val && (
                <div className="flex flex-row items-center">
                  {agent.pinned && (
                    <div>
                      <BookmarkIcon
                        className={`h-4 w-4 cursor-pointer stroke-gray-500 ${
                          agent.pinned && "fill-blue-900"
                        }`}
                        onClick={(event) => {
                          favoriteAgent(agent);
                        }}
                      />
                    </div>
                  )}
                  <div className="h-5 w-5 opacity-0 group-hover:opacity-100">
                    <div
                      ref={(ref: any) => {
                        popoverRefs.current[agent.id] = ref;
                      }}
                    >
                      <EllipsisVerticalIcon
                        className={`grey-500 h-5 w-5`}
                        onClick={() => {
                          const pop = document.getElementById(
                            `pop_agent_${agent.id}`,
                          );
                          if (pop) {
                            if (!pop.classList.contains("hidden")) {
                              pop.classList.add("hidden");
                            } else {
                              pop.style.top =
                                popoverRefs.current[
                                  agent.id
                                ].getBoundingClientRect().top -
                                23 +
                                "px";
                              pop.classList.remove("hidden");
                            }
                          }
                        }}
                      />
                    </div>
                    <div
                      id={`pop_agent_${agent.id}`}
                      className="z-top absolute right-0 hidden w-fit rounded-lg border border-gray-200 bg-white p-2"
                    >
                      <div className="flex flex-row gap-x-1">
                        <div>
                          <BookmarkIcon
                            className={`h-4 w-4 cursor-pointer stroke-gray-500 ${
                              agent.pinned && "fill-blue-900"
                            }`}
                            onClick={(event) => {
                              favoriteAgent(agent);
                              closePopup();
                            }}
                          />
                        </div>
                        <div
                          className="h-4 w-4 cursor-pointer"
                          onClick={() => {
                            loadAgent(agent.id);
                            closePopup();
                          }}
                        >
                          <PencilIcon className="h-4 w-4 stroke-gray-500 hover:stroke-blue-900" />
                        </div>
                        <div
                          className="h-4 w-4 cursor-pointer"
                          onClick={() => {
                            setDeleteId(agent.id);
                            setShowConfirmDeletionAgent(true);
                            closePopup();
                          }}
                        >
                          <TrashIcon className="h-4 w-4 stroke-gray-500 hover:stroke-red-500" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      )}
      <NewModal
        title={agentDetail ? "Edit agent" : "Create agent"}
        customClass="!overflow-visible !max-h-none"
        open={showAgentForm}
        onClose={setShowAgentForm}
      >
        <AgentForm
          agent={agentDetail}
          onSuccess={(agent, isCreateNew) => {
            if (agentDetail && !isCreateNew) {
              agents.forEach((item, index) => {
                if (item.id === agentDetail.id) {
                  agents[index] = agent;
                  return false;
                }
              });
              setAgents(agents);
            } else {
              setAgents([...agents, agent]);
            }
            onItemChanged(agent);
            setAgentDetail(undefined);
            setShowAgentForm(false);
          }}
        />
      </NewModal>
      <ErrorModal
        open={showErrorModal}
        setOpen={setShowErrorModal}
        errorMessage={errorMessage}
      />
      <ConfirmationModal
        open={showConfirmDeletionAgent}
        title="Delete agent?"
        subtitle="This action cannot be undone"
        processing={deletingAgent}
        processingText={"Deleting..."}
        submitButtonText={"Delete"}
        onClose={() => {
          setDeleteId(0);
          setShowConfirmDeletionAgent(false);
        }}
        onSubmit={() => deleteAgent(deleteId)}
      />
    </div>
  );
};
