import { Popover } from "@headlessui/react";
import {
  InformationCircleIcon,
  UserCircleIcon,
} from "@heroicons/react/24/outline";
import React, { useState } from "react";
import { usePopper } from "react-popper";

export interface TooltipProps {
  title?: string;
  content?: string | JSX.Element;
  children?: React.ReactNode;
  showButton?: boolean;
  isTask?: boolean;
  text?: string;
  customClass?: string;
  tooltipClass?: string;
}

export default function Tooltip({
  title,
  content,
  children,
  showButton = true,
  isTask = false,
  text,
  customClass = "",
  tooltipClass = "",
}: TooltipProps) {
  let [referenceElement, setReferenceElement] = useState<HTMLElement | null>(
    null,
  );
  let [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const [arrowElement, setArrowElement] = useState<HTMLElement | null>(null);

  let { styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [
      { name: "arrow", options: { element: arrowElement } },
      {
        name: "offset",
        options: {
          offset: [0, 8],
        },
      },
    ],
  });

  return (
    <Popover>
      <Popover.Button
        ref={setReferenceElement}
        className="block outline-hidden"
      >
        {isTask ? (
          <UserCircleIcon
            className="ml-2 h-4 w-4"
            onMouseEnter={() => referenceElement?.click()}
            onMouseLeave={() => referenceElement?.click()}
          />
        ) : text !== undefined ? (
          <div
            onMouseEnter={() => referenceElement?.click()}
            onMouseLeave={() => referenceElement?.click()}
            className={`${customClass} line-clamp-3 text-left text-xs font-light`}
          >
            {text}
          </div>
        ) : (
          <div className="ml-2 h-4 w-4">
            <InformationCircleIcon className="h-4 w-4 text-blue-900" />
          </div>
        )}
      </Popover.Button>

      <Popover.Panel
        ref={setPopperElement}
        style={styles.popper}
        {...attributes.popper}
        className={`z-10 ml-1 min-h-[24px] max-w-sm rounded-md bg-gray-800 p-2 text-white ${tooltipClass}`}
      >
        {({ close }) => (
          <>
            <div className="grid gap-y-2">
              {title && <div className="text-xs font-bold">{title}</div>}
              {content && <div className="text-xs font-normal">{content}</div>}
              {children && (
                <div className="text-xs font-normal">{children}</div>
              )}
              {showButton && (
                <div className="flex justify-end text-blue-400">
                  <button
                    onClick={(event: any) => {
                      event.stopPropagation();
                      event.preventDefault();
                      close();
                    }}
                    className="text-xs font-bold"
                  >
                    Got it!
                  </button>
                </div>
              )}
            </div>
            <div
              ref={setArrowElement}
              style={styles.arrow}
              className="popper-arrow"
            />
          </>
        )}
      </Popover.Panel>
    </Popover>
  );
}
