import { XCircleIcon } from "@heroicons/react/24/solid";
import { ICellRendererParams } from "ag-grid-community";
import { useState } from "react";
import { deleteOpportunityResearchResult } from "../../../../api";
import { OpportunityResearchResult } from "../../../../api/types";
import { ConfirmationModal } from "../../../ConfirmationModal";
import { ShowSnackBar } from "../../../Utils/supportMessage";

type ConfigModalProps = {
  params: ICellRendererParams<OpportunityResearchResult>;
};

const OpportunityResearchResultsDeletePopUp = ({
  params,
}: ConfigModalProps) => {
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const deleteOrgSearchResult = () => {
    if (params.data) {
      setIsLoading(true);
      deleteOpportunityResearchResult(params.data.id)
        .then(() => {
          setConfirmDelete(false);
          params.api.applyServerSideTransaction({ remove: [params.node] });
          ShowSnackBar(`You deleted ${params.data?.opportunity.name}.`);
          setConfirmDelete(false);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => setIsLoading(false));
    }
  };
  return (
    <>
      <XCircleIcon
        className="h-5 w-5 cursor-pointer fill-blue-900"
        onClick={() => {
          setConfirmDelete(true);
        }}
      />
      <ConfirmationModal
        open={confirmDelete}
        title={`Are you sure to delete ${params.data?.opportunity.name}?`}
        subtitle="This action cannot be undone"
        processing={isLoading}
        processingText={"Deleting..."}
        submitButtonText={"Delete"}
        onClose={() => {
          setConfirmDelete(false);
        }}
        onSubmit={deleteOrgSearchResult}
      />
    </>
  );
};

export const OpportunityResearchResultsNameRenderer = (
  params: ICellRendererParams<OpportunityResearchResult>,
) => {
  if (params.data) {
    return (
      <div className="flex w-full items-center gap-x-4">
        <div className="h-5 w-5">
          <OpportunityResearchResultsDeletePopUp params={params} />
        </div>
        <span className="text-black">{params.data?.opportunity.name}</span>
      </div>
    );
  } else {
    return <></>;
  }
};
