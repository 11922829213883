import { ColDef, ICellRendererParams } from "ag-grid-community";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { OpportunityResearch } from "../../../api/types";
import Avatar from "../../Avatar";
import { dateFormatISO } from "../../utils";
import { OpportunityResearchNameRenderer } from "./OpportunityResearchNameRenderer";

export const OpportunityResearchColumns = () => {
  const colDefs: ColDef[] = [
    {
      field: "name",
      headerName: "Name",
      sortable: false,
      filter: false,
      width: 250,
      minWidth: 250,
      cellRenderer: OpportunityResearchNameRenderer,
    },
    {
      field: "prompt",
      headerName: "Prompt",
      sortable: false,
      filter: false,
      width: 300,
      minWidth: 300,
      cellRenderer: function (
        params: ICellRendererParams<OpportunityResearch>,
      ) {
        return <div className="my-1 line-clamp-3">{params.data?.prompt}</div>;
      },
    },
    {
      field: "persona",
      headerName: "Persona",
      sortable: false,
      filter: false,
      width: 300,
      minWidth: 300,
      cellRenderer: function (
        params: ICellRendererParams<OpportunityResearch>,
      ) {
        return <div className="line-clamp-3">{params.data?.persona}</div>;
      },
    },
    {
      field: "model",
      headerName: "Model",
      sortable: false,
      filter: false,
      width: 160,
      minWidth: 160,
      cellRenderer: function (
        params: ICellRendererParams<OpportunityResearch>,
      ) {
        return <div>{params.data?.model}</div>;
      },
    },
    {
      field: "tools",
      headerName: "Tools",
      sortable: false,
      filter: false,
      width: 300,
      minWidth: 300,
      cellRenderer: function (
        params: ICellRendererParams<OpportunityResearch>,
      ) {
        return <div>{params.data?.tools.join(", ")}</div>;
      },
    },
    {
      field: "group",
      headerName: "Group",
      sortable: false,
      filter: false,
      width: 160,
      minWidth: 160,
      cellRenderer: function (
        params: ICellRendererParams<OpportunityResearch>,
      ) {
        return <div>{params.data?.group.name}</div>;
      },
    },
    {
      field: "created_by",
      headerName: "Created By",
      sortable: false,
      filter: false,
      width: 200,
      minWidth: 200,
      cellRenderer: function (
        params: ICellRendererParams<OpportunityResearch>,
      ) {
        return (
          <div className="flex h-full items-center justify-start">
            <Avatar
              name={params.data?.created_by.name}
              size={32}
              url={params.data?.created_by.image_url}
              className="hidden sm:flex"
            />
            <span className="ml-2 max-w-xs text-ellipsis text-black">
              {params.data?.created_by.name}
            </span>
          </div>
        );
      },
    },
    {
      field: "created_on",
      headerName: "Created On",
      sortable: false,
      unSortIcon: true,
      filter: false,
      minWidth: 140,
      maxWidth: 140,
      cellRenderer: function (params: any) {
        return <div>{dateFormatISO(params.data.created_on)}</div>;
      },
    },
    {
      field: "updated_by",
      headerName: "Updated By",
      sortable: false,
      filter: false,
      width: 200,
      minWidth: 200,
      cellRenderer: function (
        params: ICellRendererParams<OpportunityResearch>,
      ) {
        return (
          <div className="flex h-full items-center justify-start">
            <Avatar
              name={params.data?.updated_by.name}
              size={32}
              url={params.data?.updated_by.image_url}
              className="hidden sm:flex"
            />
            <span className="ml-2 max-w-xs text-ellipsis text-black">
              {params.data?.updated_by.name}
            </span>
          </div>
        );
      },
    },
    {
      field: "updated_on",
      headerName: "Updated On",
      sortable: false,
      unSortIcon: true,
      filter: false,
      minWidth: 140,
      maxWidth: 140,
      cellRenderer: function (params: any) {
        return <div>{dateFormatISO(params.data.updated_on)}</div>;
      },
    },
  ];
  return colDefs;
};
