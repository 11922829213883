import { AxiosError } from "axios";
import { useState } from "react";
import { createOrganizationResearchResults } from "../../../../api";
import {
  OrganizationAutocompleteResult,
  OrganizationResearch,
  OrganizationsResearchResultsParams,
} from "../../../../api/types";
import ErrorModal from "../../../ErrorModal";
import NewModal from "../../../NewModal";
import OrganizationSelect from "../../../Select/OrganizationSelect";
import Spinner from "../../../Spinner";
import Tooltip from "../../../Tooltip";
import { parseAxiosError } from "../../../utils";
import { ShowSnackBar } from "../../../Utils/supportMessage";

type OrganizationResearchResultsAddOrganizationsModalProps = {
  organizationResearch: OrganizationResearch;
};

export const OrganizationResearchResultsAddOrganizationsModal = ({
  organizationResearch,
}: OrganizationResearchResultsAddOrganizationsModalProps) => {
  const [
    showAddOrganizationsForResearching,
    setShowAddOrganizationsForResearching,
  ] = useState(false);
  const [openModalError, setOpenModalError] = useState(false);
  const [errMessage, setErrorMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [organizations, setOrganizations] = useState<
    OrganizationAutocompleteResult[]
  >([]);
  const [includeVpcs, setIncludeVpcs] = useState(false);

  const addOrganizations = () => {
    // Only proceed if organizations are selected or include_vpcs is true
    if (organizations.length === 0 && !includeVpcs) {
      return;
    }

    setIsSubmitting(true);
    const payload: OrganizationsResearchResultsParams = {
      organization_pks: organizations.map((org) => org.value),
      include_vpcs: includeVpcs,
    };

    createOrganizationResearchResults(organizationResearch.id, payload)
      .then((response) => {
        if (response.status === 200 && response.data) {
          ShowSnackBar("Processing New Organization Research");
          setShowAddOrganizationsForResearching(false);
        }
      })
      .catch((err: AxiosError) => {
        setOpenModalError(true);
        setShowAddOrganizationsForResearching(false);
        switch (err.response?.status) {
          case 409:
          case 403:
            setErrorMessage(parseAxiosError(err));
            return;
          case 500:
            setErrorMessage(err.message);
            return;
          default:
            setErrorMessage("Something went wrong! Please try again.");
            return;
        }
      })
      .finally(() => {
        setIsSubmitting(false);
        setOrganizations([]);
        setIncludeVpcs(false);
      });
  };

  return (
    <>
      <button
        className="btn-primary w-fit"
        onClick={() => setShowAddOrganizationsForResearching(true)}
      >
        Add Organizations
      </button>
      <NewModal
        open={showAddOrganizationsForResearching}
        onClose={() => {
          setShowAddOrganizationsForResearching(false);
          setIsSubmitting(false);
          setOrganizations([]);
          setIncludeVpcs(false);
        }}
        title="Add Organizations"
        customClass="overflow-visible!"
        expanded={false}
      >
        <div className="flex flex-col gap-y-4">
          <div className="flex flex-row items-center gap-x-10">
            <div className="text-xs text-gray-900">Organizations</div>
            <div className="w-full">
              <OrganizationSelect
                withNinja
                value={organizations}
                isMulti
                onChange={(newValue: any) => {
                  if (newValue) {
                    setOrganizations(newValue);
                  }
                }}
                flexible={true}
                widthFull={true}
                showFullInfo={true}
              />
            </div>
          </div>
          <div className="flex flex-row items-center gap-x-2">
            <input
              type="checkbox"
              id="include-vpcs"
              checked={includeVpcs}
              onChange={(e) => setIncludeVpcs(e.target.checked)}
              className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
            />
            <label htmlFor="include-vpcs" className="text-xs text-gray-900">
              Include VPCs
            </label>
            <Tooltip content="All VPCs will be included" />
          </div>
          <div className="flex w-full flex-row items-center justify-end gap-x-1">
            <button
              type="button"
              className="btn-plain"
              onClick={() => {
                setIsSubmitting(false);
                setOrganizations([]);
                setIncludeVpcs(false);
                setShowAddOrganizationsForResearching(false);
              }}
            >
              Close
            </button>
            <button
              className={`btn-primary sm:ml-3 ${organizations.length === 0 && !includeVpcs && "disabled"}`}
              disabled={organizations.length === 0 && !includeVpcs}
              onClick={addOrganizations}
            >
              {isSubmitting ? (
                <div className="flex flex-row items-center gap-x-2 text-white">
                  <div>
                    <Spinner className="text-blue h-4 w-4" />
                  </div>
                  <div className="">Submitting....</div>
                </div>
              ) : (
                "Submit"
              )}
            </button>
          </div>
        </div>
      </NewModal>
      <ErrorModal
        open={openModalError}
        setOpen={setOpenModalError}
        errorMessage={errMessage}
      />
    </>
  );
};
