import Link from "next/link";
import { useRouter } from "next/router";

interface SideNavigationBarProps {
  updatedNavigation: any[];
  current: any;
  menuText: (text: string) => string;
}

const SideNavigationBar = ({
  updatedNavigation,
  current,
  menuText,
}: SideNavigationBarProps) => {
  const router = useRouter();

  return (
    <aside className="hidden w-20 flex-col md:block">
      <nav className="h-[92vh] flex-1 space-y-1">
        {updatedNavigation.map((item: any) =>
          item ? (
            <div key={item.key} className={`my-1 duration-300`}>
              <Link
                href={item.href}
                key={item.key}
                title={item.name}
                onClick={() => {
                  if (item.key === current?.key && item.key === "chatbot") {
                    router.reload();
                  } else {
                    router.push(item.href, undefined, {
                      shallow: true,
                    });
                  }
                }}
                className={`flex h-full w-full flex-col items-center text-sm font-light tracking-wide ${
                  item.key === current?.key
                    ? "border-blue-900 font-medium text-white"
                    : `border-transparent font-light text-blue-900`
                }`}
              >
                <div
                  className={`flex h-10 w-10 flex-col items-center justify-center duration-300 ${
                    item.key === current?.key
                      ? "rounded-full bg-blue-900"
                      : "hover:rounded-full hover:bg-blue-100 hover:text-blue-900"
                  }`}
                >
                  <item.icon
                    className={`h-6 w-6 shrink-0`}
                    aria-hidden="true"
                  />
                </div>
                <div
                  className={`text-2.5xs mb-2 origin-left text-center text-blue-900 ${
                    item.key === current?.key ? "font-semibold" : "font-normal"
                  }`}
                >
                  {menuText(item.name)}
                </div>
              </Link>
            </div>
          ) : null,
        )}
      </nav>
    </aside>
  );
};

export default SideNavigationBar;
