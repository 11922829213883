import {
  CellValueChangedEvent,
  ColumnState,
  GetContextMenuItemsParams,
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import DOMPurify from "dompurify";
import get from "lodash/get";
import { RefObject, useEffect, useMemo, useState } from "react";
import {
  LeadDetail,
  OpportunityDetail,
  PortfolioOrganizationItem,
  WorkTaskDetail,
} from "../../api/types";
import Avatar from "../Avatar";
import AvatarNumber from "../AvatarNumber";
import Spinner from "../Spinner";

// PAGINATION SIZE
export const paginationPageSize = 50;

export const UPDATE_GRID_OPTIONS_DELAY = 500;

export const useTotalBar = (props: any) => {
  const [totalItems, setTotalItems] = useState<number>(0);
  const [currentItems, setCurrentItems] = useState<number>(0);

  const updateStatusBar = () => {
    setTotalItems(props.api.totalItems);
    const displayItems =
      props.api.getDisplayedRowCount() > 0
        ? props.api.getDisplayedRowCount()
        : 0;
    if (
      displayItems > 1 &&
      displayItems % props.paginationPageSize === 1 &&
      props.api.totalItems !== displayItems
    ) {
      setCurrentItems(displayItems - 1);
    } else {
      setCurrentItems(displayItems);
    }
    showNoRowOverlay(props.api, props.api.totalItems);
  };

  useEffect(() => {
    props.api.addEventListener("modelUpdated", updateStatusBar);

    // Remember to remove the event listener when the component is destroyed
    return () => {
      if (!props.api.isDestroyed()) {
        props.api.removeEventListener("modelUpdated", updateStatusBar);
      }
    };
    // eslint-disable-next-line
  }, []);

  return totalItems === undefined ? (
    <div className="mt-2 h-6 w-6">
      <Spinner className="h-4 w-4" />
    </div>
  ) : (
    <div className="p-2 text-xs font-semibold">{`${currentItems > totalItems ? 0 : currentItems} of ${totalItems} items`}</div>
  );
};

export const useClientTotalBar = (props: any) => {
  const [totalItems, setTotalItems] = useState<number>(props.api.totalItems);
  const [currentItems, setCurrentItems] = useState<number>(0);

  const updateStatusBar = (params: any) => {
    setCurrentItems(
      props.api.getDisplayedRowCount() > 0
        ? props.api.getDisplayedRowCount()
        : 0,
    );
    setTotalItems(params.api.totalItems);
    if (params.api.totalItems === 0) {
      params.api.showNoRowsOverlay();
    } else {
      if (params.api.totalItems !== -1) {
        params.api.setGridOption("loading", false);
      }
    }
  };

  useEffect(() => {
    props.api.addEventListener("modelUpdated", updateStatusBar);

    // Remember to remove the event listener when the component is destroyed
    return () => {
      if (!props.api.isDestroyed()) {
        props.api.removeEventListener("modelUpdated", updateStatusBar);
      }
    };
    // eslint-disable-next-line
  }, []);

  return totalItems === undefined || totalItems === -1 ? (
    <div className="mt-2 h-6 w-6">
      <Spinner className="h-4 w-4" />
    </div>
  ) : (
    <div className="p-2 text-xs font-semibold">{`${currentItems} of ${totalItems} items`}</div>
  );
};

export const updateGridHeight = (
  gridRef: RefObject<AgGridReact<any>>,
  gridContainerId: string,
) => {
  if (gridRef.current) {
    const gridApi = gridRef.current.api as any;
    if (gridApi.totalItems > 10) {
      gridApi.setGridOption("domLayout", "normal");
      document.getElementById(gridContainerId)!.style.height = `${
        10 * 42 + 51
      }px`;
    } else {
      gridApi.setGridOption("domLayout", "autoHeight");
    }
  }
};

export const useTotalCompanyFormBar = (props: any) => {
  const [totalCompanies, setTotalCompanies] = useState<number>(0);
  const updateStatusBar = () => {
    setTotalCompanies(props.api.companies);
    showNoRowOverlay(props.api, props.api.companies);
  };

  useEffect(() => {
    props.api.addEventListener("modelUpdated", updateStatusBar);

    // Remember to remove the event listener when the component is destroyed
    return () => {
      if (!props.api.isDestroyed()) {
        props.api.removeEventListener("modelUpdated", updateStatusBar);
      }
    };
    // eslint-disable-next-line
  }, []);

  return (
    <div className="m-auto p-[6px] pt-2.5 text-xs font-semibold">{`${totalCompanies} ${
      totalCompanies > 1 ? "companies" : "company"
    }.`}</div>
  );
};

export const useTotalPagesBar = (props: any) => {
  const [totalPages, setTotalPages] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const updateStatusBar = () => {
    setTotalPages(props.api.totalPages);
    setCurrentPage(props.api.nextPage);
    showNoRowOverlay(props.api, props.api.totalPages);
  };

  useEffect(() => {
    props.api.addEventListener("modelUpdated", updateStatusBar);

    // Remember to remove the event listener when the component is destroyed
    return () => {
      if (!props.api.isDestroyed()) {
        props.api.removeEventListener("modelUpdated", updateStatusBar);
      }
    };
    // eslint-disable-next-line
  }, []);

  return totalPages === undefined ? (
    <div className="mt-2 h-6 w-6">
      <Spinner className="h-4 w-4" />
    </div>
  ) : (
    <div className="m-auto p-[6px] pt-2.5 text-xs font-semibold">{`${currentPage} of ${totalPages} pages`}</div>
  );
};

const showNoRowOverlay = (gridApi: any, rows: number) => {
  if (rows === 0) {
    gridApi.showNoRowsOverlay();
  } else {
    gridApi.setGridOption("loading", false);
  }
};

type AvatarWithMore = {
  members: any;
  size?: number;
};

export const AvatarWithMore = ({ members, size }: AvatarWithMore) => {
  var firstThree = members?.slice(0, 3);
  return (
    <div className="my-2 flex items-center -space-x-1.5 overflow-x-clip lg:mx-4">
      {firstThree?.map((people: any) => (
        <div
          key={people.id}
          className={`${
            size ? `w-[${size}px] h-[${size}px]` : "h-6 w-8"
          } flex cursor-default items-center`}
          title={people.name}
        >
          <Avatar
            name={people.name}
            size={size || 32}
            url={people.image_url}
            className="sm:flex"
          />
        </div>
      ))}
      {members?.length > 3 && (
        <div
          className="cursor-default"
          title={members
            .slice(3)
            .map((m: any) => m.name)
            .join(", ")}
        >
          <AvatarNumber
            number={members?.length - 3}
            size={size || 32}
            className="shrink-0 rounded-full ring-1 ring-white sm:flex"
          />
        </div>
      )}
    </div>
  );
};

export const arrangeFilterItems = (items: any, selectedItems: any) => {
  const selected = items.filter(
    (item: any) =>
      selectedItems.filter(
        (selectedItem: any) => selectedItem?.value === item.value,
      ).length > 0,
  );
  const unSelected = items.filter(
    (item: any) =>
      selectedItems.filter(
        (selectedItem: any) => selectedItem?.value === item.value,
      ).length === 0,
  );
  return [...selected, ...unSelected];
};

// get filter model
export const getFilterModel = (agGridOption: any) => {
  return agGridOption?.api.getFilterModel();
};

// get sort model
export const getSortModel = (agGridOption: any) => {
  let sortModel: ColumnState[] = [];
  if (agGridOption) {
    const columnState = agGridOption.api.getColumnState();
    if (columnState) {
      sortModel = columnState;
    }
  }
  return sortModel;
};

export const Tooltip = (tooltip: string) => {
  if (tooltip.length === 0) {
    return;
  }
  return (
    <p
      className="custom-tooltip break-word-custom w-fit max-w-[50vw] rounded-sm border border-gray-300 bg-gray-800 p-2 text-xs leading-4 whitespace-pre-line text-white"
      dangerouslySetInnerHTML={{
        __html: DOMPurify.sanitize(tooltip, {
          FORBID_TAGS: ["style"],
        }),
      }}
    />
  );
};

type TagName = {
  tagName: string;
  name: string;
};

export const HtmlTagComment = (text: string) => {
  const regex = /@\[(.+?)\]\(([0-9]+)\)/g;
  let tagNames: TagName[] = [];
  let comment = text?.trim() ?? "";
  if (comment.length > 0) {
    tagNames = Array.from(comment.matchAll(regex), (x) => {
      return {
        tagName: x[0],
        name: x[1],
      };
    });

    tagNames.forEach((tag: TagName) => {
      comment = comment.replace(
        tag.tagName,
        `<span class="bg-gray-200">${tag.name}</span>`,
      );
    });
  }
  return comment;
};

export const usePopupParent = (): HTMLElement | null => {
  return useMemo<HTMLElement | null>(() => {
    return document.querySelector("main");
  }, []);
};

export const join = (t: any, a: any, s: any) => {
  function format(m: any) {
    let f = new Intl.DateTimeFormat("en", m);
    return f.format(t);
  }
  return a.map(format).join(s);
};

export const date_format = [
  { year: "numeric" },
  { month: "2-digit" },
  { day: "2-digit" },
];

export const removeRowIfDifferenceCurrentFilter = async (
  changedData: any,
  event: CellValueChangedEvent,
) => {
  if (event.node.id) {
    const rowNode = event.api.getRowNode(event.node.id);
    if (rowNode) {
      rowNode.setData(changedData);

      const filterInstance: any = await event.api.getColumnFilterInstance(
        event.column.getColId(),
      );

      if (filterInstance) {
        if (filterInstance.providedMethods?.doesFilterPass) {
          const filterValues = get(
            event.api.getFilterModel(),
            event.column.getColId(),
          );
          if (filterValues) {
            const rowMeetsFilterCriteria =
              filterInstance.providedMethods.doesFilterPass({
                node: rowNode,
                data: get(rowNode.data, event.column.getColId().split(".")[0]),
                filterValues: filterValues,
              });
            if (!rowMeetsFilterCriteria) {
              event.api.applyServerSideTransaction({ remove: [rowNode] });
            }
          }
        } else if (
          filterInstance.doesFilterPass &&
          filterInstance.appliedModel
        ) {
          const rowMeetsFilterCriteria = filterInstance.doesFilterPass({
            node: rowNode,
            data: rowNode.data,
          });
          if (!rowMeetsFilterCriteria) {
            event.api.applyServerSideTransaction({ remove: [rowNode] });
          }
        }
      }
    }
  }
};

export const updateCheckFilters = (
  agGrid: any,
  data:
    | LeadDetail
    | OpportunityDetail
    | WorkTaskDetail
    | PortfolioOrganizationItem
    | null,
) => {
  if (agGrid.node.id) {
    const rowNode = agGrid.api.getRowNode(agGrid.node.id);
    if (rowNode) {
      if (data) {
        rowNode.setData(data);
        const filterModel = agGrid.api.getFilterModel();

        let values: boolean[] = [];
        Object.keys(filterModel).forEach((columnId: string) => {
          agGrid.api
            .getColumnFilterInstance(columnId)
            .then((filterInstance: any) => {
              if (filterInstance) {
                if (filterInstance.providedMethods?.doesFilterPass) {
                  const filterValues = get(
                    agGrid.api.getFilterModel(),
                    columnId,
                  );
                  if (filterValues) {
                    values.push(
                      filterInstance.providedMethods.doesFilterPass({
                        node: rowNode,
                        data: get(rowNode.data, columnId.split(".")[0]),
                        filterValues: filterValues,
                      }),
                    );
                  }
                } else if (
                  filterInstance.doesFilterPass &&
                  filterInstance.appliedModel
                ) {
                  values.push(
                    filterInstance.doesFilterPass({
                      node: rowNode,
                      data: rowNode.data,
                    }),
                  );
                }

                if (values.length === Object.keys(filterModel).length) {
                  const rowMeetsAllFilterCriteria = values.every(Boolean);
                  if (!rowMeetsAllFilterCriteria) {
                    agGrid.api.applyServerSideTransaction({
                      remove: [rowNode],
                    });
                  }
                }
              }
            });
        });
      } else {
        // Opportunity deleted and remove node
        agGrid.api.applyServerSideTransaction({ remove: [rowNode] });
      }
    }
  }
};

export const getContextMenuItems = (
  params: GetContextMenuItemsParams,
  fileName: string,
) => {
  return [
    "cut",
    "copy",
    "copyWithHeaders",
    "copyWithGroupHeaders",
    "paste",
    {
      name: "Export",
      icon: '<img src="/arrow-down-tray.svg" alt="arrowtopright" className="h-6 w-6" loading="lazy" />',
      subMenu: [
        {
          name: "CSV Export",
          icon: '<span class="ag-icon ag-icon-csv"></span>',
          action: () => {
            const csvParams = {
              fileName: `${fileName}.csv`,
            };
            params.api.exportDataAsCsv(csvParams);
          },
        },
        {
          name: "Excel Export",
          icon: '<span class="ag-icon ag-icon-excel"></span>',
          action: () => {
            const excelParams = {
              fileName: `${fileName}.xlsx`,
            };
            params.api.exportDataAsExcel(excelParams);
          },
        },
      ],
    },
  ];
};
