import { Popover, Transition } from "@headlessui/react";
import {
  CheckIcon,
  ChevronRightIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { Dispatch, Fragment, SetStateAction, useEffect, useState } from "react";
import useSWR from "swr";
import { useDebounce } from "use-debounce";
import { getter } from "../../api";
import { OrganizationResearch, PaginatedList, Success } from "../../api/types";

type OrganizationResearchDropdownProps = {
  organizationsResearchSelected: OrganizationResearch[];
  setOrganizationsResearchSelected: Dispatch<
    SetStateAction<OrganizationResearch[]>
  >;
};

export const OrganizationResearchDropdown = ({
  organizationsResearchSelected,
  setOrganizationsResearchSelected,
}: OrganizationResearchDropdownProps) => {
  const [orgQuery, setOrgQuery] = useState<string>("");
  const [debouncedQuery] = useDebounce(orgQuery, 500);
  const [organizationResearchData, setOrganizationResearchData] = useState<
    OrganizationResearch[] | undefined
  >([]);
  const { data } = useSWR<Success<PaginatedList<OrganizationResearch>>>(
    `/api/people_map/research?q=${debouncedQuery}&limit=50`,
    getter,
    { revalidateOnFocus: false },
  );

  useEffect(() => {
    if (!data && debouncedQuery.trim().length > 0) {
      setOrganizationResearchData([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedQuery]);

  useEffect(() => {
    if (data && data.data.items.length > 0) {
      if (debouncedQuery.length > 0) {
        setOrganizationResearchData(data.data.items);
      } else {
        let orgData =
          data.data.items?.filter(
            (org) =>
              organizationsResearchSelected.filter(
                (orgSelected) => org.id === orgSelected.id,
              ).length === 0,
          ) ?? [];

        setOrganizationResearchData([
          ...organizationsResearchSelected,
          ...orgData,
        ]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, debouncedQuery, organizationsResearchSelected]);

  return organizationResearchData ? (
    <div className="text-2xs relative ml-2 rounded-md bg-slate-100 px-2 py-1 font-semibold text-gray-900">
      <Popover key={`prompt`}>
        {({ open, close }) => {
          return (
            <>
              <Popover.Button className={`text-2xs`}>
                <div className="flex flex-row items-center space-x-2">
                  {organizationsResearchSelected.length > 0 && (
                    <div className="mr-1 h-1.5 w-1.5 rounded-full bg-blue-900" />
                  )}
                  Select Organization Research
                  <ChevronRightIcon className="ml-2 h-3 w-3" />
                </div>
              </Popover.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <Popover.Panel
                  className={`z-top 2xs:right-0 2xs:left-auto absolute bottom-8 left-0 w-52 rounded-lg border border-gray-200 bg-white p-2`}
                >
                  <div className="mb-1 flex w-full flex-col items-center justify-between gap-y-1">
                    <div className="relative w-full">
                      <input
                        value={orgQuery}
                        onChange={(event) => setOrgQuery(event.target.value)}
                        className="h-7 w-full rounded-sm border border-gray-200 px-2 text-xs font-light hover:border-blue-900 focus:outline-hidden"
                        placeholder={`Search Organization Research`}
                      />
                      {orgQuery.trim().length > 0 && (
                        <XMarkIcon
                          className="absolute top-1.5 right-1.5 h-4 w-4 cursor-pointer"
                          onClick={() => setOrgQuery("")}
                        />
                      )}
                    </div>
                  </div>
                  <div className="h-fit max-h-[305px] overflow-y-auto font-normal">
                    {orgQuery.length > 0 &&
                      organizationsResearchSelected.length > 0 && (
                        <div className="p-2 italic">
                          <b className="mr-1">{`(${organizationsResearchSelected.length})`}</b>
                          item(s) selected
                        </div>
                      )}
                    {organizationResearchData &&
                    organizationResearchData.length > 0 ? (
                      organizationResearchData.map(
                        (organizationResearch: OrganizationResearch) => (
                          <div
                            key={organizationResearch.id}
                            className={`mb-0.5 flex cursor-pointer flex-row items-center justify-between rounded px-1 py-1 text-xs ${
                              organizationsResearchSelected.filter(
                                (org) => org.id === organizationResearch.id,
                              ).length > 0 && "bg-gray-50"
                            }`}
                            onClick={() => {
                              if (
                                organizationsResearchSelected.filter(
                                  (org) => org.id === organizationResearch.id,
                                ).length > 0
                              ) {
                                setOrganizationsResearchSelected(
                                  organizationsResearchSelected.filter(
                                    (org) => org.id !== organizationResearch.id,
                                  ),
                                );
                              } else {
                                setOrganizationsResearchSelected([
                                  ...organizationsResearchSelected,
                                  organizationResearch,
                                ]);
                              }
                            }}
                          >
                            <div className="flex flex-row items-center">
                              <div className="line-clamp-1">
                                {organizationResearch.name}
                              </div>
                            </div>
                            {organizationsResearchSelected.filter(
                              (org) => org.id === organizationResearch.id,
                            ).length > 0 && (
                              <div className="h-5 w-5">
                                <CheckIcon className="h-5 w-5 text-blue-900" />
                              </div>
                            )}
                          </div>
                        ),
                      )
                    ) : (
                      <div className="h7 items-center p-1 font-light">
                        No Organization Research Found
                      </div>
                    )}
                  </div>
                </Popover.Panel>
              </Transition>
            </>
          );
        }}
      </Popover>
    </div>
  ) : (
    <></>
  );
};
