import { Montserrat } from "next/font/google";
import { useRouter } from "next/router";
import { PropsWithChildren, useEffect, useMemo } from "react";

import ChatbotView from "./Chatbot/ChatbotView";
import Navigation from "./Navigation";
import { NotificationBar } from "./NotificationBar";

const montserrat = Montserrat({
  weight: ["100", "200", "300", "400", "500", "600", "700", "800", "900"],
  subsets: ["cyrillic", "cyrillic-ext", "latin", "latin-ext", "vietnamese"],
  display: "swap",
  fallback: ["Arial", "sans-serif"],
  variable: "--font-montserrat",
});

const Layout = ({ children }: PropsWithChildren<{}>) => {
  const router = useRouter();

  useEffect(() => {
    if (document.body) {
      document.body.classList.add(montserrat.className);
      document.body.classList.add(montserrat.variable);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [document]);

  useEffect(() => {
    const closeValChat = (event: any) => {
      if (
        event.key === "Escape" &&
        !document.getElementById("headlessui-portal-root")
      ) {
        window.sessionStorage.setItem("showValStatus", "false");
        window.dispatchEvent(new Event("storage"));
      }
    };
    window.addEventListener("keydown", (event) => closeValChat(event));
    return () => {
      window.removeEventListener("keydown", (event) => closeValChat(event));
    };
    // eslint-disable-next-line
  }, []);

  const ChatBoxView = useMemo(() => {
    return (
      <div className={`flex flex-col`}>
        <ChatbotView defaultWidth={240} />
      </div>
    );
  }, []);

  return ["/signin"].includes(router.pathname) ? (
    <>{children}</>
  ) : (
    <div>
      {ChatBoxView}
      <Navigation>{children}</Navigation>
      <NotificationBar />
    </div>
  );
};

export default Layout;
