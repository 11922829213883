import Avatar from "../Avatar";

export const OrganizationSelectSkeleton = () => (
  <div className="flex w-full items-center gap-x-3">
    <Avatar name="" size={28} className="hidden xl:flex" shape="square" />
    <div className="grid gap-y-2">
      <div className="h-4 w-60 animate-pulse rounded-xs bg-gray-200"></div>
      <div className="h-3 w-60 animate-pulse rounded-xs bg-gray-200"></div>
    </div>
  </div>
);
export default OrganizationSelectSkeleton;
