import { Combobox, Transition } from "@headlessui/react";
import { CheckIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { ChevronUpDownIcon } from "@heroicons/react/24/solid";
import { Fragment, SetStateAction, useEffect, useRef, useState } from "react";
import { Option } from "../../api/types";
import { classNames } from "../utils";

type SelectProps = {
  loadOptions?: (inputValue: string) => Promise<any>;
  formatOptionLabel?: any;
  value?: any;
  onChange?: any;
  options?: Option[];
  isMulti?: boolean;
  isClearable?: boolean;
  id?: string;
  placeholder?: string;
  setInputValue?: any;
  className?: string;
  creatable?: boolean;
  isGroup?: boolean;
  formatOptionLabelSelected?: any;
  onInputChange?: (newValue: string) => void;
  isAdditionalField?: boolean;
  isSmallSelect?: boolean;
  onBlur?: () => void;
  closeOptionsAfterSelect?: boolean;
  checkIconFirst?: boolean;
};

type ChildrenComponentProps = {
  comboBoxButtonRef: any;
  selectedOptions: Option[];
  query: string;
  setQuery: React.Dispatch<SetStateAction<string>>;
  setSelectedOptions: React.Dispatch<SetStateAction<Option[]>>;
  open?: boolean;
  clearAll: () => void;
  listOptions: Option[] | GroupOptions[];
  setIsEnterPressed?: React.Dispatch<SetStateAction<boolean>>;
};

function Component({
  id,
  isMulti,
  value,
  onChange,
  comboBoxButtonRef,
  selectedOptions,
  formatOptionLabel,
  query,
  setQuery,
  isClearable = false,
  setSelectedOptions,
  clearAll,
  open,
  creatable = false,
  isGroup = false,
  formatOptionLabelSelected,
  onInputChange,
  listOptions,
  setIsEnterPressed,
  onBlur,
  closeOptionsAfterSelect,
  ...props
}: SelectProps & ChildrenComponentProps) {
  if (isMulti) {
    return (
      <Combobox value={value} onChange={onChange} multiple>
        {({ open }) => (
          <ChildrenComponent
            id={id}
            comboBoxButtonRef={comboBoxButtonRef}
            selectedOptions={selectedOptions}
            query={query}
            setQuery={setQuery}
            setSelectedOptions={setSelectedOptions}
            open={open}
            clearAll={clearAll}
            listOptions={listOptions}
            onInputChange={onInputChange}
            formatOptionLabel={formatOptionLabel}
            onChange={onChange}
            isMulti={isMulti}
            isClearable={isClearable}
            creatable={creatable}
            isGroup={isGroup}
            formatOptionLabelSelected={formatOptionLabelSelected}
            onBlur={onBlur}
            closeOptionsAfterSelect={closeOptionsAfterSelect}
            {...props}
          />
        )}
      </Combobox>
    );
  } else {
    return (
      <Combobox value={value} onChange={onChange}>
        {({ open }) => (
          <ChildrenComponent
            id={id}
            comboBoxButtonRef={comboBoxButtonRef}
            selectedOptions={selectedOptions}
            query={query}
            setQuery={setQuery}
            setSelectedOptions={setSelectedOptions}
            open={open}
            clearAll={clearAll}
            listOptions={listOptions}
            onInputChange={onInputChange}
            formatOptionLabel={formatOptionLabel}
            onChange={onChange}
            isClearable={isClearable}
            creatable={creatable}
            isGroup={isGroup}
            formatOptionLabelSelected={formatOptionLabelSelected}
            setIsEnterPressed={setIsEnterPressed}
            onBlur={onBlur}
            {...props}
          />
        )}
      </Combobox>
    );
  }
}

function ChildrenComponent({
  id,
  comboBoxButtonRef,
  selectedOptions,
  formatOptionLabel,
  query,
  onChange,
  setQuery,
  isMulti = false,
  isClearable = false,
  setSelectedOptions,
  clearAll,
  open,
  creatable = false,
  isGroup = false,
  formatOptionLabelSelected,
  onInputChange,
  listOptions,
  setIsEnterPressed,
  isSmallSelect,
  checkIconFirst = false,
  onBlur,
  closeOptionsAfterSelect,
  ...props
}: SelectProps & ChildrenComponentProps) {
  const inputRef = useRef<any>(null);

  useEffect(() => {
    if (open) {
      inputRef.current?.focus();
    }
  }, [open]);

  return (
    <div className="relative">
      <div className="flex h-auto min-h-[42px] w-full cursor-pointer flex-wrap overflow-x-hidden rounded-lg bg-white text-left focus:outline-hidden focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
        <Combobox.Button
          id={id}
          className="flex w-full items-center pr-14 pl-2"
          ref={comboBoxButtonRef}
          onKeyDown={(event: any) => {
            if (event.key === " ") {
              event.preventDefault();
              setQuery(query + " ");
              if (!open) {
                comboBoxButtonRef.current?.click();
              }
            } else if (event.key === "Backspace") {
              if (isMulti && selectedOptions.length > 0 && query.length === 0) {
                onChange(selectedOptions.slice(0, -1));
              }
            }
          }}
        >
          {!isMulti ? (
            formatOptionLabel ? (
              <div className="my-1 flex flex-wrap gap-x-1 gap-y-1 text-xs">
                {selectedOptions[0] && !query && formatOptionLabelSelected
                  ? formatOptionLabelSelected({
                      result: selectedOptions[0],
                    })
                  : formatOptionLabel(selectedOptions[0])}
                <Combobox.Input
                  placeholder={
                    selectedOptions.length > 0
                      ? ""
                      : props.placeholder || "Select..."
                  }
                  className={`absolute top-0 flex h-full w-[calc(100%-1rem)] min-w-[1rem] flex-1 border-0 px-0 text-xs outline-hidden focus:ring-0 ${
                    query ? "bg-white" : "bg-transparent"
                  }`}
                  ref={inputRef}
                  value={query}
                  onChange={(event) => {
                    setQuery(event.target.value);
                    if (!open) {
                      comboBoxButtonRef.current?.click();
                    }
                  }}
                  onKeyDown={(e: any) => {
                    if (e.key === "Enter") {
                      setIsEnterPressed && setIsEnterPressed(true);
                    }
                  }}
                  onBlur={onBlur && onBlur}
                />
              </div>
            ) : (
              <>
                <Combobox.Input
                  placeholder={
                    selectedOptions.length > 0
                      ? ""
                      : props.placeholder || "Select..."
                  }
                  className={`absolute top-0 flex h-full w-full min-w-[1rem] flex-1 border-0 px-1 text-xs outline-hidden focus:ring-0 ${
                    query ? "bg-white" : "bg-transparent"
                  }`}
                  ref={inputRef}
                  value={query}
                  onChange={(event) => {
                    setQuery(event.target.value);
                    if (!open) {
                      comboBoxButtonRef.current?.click();
                    }
                  }}
                  onKeyDown={(e: any) => {
                    if (e.key === "Enter") {
                      setIsEnterPressed && setIsEnterPressed(true);
                    }
                  }}
                  onBlur={onBlur && onBlur}
                />
                <ul className="my-1 flex w-full flex-wrap gap-x-1 gap-y-1">
                  {selectedOptions.length > 0 && (
                    <li
                      className="flex items-center rounded-sm px-1 py-1 text-xs"
                      key={selectedOptions[0].value}
                    >
                      {selectedOptions[0].label}
                    </li>
                  )}
                </ul>
              </>
            )
          ) : formatOptionLabel ? (
            <ul className="my-1 flex w-full flex-wrap items-center gap-1">
              {selectedOptions.map((option: Option) => (
                <li
                  className="max text-2xs z-0 flex h-fit items-center rounded-sm bg-gray-200 px-2 py-1"
                  key={option.value}
                >
                  {formatOptionLabelSelected
                    ? formatOptionLabelSelected({
                        result: option,
                      })
                    : formatOptionLabel(option)}
                  <XMarkIcon
                    className="ml-1 h-4 w-4 cursor-pointer hover:text-red-600"
                    onClick={(event) => {
                      event.stopPropagation();
                      setSelectedOptions((pre) => {
                        const newOptions = pre.filter(
                          (item) => item.value !== option.value,
                        );
                        onChange && onChange(newOptions);
                        return newOptions;
                      });
                    }}
                  />
                </li>
              ))}
              <Combobox.Input
                placeholder={
                  selectedOptions.length > 0
                    ? ""
                    : (props.placeholder ?? "Select...")
                }
                className={`flex min-w-[1rem] flex-1 border-0 p-0 text-xs outline-hidden focus:ring-0 ${
                  query ? "bg-white" : "bg-transparent"
                }`}
                value={query}
                onChange={(event) => {
                  setQuery(event.target.value);
                  if (!open) {
                    comboBoxButtonRef.current?.click();
                  }
                }}
                ref={inputRef}
                onBlur={onBlur && onBlur}
              />
            </ul>
          ) : (
            <ul className="my-1 flex w-full flex-wrap items-center gap-1">
              {selectedOptions.map((option: Option) => (
                <li
                  className="text-2xs flex h-fit items-center rounded-sm bg-gray-200 px-2 py-1"
                  key={option.value}
                >
                  {option.label.split(" ").length > 5
                    ? `${option.label.split(" ").slice(0, 5).join(" ")}...`
                    : option.label}
                  <XMarkIcon
                    className="ml-1 h-4 w-4 cursor-pointer hover:text-red-600"
                    onClick={(event) => {
                      event.stopPropagation();
                      setSelectedOptions((pre) => {
                        const newOptions = pre.filter(
                          (item) => item.value !== option.value,
                        );
                        onChange && onChange(newOptions);
                        return newOptions;
                      });
                    }}
                  />
                </li>
              ))}
              <Combobox.Input
                placeholder={
                  selectedOptions.length > 0
                    ? ""
                    : props.placeholder || "Select..."
                }
                className={`flex min-w-[1rem] flex-1 border-0 p-0 text-xs outline-hidden focus:ring-0 ${
                  query ? "bg-white" : "bg-transparent"
                }`}
                value={query}
                onChange={(event) => {
                  setQuery(event.target.value);

                  if (!open) {
                    comboBoxButtonRef.current?.click();
                  }
                }}
                ref={inputRef}
                onBlur={onBlur && onBlur}
              />
            </ul>
          )}
          <div className="absolute right-2 flex h-full items-center space-x-1 text-gray-400">
            {isClearable && selectedOptions.length > 0 && (
              <XMarkIcon
                className="h-5 w-5 cursor-pointer"
                onClick={clearAll}
              />
            )}
            <div className="flex h-full items-center">
              <ChevronUpDownIcon className="h-5 w-5" aria-hidden="true" />
            </div>
          </div>
        </Combobox.Button>
      </div>
      <Transition
        as={Fragment}
        show={open}
        leave="transition ease-in duration-100"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        afterLeave={() => {
          setQuery("");
        }}
      >
        <Combobox.Options className="z-top absolute mt-1 max-h-44 w-full overflow-x-hidden overflow-y-auto rounded-md border bg-white py-1 text-xs ring-1 shadow-md ring-black/5 focus:outline-none sm:max-h-80">
          {creatable && query.length > 0 && (
            <Combobox.Option
              key={-1}
              value={{ value: query, label: query, isNew: true }}
              className={
                "relative cursor-pointer bg-blue-100 py-2 pr-9 pl-3 text-blue-900 select-none"
              }
            >
              Create "{query}"
            </Combobox.Option>
          )}

          {(listOptions ?? []).length === 0 ? (
            <div
              className="relative cursor-pointer px-4 py-2 text-gray-700 select-none"
              key={-2}
            >
              Nothing found.
            </div>
          ) : !isGroup ? (
            (listOptions as Option[]).map((option) => (
              <Combobox.Option
                key={option.value}
                className={({ active }) =>
                  classNames(
                    active ? "bg-blue-100 text-blue-900" : "text-gray-900",
                    "relative cursor-pointer py-2 select-none",
                    checkIconFirst && "flex break-all",
                    isSmallSelect ? "" : "px-2",
                  )
                }
                value={option}
                onClick={() => {
                  if (open && closeOptionsAfterSelect) {
                    comboBoxButtonRef.current?.click();
                  }
                }}
              >
                {({ selected, active }) => {
                  const isOptionSelected = selectedOptions.find(
                    (item) => item.value.toString() === option.value.toString(),
                  );
                  return (
                    <>
                      {checkIconFirst ? (
                        <span
                          className={classNames(
                            active ? "text-blue-900" : "text-gray-900",
                            "inset-y-0 right-0 flex items-center",
                            isSmallSelect ? "" : "pr-2",
                          )}
                        >
                          {isOptionSelected ? (
                            <CheckIcon className="h-4 w-4" aria-hidden="true" />
                          ) : (
                            <div className="h-4 w-4" aria-hidden="true" />
                          )}
                        </span>
                      ) : null}
                      {formatOptionLabel ? (
                        formatOptionLabel(option)
                      ) : (
                        <div className="flex items-center">
                          <span
                            className={classNames(
                              isOptionSelected
                                ? "font-semibold"
                                : "font-normal",
                              "ml-3 block truncate",
                            )}
                          >
                            {option.label}
                          </span>
                        </div>
                      )}

                      {isOptionSelected && !checkIconFirst ? (
                        <span
                          className={classNames(
                            active ? "text-blue-900" : "text-gray-900",
                            "absolute inset-y-0 right-0 flex items-center",
                            isSmallSelect ? "" : "pr-4",
                          )}
                        >
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  );
                }}
              </Combobox.Option>
            ))
          ) : (
            (listOptions as GroupOptions[]).map((group) => {
              return (
                <div key={group.label}>
                  <p className="px-3 py-2 text-gray-500">{group.label}</p>
                  {group.validating ? (
                    <div className="relative px-4 py-2 text-gray-700 select-none">
                      Searching...
                    </div>
                  ) : group.options?.length === 0 ? (
                    <div
                      className="relative px-4 py-2 text-gray-700 select-none"
                      key={-3}
                    >
                      Nothing found.
                    </div>
                  ) : (
                    group.options.map((option) => (
                      <Combobox.Option
                        key={option.value}
                        className={({ active }) =>
                          classNames(
                            active
                              ? "bg-blue-100 text-blue-900"
                              : "text-gray-900",
                            "relative cursor-pointer py-2 select-none",
                            checkIconFirst && "break-all",
                            isSmallSelect ? "" : "pr-9 pl-3",
                          )
                        }
                        value={option}
                      >
                        {({ selected, active }) => {
                          const isOptionSelected = selectedOptions.find(
                            (item) =>
                              item.value.toString() === option.value.toString(),
                          );
                          return (
                            <>
                              {checkIconFirst ? (
                                <span
                                  className={classNames(
                                    active ? "text-blue-900" : "text-gray-900",
                                    "inset-y-0 right-0 flex items-center",
                                    isSmallSelect ? "" : "pr-2",
                                  )}
                                >
                                  {isOptionSelected ? (
                                    <CheckIcon
                                      className="h-4 w-4"
                                      aria-hidden="true"
                                    />
                                  ) : (
                                    <div
                                      className="h-4 w-4"
                                      aria-hidden="true"
                                    />
                                  )}
                                </span>
                              ) : null}
                              {formatOptionLabel ? (
                                formatOptionLabel(option)
                              ) : (
                                <div className="flex items-center">
                                  <span
                                    className={classNames(
                                      isOptionSelected
                                        ? "font-semibold"
                                        : "font-normal",
                                      "ml-3 block truncate",
                                    )}
                                  >
                                    {option.label}
                                  </span>
                                </div>
                              )}

                              {isOptionSelected && !checkIconFirst ? (
                                <span
                                  className={classNames(
                                    active ? "text-blue-900" : "text-gray-900",
                                    "absolute inset-y-0 right-0 flex items-center",
                                    isSmallSelect ? "" : "pr-4",
                                  )}
                                >
                                  <CheckIcon
                                    className="h-5 w-5"
                                    aria-hidden="true"
                                  />
                                </span>
                              ) : null}
                            </>
                          );
                        }}
                      </Combobox.Option>
                    ))
                  )}
                </div>
              );
            })
          )}
        </Combobox.Options>
      </Transition>
    </div>
  );
}

type GroupOptions = {
  label: string;
  options: Option[];
  validating?: boolean;
};

export default function Select({
  loadOptions,
  formatOptionLabel,
  value,
  onChange,
  options,
  isMulti = false,
  isClearable = false,
  id = "",
  setInputValue,
  className = "",
  creatable = false,
  isGroup = false,
  formatOptionLabelSelected,
  onInputChange,
  isAdditionalField = false,
  isSmallSelect = false,
  onBlur,
  placeholder,
  checkIconFirst = false,
  ...props
}: SelectProps) {
  const comboBoxButtonRef = useRef<any>(null);

  const [selectedOptions, setSelectedOptions] = useState<Option[]>([]);
  const [query, setQuery] = useState("");
  const [listOptions, setListOptions] = useState<Option[] | GroupOptions[]>([]);
  const [isEnterPressed, setIsEnterPressed] = useState<boolean>(false);

  useEffect(() => {
    setInputValue && setInputValue(query);
    if (loadOptions) {
      loadOptions(query).then((items) => {
        setListOptions(items);
      });
    }
    // eslint-disable-next-line
  }, [loadOptions, query]);

  useEffect(() => {
    if (options) {
      setListOptions(options);
    }
  }, [options]);

  useEffect(() => {
    if (value) {
      if (!Array.isArray(value)) {
        setSelectedOptions([value]);
      } else {
        setSelectedOptions(value);
      }
    } else {
      setSelectedOptions([]);
    }
    //eslint-disable-next-line
  }, [value, listOptions]);

  const onSelectedChange = async (
    item: (Option & { isNew: boolean }) | (Option & { isNew: boolean })[],
  ) => {
    setQuery("");
    if (!Array.isArray(item)) {
      setSelectedOptions([item]);
      if (item.label === item.value) {
        onChange && (isAdditionalField ? await onChange(item) : onChange(item));
        !item.isNew && (await comboBoxButtonRef?.current?.click());
      } else {
        onChange && onChange(item);
      }
    } else {
      let tempItems: Option[] = item.filter(
        (it) =>
          item.filter((dt) => dt.value.toString() === it.value.toString())
            .length < 2,
      );
      setSelectedOptions([...tempItems]);
      onChange && onChange([...tempItems]);
    }
  };

  useEffect(() => {
    if (isEnterPressed && !isMulti && isAdditionalField) {
      comboBoxButtonRef?.current?.click();
      setIsEnterPressed(false);
    }
    // eslint-disable-next-line
  }, [isEnterPressed]);

  useEffect(() => {
    if (onInputChange) {
      onInputChange(query);
    } else {
      if (query === "") {
        setListOptions(options as Option[]);
      } else {
        const newOptions = (options || []).filter((option) =>
          option.label.toLowerCase().includes(query.toLowerCase()),
        );
        setListOptions(newOptions as Option[]);
      }
    }
    //eslint-disable-next-line
  }, [query]);

  const clearAll = () => {
    setSelectedOptions([]);
    if (isMulti) {
      onChange && onChange([]);
    } else {
      onChange && onChange(null);
    }
  };

  return (
    <div className={`rounded-md border-1 border-gray-200 ${className}`}>
      <Component
        id={id}
        {...props}
        placeholder={placeholder}
        value={selectedOptions}
        onChange={onSelectedChange}
        isMulti={isMulti}
        comboBoxButtonRef={comboBoxButtonRef}
        selectedOptions={selectedOptions}
        query={query}
        setQuery={setQuery}
        setSelectedOptions={setSelectedOptions}
        clearAll={clearAll}
        listOptions={listOptions}
        onInputChange={onInputChange}
        formatOptionLabel={formatOptionLabel}
        isClearable={isClearable}
        creatable={creatable}
        isGroup={isGroup}
        formatOptionLabelSelected={formatOptionLabelSelected}
        setIsEnterPressed={setIsEnterPressed}
        isSmallSelect={isSmallSelect}
        onBlur={onBlur}
        checkIconFirst={checkIconFirst}
      />
    </div>
  );
}
