import { Dialog } from "@headlessui/react";
import * as HeroIcons from "@heroicons/react/24/solid";

type ModalHeaderProps = {
  title: string;
  className?: string;
  icon?: IconNames;
};

type IconNames = keyof typeof HeroIcons;

interface IconComponentProps {
  icon: IconNames;
}

const IconComponent: React.FC<IconComponentProps> = ({ icon }) => {
  const Icon = HeroIcons[icon];

  return (
    <div>
      <Icon className="h-6 w-6 text-blue-900" aria-hidden="true" />
    </div>
  );
};

export const ModalHeader = ({ title, className, icon }: ModalHeaderProps) => {
  return (
    <div className={`mb-2 sm:mb-0 sm:flex sm:items-center ${className}`}>
      <div className="mx-auto flex h-12 w-12 shrink-0 items-center justify-center rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
        {icon && <IconComponent icon={icon} />}
      </div>
      <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
        <Dialog.Title className="text-sm leading-6 font-semibold text-gray-900">
          {title}
        </Dialog.Title>
      </div>
    </div>
  );
};
