import { Dialog } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { PropsWithChildren, useEffect, useState } from "react";

type ComponentProps = {
  isPreventClickOutside?: boolean;
};

const Component = ({
  children,
  isPreventClickOutside,
}: PropsWithChildren<ComponentProps>) => {
  if (isPreventClickOutside) {
    return (
      <Dialog as="div" open={true} onClose={() => {}} className="h-0 w-0">
        {children}
      </Dialog>
    );
  } else {
    return <div className="h-0 w-0">{children}</div>;
  }
};

export const NotificationBar = ({
  isPreventClickOutside = false,
}: ComponentProps) => {
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [message, setMessage] = useState<string>("");

  const receiveMessage = (e: any) => {
    setTimeout(() => {
      setMessage(e.detail.message);
      setShowSnackbar(true);
    }, 200);
  };

  useEffect(() => {
    document.addEventListener("NOTIFICATION_MESSAGE", (e) => receiveMessage(e));

    return () => {
      window.removeEventListener("NOTIFICATION_MESSAGE", (e) =>
        receiveMessage(e),
      );
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (showSnackbar) {
      setTimeout(() => {
        setShowSnackbar(false);
      }, 2000);
    }
    // eslint-disable-next-line
  }, [showSnackbar]);

  const onClose = () => {
    setShowSnackbar(false);
  };

  if (!showSnackbar) {
    return <></>;
  }

  return (
    <Component isPreventClickOutside={isPreventClickOutside}>
      <div
        id="snackbar"
        className={`text-2xs ring-opacity-5 pointer-events-auto absolute bottom-8 left-1/2 z-1000 flex h-12 w-max -translate-x-1/2 transform items-center gap-x-2 overflow-hidden rounded-lg bg-black px-6 py-4 text-white ring-1 shadow-lg ring-black md:text-xs`}
      >
        {message}
        <button
          type="button"
          className="text-gray-500"
          onClick={() => {
            setShowSnackbar(false);
          }}
        >
          <XMarkIcon
            className="h-6 w-6 text-white"
            aria-hidden="true"
            onClick={onClose}
          />
        </button>
      </div>
    </Component>
  );
};
