import {
  ArrowPathIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { OpportunityResearch } from "../../../api/types";
import NewModal from "../../NewModal";
import {
  getFilterModel,
  getSortModel,
  paginationPageSize,
  UPDATE_GRID_OPTIONS_DELAY,
} from "../../Utils/agGridUtils";
import { getInitialGlobalGroupSelected } from "../../Utils/commons";
import { OpportunityResearchColumns } from "./OpportunityResearchColumns";
import OpportunityResearchForm from "./OpportunityResearchForm";
import { OpportunityResearchTable } from "./OpportunityResearchTable";
import {
  fetchOpportunityResearchs,
  OpportunityResearchContext,
  translateParams,
} from "./OpportunityResearchUtils";
import { OpportunityResearchResultsView } from "./Results/OpportunityResearchResultsView";

export const OpportunityResearchView = () => {
  const currentGroup = getInitialGlobalGroupSelected();
  const [query, setQuery] = useState<string>("");
  const [agGrid, setAgGrid] = useState<any>();
  const inputRef = useRef<HTMLInputElement>(null);

  const [showOpportunityResearchResults, setShowOpportunityResearchResults] =
    useState<false>();
  const [OpportunityResearch, setOpportunityResearch] =
    useState<OpportunityResearch>();

  const [refreshOpportunityResearch, setRefreshOpportunityResearch] =
    useState<boolean>(false);

  const receiveMessage = (e: any) => {
    setShowOpportunityResearchResults(e.detail.showOpportunityResearchResults);
    setOpportunityResearch(e.detail.OpportunityResearch);
  };

  useEffect(() => {
    document.addEventListener(
      "SHOW_Opportunity_RESEARCH_RESULTS",
      receiveMessage,
    );

    return () => {
      document.removeEventListener(
        "SHOW_Opportunity_RESEARCH_RESULTS",
        receiveMessage,
      );
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //New Opportunity Research
  const [showCreateOpportunityResearch, setShowCreateOpportunityResearch] =
    useState(false);

  // Search bar
  useEffect(() => {
    if (agGrid && inputRef && inputRef.current) {
      //filter opportunity
      const filterOpportunity = (event: any) => {
        const timeout = setTimeout(() => {
          if (agGrid.api.searchValue !== event.target.value) {
            setQuery(event.target.value);
            agGrid.api.searchValue = event.target.value;
            agGrid.api.updateGridOptions({
              serverSideDatasource: serverSideDataSource(event.target.value),
            });
          }
        }, UPDATE_GRID_OPTIONS_DELAY);
        return () => clearTimeout(timeout);
      };

      //init values
      inputRef.current.value = query as string;
      inputRef.current.addEventListener("keyup", filterOpportunity);

      //remove event
      return () => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        inputRef.current?.removeEventListener("keyup", filterOpportunity);
      };
    }
    // eslint-disable-next-line
  }, [inputRef, agGrid]);

  const serverSideDataSource = useCallback(
    (queryString = "") => {
      return {
        getRows(params: any) {
          const {
            request: { startRow },
          } = params;

          let filterModel = getFilterModel(params);
          let sortModel = getSortModel(params);

          // building api params
          var experimentParams = translateParams({
            startRow,
            filterModel: filterModel,
            sortModel: sortModel,
            paginationPageSize,
            queryString: queryString,
          });

          // Check if is last page, api will not call
          if (
            !params.api.totalPages ||
            (params.api.totalPages &&
              experimentParams.page <= params.api.totalPages)
          ) {
            //fetch data base on new params
            fetchOpportunityResearchs(
              params,
              experimentParams,
              paginationPageSize,
            );
          }
        },
      };
      // eslint-disable-next-line
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const value = useMemo(
    () => ({
      refreshOpportunityResearch,
      setRefreshOpportunityResearch,
      OpportunityResearch,
      agGrid,
      setAgGrid,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [agGrid, OpportunityResearch, refreshOpportunityResearch],
  );

  return (
    <OpportunityResearchContext.Provider value={value}>
      <div className="h-full p-3">
        <div
          className={`${showOpportunityResearchResults ? "" : "hidden"} flex h-full w-full`}
        >
          {OpportunityResearch && (
            <OpportunityResearchResultsView
              OpportunityResearch={OpportunityResearch}
            />
          )}
        </div>
        <div
          className={`flex h-full flex-col ${showOpportunityResearchResults ? "hidden" : ""}`}
        >
          <div className="flex flex-row justify-between">
            <div className="flex flex-col">
              <h1 className="text-xl font-bold">Opportunity Research</h1>
              <p className="text-2xs text-gray-700 italic">
                You may activate the research for a opportunity on its
                respective page.
              </p>
            </div>

            <div className="flex flex-row items-start gap-x-2">
              <div className="group relative h-7 w-7 rounded-full bg-yellow-200 p-1">
                <ArrowPathIcon
                  className="h-5 w-5 cursor-pointer"
                  onClick={() => setRefreshOpportunityResearch(true)}
                />
                <div className="z-top text-2xs invisible absolute top-8 right-0 inline-block w-max rounded-lg bg-gray-900 px-2 py-1 font-medium text-white shadow-sm group-hover:visible">
                  Reload Opportunity Research
                </div>
              </div>
              <button
                className="btn-primary w-fit"
                onClick={() => setShowCreateOpportunityResearch(true)}
              >
                New Opportunity Research
              </button>
            </div>
          </div>

          <div className="relative mt-5 flex">
            <MagnifyingGlassIcon
              className="absolute top-2/4 left-2 h-4 w-4 -translate-y-2/4 transform text-gray-400 hover:text-blue-900"
              aria-hidden="true"
            />
            <input
              ref={inputRef}
              className="peer block h-8 w-full truncate rounded-full border border-gray-400 pr-9 pl-9 text-xs hover:border-blue-900 focus:outline-hidden"
              placeholder="Search for Opportunity Research"
            />
          </div>
          <OpportunityResearchTable
            paginationPageSize={paginationPageSize}
            columnDefs={OpportunityResearchColumns()}
            serverSideDataSource={serverSideDataSource(query)}
          />
        </div>
      </div>
      <NewModal
        title={`Create New Opportunity Research (${currentGroup.label})`}
        onClose={setShowCreateOpportunityResearch}
        open={showCreateOpportunityResearch}
      >
        <OpportunityResearchForm
          onSuccess={() => {
            agGrid.api.refreshServerSide({ purge: true });
            setShowCreateOpportunityResearch(false);
          }}
        />
      </NewModal>
    </OpportunityResearchContext.Provider>
  );
};
