import {
  ArrowPathIcon,
  ChevronRightIcon,
  PlayCircleIcon,
} from "@heroicons/react/24/outline";
import { useMemo, useState } from "react";
import { refreshOrganizationResearchResults } from "../../../../api";
import { OrganizationResearch } from "../../../../api/types";
import { ConfirmationModal } from "../../../ConfirmationModal";
import ErrorModal from "../../../ErrorModal";
import { parseAxiosError } from "../../../utils";
import { ShowSnackBar } from "../../../Utils/supportMessage";
import { OrganizationResearchResultsAddOrganizationsModal } from "./OrganizationResearchResultsAddOrganizationsModal";
import OrganizationResearchResultsTable from "./OrganizationResearchResultsTable";
import { OrganizationResearchResultsContext } from "./OrganizationResearchResultsUtils";

type OrganizationResearchResultsViewProps = {
  organizationResearch: OrganizationResearch;
};

export const OrganizationResearchResultsView = ({
  organizationResearch,
}: OrganizationResearchResultsViewProps) => {
  const [refreshResult, setRefreshResult] = useState<boolean>(false);
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorModal, setErrorModal] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const contextValue = useMemo(
    () => ({
      refreshResult,
      setRefreshResult,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [refreshResult, organizationResearch],
  );

  const handleRefreshOrganizationResearchResults = () => {
    setIsLoading(true);
    refreshOrganizationResearchResults(organizationResearch.id)
      .then((response) => {
        if (response.status === 200) {
          setRefreshResult(true);
          setShowConfirmModal(false);
          ShowSnackBar("Organization Research Results Refresh Successfully!");
        }
      })
      .catch((error) => {
        setErrorModal(true);
        setErrorMessage(parseAxiosError(error));
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <OrganizationResearchResultsContext.Provider value={contextValue}>
      <div className="h-full w-full">
        <div className="flex flex-col">
          <div className="flex flex-row items-center justify-between">
            <div className="flex flex-row items-center gap-x-1">
              <div className="flex flex-row items-center gap-x-2 text-xs">
                <div
                  className="cursor-pointer text-xl font-bold text-blue-700"
                  onClick={() => {
                    document.dispatchEvent(
                      new CustomEvent("SHOW_ORGANIZATION_RESEARCH_RESULTS", {
                        detail: {
                          showOrganizationResearchResults: false,
                          organizationResearch: null,
                        },
                      }),
                    );
                  }}
                >
                  Organization Research
                </div>
              </div>
              <div className="h-4 w-4">
                <ChevronRightIcon className="h-4 w-4" />
              </div>

              <div className="text-xl font-bold">
                {organizationResearch.name}
              </div>
            </div>
            <div className="flex flex-row items-center gap-x-2">
              <div className="group relative h-7 w-7 rounded-full bg-yellow-200 p-1">
                <ArrowPathIcon
                  className="h-5 w-5 cursor-pointer"
                  onClick={() => setRefreshResult(true)}
                />
                <div className="z-top text-2xs invisible absolute top-8 right-0 inline-block w-max rounded-lg bg-gray-900 px-2 py-1 font-medium text-white shadow-sm group-hover:visible">
                  Reload Results
                </div>
              </div>
              <div className="group relative h-7 w-7 rounded-full bg-yellow-200 p-1">
                <PlayCircleIcon
                  className="h-5 w-5 cursor-pointer"
                  onClick={() => setShowConfirmModal(true)}
                />
                <div className="z-top text-2xs invisible absolute top-8 right-0 inline-block w-max rounded-lg bg-gray-900 px-2 py-1 font-medium text-white shadow-sm group-hover:visible">
                  Refresh All Organization Research Results
                </div>
              </div>
              <OrganizationResearchResultsAddOrganizationsModal
                organizationResearch={organizationResearch}
              />
            </div>
          </div>
        </div>
        <OrganizationResearchResultsTable />
      </div>
      <ConfirmationModal
        open={showConfirmModal}
        title="Are you sure you want to refresh the organization research results?"
        subtitle="All existing organization research results will be deleted"
        processing={isLoading}
        processingText={"Refreshing..."}
        submitButtonText={"Refresh"}
        onClose={() => {
          setShowConfirmModal(false);
        }}
        onSubmit={handleRefreshOrganizationResearchResults}
      />
      <ErrorModal
        open={errorModal}
        setOpen={setErrorModal}
        errorMessage={errorMessage}
      />
    </OrganizationResearchResultsContext.Provider>
  );
};
