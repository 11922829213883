import { OrganizationDetail } from "../api/types";
import Avatar from "./Avatar";
import Spinner from "./Spinner";

type ApolloOrganizationSuggestionProps = {
  handleClickFill: (organizationDetail: OrganizationDetail) => void;
  organizationDetail: OrganizationDetail;
  loading: boolean;
};

/**
 * Renders the Apollo information in a modal popup.
 * This component requires 1) state containing apollo data to be passed in, 2) state to handle modal opening, 3) method to handle the fill button
 * @param props
 * @returns
 */
const ApolloOrganizationSuggestion = ({
  handleClickFill,
  organizationDetail,
  loading,
}: ApolloOrganizationSuggestionProps) => {
  return (
    <div>
      <div className="flex flex-row">
        <Avatar
          name={organizationDetail.name}
          size={80}
          url={organizationDetail.logo_url}
          shape="square"
          orgId={organizationDetail.id}
        />
        <div className="flex w-full flex-col gap-y-1 px-4 py-2">
          {organizationDetail && (
            <div className="flex flex-row items-center space-x-2">
              <h2 className="w-24 text-xs text-gray-600">
                <b>Organization: </b>
              </h2>
              <h2 className="text-xs text-gray-600">
                {organizationDetail.name}
              </h2>
            </div>
          )}
          {organizationDetail && (
            <div className="flex flex-row space-x-2">
              <div className="min-w-[96px] text-xs text-gray-600">
                <b>Description: </b>
              </div>
              <h2 className="line-clamp-3 text-xs text-gray-600">
                {organizationDetail.description}
              </h2>
            </div>
          )}
          {(organizationDetail.website || organizationDetail.domain) && (
            <div className="flex flex-row space-x-2">
              <h2 className="w-24 text-xs text-gray-600">
                <b>Website: </b>
              </h2>
              <h2 className="line-clamp-3 text-xs text-gray-600">
                {organizationDetail.website ?? organizationDetail.domain}
              </h2>
            </div>
          )}
          {organizationDetail.headquarters && (
            <div className="flex flex-row space-x-2">
              <h2 className="w-24 text-xs text-gray-600">
                <b>Country: </b>
              </h2>
              <h2 className="line-clamp-3 text-xs text-gray-600">
                {organizationDetail.headquarters?.label}
              </h2>
            </div>
          )}
          {organizationDetail.linkedin && (
            <div className="flex flex-row space-x-2">
              <h2 className="w-24 text-xs text-gray-600">
                <b>LinkedIn: </b>
              </h2>
              <h2 className="line-clamp-3 text-xs text-gray-600">
                {organizationDetail.linkedin}
              </h2>
            </div>
          )}
          {organizationDetail.industries?.length > 0 && (
            <div className="flex flex-row space-x-2">
              <h2 className="w-24 text-xs text-gray-600">
                <b>Industries: </b>
              </h2>
              <h2 className="line-clamp-3 text-xs text-gray-600">
                {organizationDetail.industries?.map((t) => t.label).join(", ")}
              </h2>
            </div>
          )}
          {organizationDetail.investors?.length > 0 && (
            <div className="flex flex-row space-x-2">
              <h2 className="w-24 text-xs text-gray-600">
                <b>Technologies: </b>
              </h2>
              <h2 className="line-clamp-3 text-xs text-gray-600">
                {organizationDetail.investors?.map((t) => t.label).join(", ")}
              </h2>
            </div>
          )}
        </div>
      </div>
      {organizationDetail && (
        <div className="mt-2 flex justify-end">
          <button
            onClick={() => {
              handleClickFill(organizationDetail);
            }}
            className="btn-primary"
          >
            {loading ? (
              <div className="flex flex-row items-center gap-x-2 text-white">
                <div>
                  <Spinner className="h-4 w-4 text-white" />
                </div>
                <div>Filling</div>
              </div>
            ) : (
              "Fill"
            )}
          </button>
        </div>
      )}
    </div>
  );
};

export default ApolloOrganizationSuggestion;
