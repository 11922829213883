import { addMonths, format } from "date-fns";
import { ErrorMessage, ErrorMessageProps, FormikErrors } from "formik";
import { calculateVisibility } from "../../api";
import {
  AttachmentDetail,
  GroupAutocompleteResult,
  OpportunityDetail,
  OpportunityType,
  Option,
  OrganizationAutocompleteResult,
  PersonAutocompleteResult,
  TagAutocompleteResult,
  UserAutocompleteResult,
  Visibility,
} from "../../api/types";
import { capitalize } from "../utils";
import { isValidJson } from "../Utils/commons";

interface FieldErrorProps extends ErrorMessageProps {}

export const FieldError = ({ name }: FieldErrorProps) => (
  <ErrorMessage name={name}>
    {(error) => <div className="mt-1 flex w-full text-red-500">{error}</div>}
  </ErrorMessage>
);

export const OPPORTUNITY_FORM = "OpportunityForm";

// Build AdditionalField controls
export type AdditionalComponents = {
  opportunityTypeId: number;
  components: AdditionalComponent[];
};

export type AdditionalComponent = {
  title: string;
  type: string;
  fieldName: string;
  component: TextField | Dropdown;
  order: number;
  format?: string;
};

export type TextField = {
  hidden: boolean;
};

export type Dropdown = {
  options?: string[];
  definition?: string;
};

export type OpportunityDescription = {
  typeId: number;
  name: string;
  description: string;
  linkedDeals: OpportunityDetail[];
};

export const INVESTMENT_OPPORTUNITY_TYPE = "Investment Opportunity";

export type OpportunityFormValues = {
  name: string;
  opportunity_type: number | undefined | null | string;
  description: string;
  nameChanged?: boolean;
  descriptionChanged?: boolean;
  source_organization: OrganizationAutocompleteResult | any;
  target_organization: OrganizationAutocompleteResult | any;
  owner: UserAutocompleteResult | null;
  owner_groups: GroupAutocompleteResult[];
  funnel_stage: Option | null;
  visibility: Visibility;
  groups: GroupAutocompleteResult[];
  users: UserAutocompleteResult[];
  rejected: boolean;
  rejected_at: string | null;
  rejected_reason: string;
  to_revisit: boolean;
  to_revisit_at: string | null;
  to_revisit_reason: string;
  additional_fields: any | null;
  tags: TagAutocompleteResult[];
  partner: UserAutocompleteResult | null;
  team_members: UserAutocompleteResult[];
  generate_target_website?: boolean;
  target_website: string;
  generate_source_website?: boolean;
  source_website: string;
  attachments: File[] | undefined;
  attachment_ids: AttachmentDetail[];
  originator: UserAutocompleteResult | null;
  contacts: PersonAutocompleteResult[];
  rejection_reason: string;
  inactive_reason: string;
  country: string;
  sector: string;
  industry: string;
  therapeutic_area: string;
  sector_list: Sector[];
  type?: any;
  oppDescription?: OpportunityDescription[];
};

export type Sector = {
  sector: string;
  childs: string[] | any;
};

export const opportunityToFormValues = (
  opportunity: OpportunityDetail,
): OpportunityFormValues => {
  return {
    name: opportunity.name,
    nameChanged: opportunity.name?.trim().length > 0,
    opportunity_type: opportunity.opportunity_type?.id,
    description: opportunity.description,
    descriptionChanged: opportunity.description?.trim().length > 0,
    source_organization: {
      label: opportunity.source_organization?.name,
      value: opportunity.source_organization?.id,
      domain: opportunity.source_organization?.domain,
      image_url: opportunity.source_organization?.image_url,
      description: opportunity.source_organization?.description,
      country: opportunity.source_organization?.country,
    },
    target_organization: opportunity.target_organization && {
      label: opportunity.target_organization?.name,
      value: opportunity.target_organization?.id,
      domain: opportunity.target_organization?.domain,
      image_url: opportunity.target_organization?.image_url,
      description: opportunity.target_organization?.description,
      country: opportunity.target_organization?.country,
    },
    owner: {
      value: opportunity.owner.id,
      email: opportunity.owner.email,
      label: opportunity.owner.name,
      image_url: opportunity.owner.image_url,
    },
    owner_groups: opportunity.owner_groups?.map((group) => ({
      label: group.name,
      value: group.id,
    })),
    funnel_stage: {
      value: opportunity.funnel_stage.id,
      label: opportunity.funnel_stage.name,
    },
    visibility: calculateVisibility(opportunity.access_policy),
    groups: opportunity.access_policy
      ? opportunity.access_policy.groups.map((group) => ({
          label: group.name,
          value: group.id,
        }))
      : [],
    users: opportunity.access_policy
      ? opportunity.access_policy.users.map((user) => ({
          label: user.name,
          value: user.id,
          email: user.email,
          image_url: user.image_url,
        }))
      : [],
    rejected: opportunity.rejected_at !== null,
    rejected_at: opportunity.rejected_at
      ? format(new Date(opportunity.rejected_at), "yyyy-MM-dd")
      : null,
    rejected_reason: opportunity.rejected_reason || "",
    to_revisit: opportunity.to_revisit_at !== null,
    to_revisit_at: opportunity.to_revisit_at
      ? format(new Date(opportunity.to_revisit_at), "yyyy-MM-dd")
      : null,
    to_revisit_reason: opportunity.to_revisit_reason || "",
    additional_fields: opportunity.additional_fields,
    tags: opportunity.tags.map((tag) => ({
      label: tag.name,
      value: tag.id,
    })),
    team_members: opportunity.team_members.map((team) => ({
      label: team?.name,
      value: team?.id,
      email: team?.email,
      image_url: team?.image_url,
    })),
    partner: opportunity.partner
      ? {
          value: opportunity.partner.id,
          email: opportunity.partner.email,
          label: opportunity.partner.name,
          image_url: opportunity.partner.image_url,
        }
      : null,
    target_website: "",
    source_website: "",
    attachments: [],
    attachment_ids: opportunity.attachments,
    originator: opportunity.originator
      ? {
          value: opportunity.originator.id,
          email: opportunity.originator.email,
          label: opportunity.originator.name,
          image_url: opportunity.originator.image_url,
        }
      : null,
    contacts: opportunity.contacts.map((contact) => ({
      label: contact.name,
      value: contact.id,
      organization: "",
      image_url: contact.image_url || "",
    })),
    rejection_reason: opportunity.additional_fields?.rejection_reason || "",
    inactive_reason: opportunity.additional_fields?.inactive_reason || "",
    country: opportunity.additional_fields?.country || "",
    sector: opportunity.additional_fields?.sector || "",
    industry: opportunity.additional_fields?.industry || "",
    therapeutic_area: opportunity.additional_fields?.therapeutic_area || "",
    sector_list: [
      {
        sector: opportunity.additional_fields?.sector ?? "",
        childs: opportunity.additional_fields?.sub_sector ?? [],
      },
    ],
  };
};

type EmptyFormProps = {
  defaultOpportunityType?: number | undefined | null;
  org?: OrganizationAutocompleteResult | null;
  initialOwner: UserAutocompleteResult | null;
  selectedGroup: any;
  additionalFieldsValue?: { source: string };
};

export const emptyFormValue = ({
  defaultOpportunityType,
  org,
  initialOwner,
  selectedGroup,
  additionalFieldsValue,
}: EmptyFormProps): OpportunityFormValues => {
  return {
    name: org ? org.label : "",
    opportunity_type: defaultOpportunityType,
    description: org && org.description ? org.description : "",
    nameChanged: false,
    descriptionChanged: false,
    source_organization: org ? org : null,
    target_organization: null,
    owner: initialOwner,
    owner_groups: selectedGroup ? Array.of(selectedGroup) : [],
    funnel_stage: null,
    visibility: "groups",
    groups: selectedGroup ? Array.of(selectedGroup) : [],
    users: [],
    rejected: false,
    rejected_at: null,
    rejected_reason: "",
    to_revisit: false,
    to_revisit_at: format(addMonths(new Date(), 3), "yyyy-MM-dd"),
    to_revisit_reason: "",
    additional_fields: additionalFieldsValue ? additionalFieldsValue : null,
    tags: [],
    partner: null,
    team_members: [],
    target_website: "",
    source_website: "",
    attachments: undefined,
    attachment_ids: [],
    originator: initialOwner,
    contacts: [],
    rejection_reason: "",
    inactive_reason: "",
    country: "",
    sector: "",
    industry: "",
    therapeutic_area: "",
    sector_list: [],
    oppDescription: [],
  };
};

export const updateOpportunityInSession = ({
  defaultValues,
  data,
}: {
  defaultValues: OpportunityFormValues;
  data: OpportunityFormValues;
}) => {
  localStorage.setItem(
    OPPORTUNITY_FORM,
    JSON.stringify({
      ...data,
      ...{
        ...defaultValues,
        additional_fields: getPreSelectedAddtionalFields(
          data.additional_fields,
        ),
        opportunity_type: data.opportunity_type,
      },
    }),
  );
};

export const setOpportunityTypeInSession = (
  oppType: number | undefined,
  funnel_stage: Option,
) => {
  const opportunityFormStorage = localStorage.getItem(OPPORTUNITY_FORM);
  if (opportunityFormStorage && isValidJson(opportunityFormStorage)) {
    const data = JSON.parse(opportunityFormStorage);
    if (data) {
      localStorage.setItem(
        OPPORTUNITY_FORM,
        JSON.stringify({
          ...data,
          opportunity_type: oppType,
          funnel_stage: funnel_stage,
        }),
      );
    }
  } else {
    localStorage.setItem(
      OPPORTUNITY_FORM,
      JSON.stringify({
        opportunity_type: oppType,
        funnel_stage: funnel_stage,
      }),
    );
  }
};

export const getDefaultOppType = (opportunityTypes: OpportunityType[]) => {
  let oppTypeId: number | undefined;
  const opportunityFormStorage = localStorage.getItem(OPPORTUNITY_FORM);
  if (opportunityFormStorage && isValidJson(opportunityFormStorage)) {
    oppTypeId = JSON.parse(opportunityFormStorage).opportunity_type;
  }

  if (oppTypeId) {
    const oppType = opportunityTypes?.find((opp) => opp.id === oppTypeId);
    if (oppType) {
      setOpportunityTypeInSession(oppType.id, {
        value: oppType.funnel[0].id,
        label: oppType.funnel[0].name,
      });
    }
    return oppTypeId;
  } else {
    const oppType = opportunityTypes?.find(
      (opp) => opp.name === "Investment Opportunity",
    );
    if (oppType) {
      setOpportunityTypeInSession(oppType.id, {
        value: oppType.funnel[0].id,
        label: oppType.funnel[0].name,
      });
      return oppType.id;
    }
  }
};

export const getOpportunityType = (
  opportunityTypes: OpportunityType[],
  opportunityId: number,
): OpportunityType => {
  return opportunityTypes.filter(
    (opportunity: OpportunityType) => opportunity.id === opportunityId,
  )[0];
};

export const updatePreDescription = ({
  opportunityType,
  formValues,
  setFieldValue,
}: DescriptionProps) => {
  const preDescriptionSelected = formValues.oppDescription?.filter(
    (item) => item.typeId === opportunityType?.id,
  )[0];

  if (preDescriptionSelected) {
    preDescriptionSelected.name.length === 0
      ? updateName({
          opportunityType,
          formValues: formValues,
          setFieldValue,
        })
      : setFieldValue("name", preDescriptionSelected.name);

    preDescriptionSelected.description.length === 0
      ? updateDescription({
          opportunityType,
          formValues: formValues,
          setFieldValue,
        })
      : setFieldValue("description", preDescriptionSelected.description);
  }
};

type DescriptionProps = {
  opportunityType: OpportunityType | undefined;
  formValues: OpportunityFormValues;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => void;
};

export const updateNameAndDescriptionValues = ({
  opportunityType,
  formValues,
  setFieldValue,
}: DescriptionProps) => {
  if (!formValues.nameChanged) {
    updateName({
      opportunityType,
      formValues,
      setFieldValue,
    });
  }
  if (!formValues.descriptionChanged) {
    updateDescription({
      opportunityType,
      formValues,
      setFieldValue,
    });
  }
};

export type UpdateFieldValueProps = {
  opportunityType: OpportunityType | undefined;
  formValues: OpportunityFormValues;
  fieldName: string;
  newValue: string;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => void;
};

export const updateFieldValue = ({
  opportunityType,
  formValues,
  fieldName,
  newValue,
  setFieldValue,
}: UpdateFieldValueProps) => {
  formValues.oppDescription?.forEach((oppDesc: any) => {
    if (oppDesc.typeId === opportunityType?.id) {
      oppDesc[fieldName] = newValue;
    }
  });
  setFieldValue(fieldName, newValue);
  setFieldValue("oppDescription", formValues.oppDescription);
};

export const updateName = ({
  opportunityType,
  formValues,
  setFieldValue,
}: DescriptionProps) => {
  let text = "";
  if (opportunityType) {
    if (opportunityType.has_destination_organization) {
      if (formValues.source_organization && formValues.target_organization) {
        text = `${formValues.source_organization.label} <> ${formValues.target_organization.label}`;
      }
    } else {
      if (formValues.source_organization) {
        text = formValues.source_organization.label;
      }
    }
    updateFieldValue({
      opportunityType,
      formValues,
      fieldName: "name",
      newValue: text,
      setFieldValue,
    });
  }
};

export const updateDescription = ({
  opportunityType,
  formValues,
  setFieldValue,
}: DescriptionProps) => {
  let text = "";
  if (opportunityType) {
    if (opportunityType.has_destination_organization) {
      if (formValues.source_organization && formValues.target_organization) {
        text = `${opportunityType.name} - ${formValues.source_organization.label} <> ${formValues.target_organization.label}`;
      }
    } else {
      if (formValues.source_organization) {
        if (opportunityType.id === 2) {
          if (
            formValues.source_organization.description &&
            formValues.source_organization.description?.trim().length > 0
          ) {
            text = formValues.source_organization.description;
          } else {
            text = `${opportunityType.name} - ${formValues.source_organization.label}`;
          }
        } else {
          text = `${opportunityType.name} - ${formValues.source_organization.label}`;
        }
      }
    }
    updateFieldValue({
      opportunityType,
      formValues,
      fieldName: "description",
      newValue: text,
      setFieldValue,
    });
  }
};

export const generateDomainFromCompanyName = (companyName: string) => {
  const cleanedCompanyName = companyName.replace(/[^a-zA-Z0-9- ]/g, "");
  return cleanedCompanyName.toLowerCase().replace(/ /g, "_");
};

//Additional Fields validation
export const vvhcMandatoryFields: string[] = [
  "therapeutic_area",
  "industry",
  "inactive_reason",
];
export const vvseaiMandatoryFields: string[] = [
  "country",
  "sector",
  "rejection_reason",
  "round",
];
export const mandatoryFields: string[] = [
  "rejection_reason",
  "inactive_reason",
];

//Addtional Fields will show radio group
const vvseaiRadioFields = ["fund", "priority"];
const vvhcRadioFields = ["funds"];
export const radioFields: string[] = [...vvseaiRadioFields, ...vvhcRadioFields];

type additionalValidationProps = {
  fields: string[];
  addtionalFields: string[];
  formValues: OpportunityFormValues;
  errors: FormikErrors<OpportunityFormValues>;
};

export const additionalValidation = ({
  fields,
  addtionalFields,
  formValues,
  errors,
}: additionalValidationProps): FormikErrors<OpportunityFormValues> => {
  addtionalFields.forEach((addfield: string) => {
    if (["rejection_reason", "inactive_reason"].includes(addfield)) {
      if (formValues.rejected && !formValues.additional_fields?.[addfield]) {
        errors = {
          ...errors,
          [addfield]: capitalize(
            `${addfield.replaceAll("_", " ")} is required`,
          ),
        };
      }
    } else {
      if (
        fields.includes(addfield) &&
        (!formValues.additional_fields?.[addfield] ||
          (Array.isArray(formValues.additional_fields?.[addfield]) &&
            formValues.additional_fields?.[addfield].length === 0))
      ) {
        errors = {
          ...errors,
          [addfield]: capitalize(
            `${addfield.replaceAll("_", " ")} is required`,
          ),
        };
      }
    }
  });

  return errors;
};

export const isRequiredAddtionalField = (
  envString: string,
  fieldName: string,
): boolean => {
  let isRequired: boolean = false;
  switch (envString) {
    case "vvseai":
      isRequired = vvseaiMandatoryFields.includes(fieldName);
      break;
    case "vvhc":
      isRequired = vvhcMandatoryFields.includes(fieldName);
      break;
    default:
      isRequired = mandatoryFields.includes(fieldName);
      break;
  }
  return isRequired;
};

export const inputType = (item: AdditionalComponent) => {
  if (item.type === "number") {
    return "number";
  } else if (item.format === "date") {
    return "date";
  }
  return "text";
};

const previousAdditionalFields = ["fund", "country"];

export const getPreSelectedAddtionalFields = (additional_fields: any) => {
  if (additional_fields) {
    Object.keys(additional_fields).map((fieldName) => {
      if (!previousAdditionalFields.includes(fieldName)) {
        delete additional_fields[fieldName];
      }
    });
    return additional_fields;
  }
  return null;
};

// Linked deals base on Opp type
export const getLinkedDealsFromOppType = (
  values: OpportunityFormValues,
  oppTypeId: string | number | undefined,
  oppId?: number,
) => {
  if (oppTypeId) {
    const oppDescriptionByOppType = values.oppDescription?.filter(
      (opp) => opp.typeId === oppTypeId,
    );
    if (oppDescriptionByOppType?.length) {
      if (oppId) {
        return oppDescriptionByOppType[0].linkedDeals.filter(
          (deal: OpportunityDetail) => deal.id !== oppId,
        );
      }
      return oppDescriptionByOppType[0].linkedDeals;
    }
    return [];
  }
  return [];
};

//Should show linkded deals list
export const showLinkedDeals = (
  values: OpportunityFormValues,
  currentOpportunityType: OpportunityType | undefined,
) => {
  if (currentOpportunityType) {
    const linkedDeals = getLinkedDealsFromOppType(
      values,
      currentOpportunityType.id,
    );
    return linkedDeals.length > 0;
  }
  return false;
};

// Update linked into each opp type
export const updateLinkedDealsFormValues = (
  linkedDeals: OpportunityDetail[],
  values: OpportunityFormValues,
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => void,
) => {
  values.oppDescription?.forEach((opp, index) => {
    if (opp.typeId === values.opportunity_type) {
      opp.linkedDeals = linkedDeals;
    }
  });
  setFieldValue("oppDescription", values.oppDescription);
};
// End Linked deals
