import SpinnerCustom from "./SpinnerCustom";

export default function Splash() {
  return (
    <div className="flex h-screen flex-col items-center justify-center space-y-6 bg-gray-100">
      <div>
        <img
          src={"/labs-icon-blue-900-transparent.png"}
          className="h-40"
          alt="labs-logo"
          loading="lazy"
        />
      </div>
      <div>
        <SpinnerCustom />
      </div>
    </div>
  );
}
