import { ColumnState } from "ag-grid-community";
import { createContext, Dispatch } from "react";
import { getOrganizationResearchs } from "../../../api";
import { GetOrganizationResearchsParams } from "../../../api/types";

type translateParamsProps = {
  startRow: number | undefined;
  filterModel: { [key: string]: any };
  sortModel?: ColumnState[];
  paginationPageSize: number;
  queryString: string;
};

// build api params
export const translateParams = ({
  startRow,
  filterModel,
  sortModel,
  paginationPageSize,
  queryString,
}: translateParamsProps) => {
  var nextPage: number = 1;

  if (startRow) {
    nextPage = Math.floor(startRow / paginationPageSize) + 1;
  }

  const params: GetOrganizationResearchsParams = {
    page: nextPage,
    limit: paginationPageSize,
    q: queryString,
  };

  return params;
};

export const fetchOrganizationResearchs = (
  agGridOption: any,
  organizationResearchParams: GetOrganizationResearchsParams,
  paginationPageSize: number,
) => {
  getOrganizationResearchs(organizationResearchParams).then((response) => {
    if (response && !agGridOption.api.isDestroyed()) {
      const data = response.data.data;
      //set total pages
      agGridOption.api.totalPages = data.pages;
      agGridOption.api.totalItems = data.total;
      // get lastrow, Will not show loading icon in case row has no data
      const lastRow =
        data.items.length === paginationPageSize &&
        organizationResearchParams.page < agGridOption.api.totalPages
          ? -1
          : (agGridOption.api.totalPages - 1) * paginationPageSize +
            data.items.length;

      agGridOption.success({
        rowData: data.items,
        rowCount: lastRow,
      });
    }
  });
};

export interface OrganizationResearchContextData {
  refreshOrganizationResearch: boolean;
  setRefreshOrganizationResearch: Dispatch<any>;
  organizationResearch: any;
  agGrid: any;
  setAgGrid: Dispatch<any>;
}

// opportunity context
export const OrganizationResearchContext =
  createContext<OrganizationResearchContextData>({
    refreshOrganizationResearch: false,
    setRefreshOrganizationResearch: (() => undefined) as Dispatch<any>,
    organizationResearch: null,
    agGrid: null,
    setAgGrid: (() => undefined) as Dispatch<any>,
  });
