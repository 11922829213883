import { AxiosError } from "axios";
import { format } from "date-fns";
import {
  ErrorMessage,
  ErrorMessageProps,
  Field,
  FieldProps,
  Form,
  Formik,
  FormikErrors,
} from "formik";
import React, { useState } from "react";
import useSWR, { KeyedMutator } from "swr";
import {
  createPortfolioOrganization,
  deletePortfolioOrganization,
  getter,
  updatePortfolioOrganization,
} from "../api";
import {
  FundAutocompleteResult,
  GroupAutocompleteResult,
  OrganizationAutocompleteResult,
  OrganizationStub,
  PaginatedList,
  PortfolioOrganizationItem,
  Success,
  TagAutocompleteResult,
  UserAutocompleteResult,
} from "../api/types";
import { useCurrentUser } from "./AuthProvider";
import { ConfirmationModal } from "./ConfirmationModal";
import ErrorModal from "./ErrorModal";
import FormCompanySelectField from "./Form/FormCompanySelectField";
import FormUserSelectField from "./Form/FormUserSelectField";
import AutocompleteMultiselect from "./Select/AutocompleteMultiselect";
import GroupSelectAll from "./Select/GroupSelectAll";
import Select from "./Select/Select";
import Spinner from "./Spinner";
import { classNames, parseAxiosError } from "./utils";
import { GLOBAL_GROUP_KEY } from "./Utils/constant";
import { ShowSnackBar } from "./Utils/supportMessage";

interface FieldErrorProps extends ErrorMessageProps {}

const FieldError = ({ name }: FieldErrorProps) => (
  <ErrorMessage name={name}>
    {(error) => <div className="mt-1 text-xs text-red-500">{error}</div>}
  </ErrorMessage>
);

const healthOptions = [
  { value: "red", label: "Red" },
  { value: "yellow", label: "Yellow" },
  { value: "green", label: "Green" },
];

const monthOptions = [
  { value: 1, label: "January" },
  { value: 2, label: "February" },
  { value: 3, label: "March" },
  { value: 4, label: "April" },
  { value: 5, label: "May" },
  { value: 6, label: "June" },
  { value: 7, label: "July" },
  { value: 8, label: "August" },
  { value: 9, label: "September" },
  { value: 10, label: "October" },
  { value: 11, label: "November" },
  { value: 12, label: "December" },
];

interface PortfolioFormValues {
  organization: OrganizationAutocompleteResult | null;
  owner: UserAutocompleteResult | null;
  investment_partner: UserAutocompleteResult | null;
  group: GroupAutocompleteResult | null;
  health: { value: string; label: string } | null;
  currency: { value: string; label: string } | null;
  fiscal_start_month: { value: number; label: string } | null;
  tags: TagAutocompleteResult[];
  exited: boolean;
  exited_at: string | null;
  exited_description: string;
  investment_funds: FundAutocompleteResult[];
}

function portfolioToFormValues(
  portfolio: PortfolioOrganizationItem,
): PortfolioFormValues {
  return {
    organization: {
      label: portfolio.organization.name,
      value: portfolio.organization.id,
      domain: portfolio.organization.domain,
      image_url: portfolio.organization.image_url,
      description: portfolio.organization.description,
    },
    owner: portfolio.owner
      ? {
          value: portfolio.owner.id,
          email: portfolio.owner.email,
          label: portfolio.owner.name,
        }
      : null,
    investment_partner: portfolio.investment_partner
      ? {
          value: portfolio.investment_partner.id,
          email: portfolio.investment_partner.email,
          label: portfolio.investment_partner.name,
        }
      : null,
    group: {
      label: portfolio.group.name,
      value: portfolio.group.id,
    },
    health: portfolio.health
      ? {
          value: portfolio.health,
          label:
            portfolio.health.charAt(0).toUpperCase() +
            portfolio.health.slice(1),
        }
      : null,
    currency: {
      label: portfolio.currency,
      value: portfolio.currency,
    },
    fiscal_start_month: {
      label: monthOptions[portfolio.fiscal_start_month - 1].label,
      value: portfolio.fiscal_start_month,
    },
    tags: portfolio.tags.map((tag) => ({
      label: tag.name,
      value: tag.id,
    })),
    investment_funds: portfolio.investment_fund?.map((fund) => ({
      label: fund.name,
      value: fund.id,
    })),
    exited: portfolio.exited_at !== null,
    exited_at: portfolio.exited_at
      ? format(new Date(portfolio.exited_at), "yyyy-MM-dd")
      : null,
    exited_description: portfolio.exited_description || "",
  };
}

type PortfolioFormProps = {
  portfolio?: PortfolioOrganizationItem;
  pk?: string;
  defaultOrganization?: OrganizationStub;
  setIsOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  mutate?: KeyedMutator<Success<PaginatedList<PortfolioOrganizationItem>>>;
  hidden?: boolean;
  success?: (portfolio: PortfolioOrganizationItem) => void;
};

const PortfolioForm = ({
  portfolio,
  pk,
  defaultOrganization,
  setIsOpen,
  mutate,
  hidden = false,
  success,
}: PortfolioFormProps) => {
  const { data: selectedGroup } =
    useSWR<GroupAutocompleteResult>(GLOBAL_GROUP_KEY);
  const { user: currentUser } = useCurrentUser();
  const initialOwner = {
    label: currentUser.name,
    value: currentUser.id,
    email: currentUser.email,
    image_url: currentUser.image_url,
  };
  const [error, setError] = useState<AxiosError>();
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [errorModal, setErrorModal] = useState<boolean>(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { data: currencyData } = useSWR<any>(
    "/api/portfolio/financial/currencies",
    getter,
  );
  let currencyArr: GroupAutocompleteResult[] = [];
  for (let i = 0; i < currencyData?.data?.length; i++) {
    let itemCur = {
      value: 0,
      label: "",
    };
    itemCur.value = i;
    itemCur.label = currencyData.data[i];
    currencyArr.push(itemCur as any);
  }

  const emptyForm: PortfolioFormValues = {
    organization: defaultOrganization
      ? {
          label: defaultOrganization.name,
          value: defaultOrganization.id,
          domain: defaultOrganization.domain,
          image_url: defaultOrganization.image_url,
          description: defaultOrganization.description,
        }
      : null,
    owner: initialOwner,
    investment_partner: initialOwner,
    group: selectedGroup ?? null,
    health: { value: "green", label: "Green" },
    currency: { value: "254", label: "USD" },
    fiscal_start_month: { value: 1, label: "January" },
    tags: [],
    exited: false,
    exited_at: null,
    exited_description: "",
    investment_funds: [],
  };

  const initialValues = portfolio
    ? portfolioToFormValues(portfolio)
    : emptyForm;

  const clickDeletePortfolioOrganization = (pk: string | undefined) => {
    if (pk) {
      setIsLoading(true);
      deletePortfolioOrganization(pk)
        .then((response) => {
          if (response.data.success) {
            if (mutate) {
              mutate();
            }
            ShowSnackBar("You deleted organization.");
          }
        })
        .catch((error) => {
          setErrorMessage(parseAxiosError(error));
          setErrorModal(true);
        })
        .finally(() => {
          setIsLoading(false);
          setConfirmDelete(false);
        });
    }
  };

  const handleSubmit = (values: PortfolioFormValues) => {
    const payLoad = {
      organization: values.organization?.value as number,
      owner: values.owner?.value as number,
      investment_partner: values.investment_partner?.value as number,
      group: values.group?.value as number,
      health: values.health?.value,
      currency: values.currency?.label,
      fiscal_start_month: values.fiscal_start_month?.value,
      tags: values.tags.map((tag) => tag.label),
      exited_at: values.exited ? values.exited_at : null,
      exited_description: values.exited ? values.exited_description : "",
      investment_funds: values.investment_funds.map((fund) => fund.value),
    };
    const submitFunction = pk
      ? updatePortfolioOrganization(payLoad, pk)
      : createPortfolioOrganization(payLoad);
    return submitFunction
      .then((res) => {
        if (res.data) {
          const dataPortfolio = res.data.data;
          if (mutate) {
            mutate();
          }
          if (setIsOpen) {
            setIsOpen(false);
          }
          if (success) {
            success(dataPortfolio);
          }
          ShowSnackBar("Portfolio Organization Added Successfully");
        }
      })
      .catch((err: AxiosError) => {
        setError(err);
        switch (err.response?.status) {
          case 409:
            setErrorMessage("Organization is already in Portfolio");
            break;
          case 403:
            setErrorMessage(parseAxiosError(err));
            setErrorModal(true);
            break;
          default:
            setErrorMessage(err.message);
            break;
        }
      });
  };

  const validate = (values: PortfolioFormValues) => {
    let errors: FormikErrors<PortfolioFormValues> = {};
    if (!values.organization) {
      errors.organization = "Organization is required";
    }
    if (!values.owner) {
      errors.owner = "Owner is required";
    }
    if (!values.group) {
      errors.group = "Group is required";
    }

    if (!values.investment_partner) {
      errors.investment_partner = "Partner is required";
    }

    if (!values.health) {
      errors.investment_partner = "Health is required";
    }

    if (!values.currency) {
      errors.investment_partner = "Currency is required";
    }

    if (!values.fiscal_start_month) {
      errors.investment_partner = "Fiscal Start Month is required";
    }

    if (
      values.exited &&
      (values.exited_at === null || !values.exited_description)
    ) {
      errors.exited_description = "Exit description is required";
    }
    return errors;
  };

  return (
    <div>
      {error && <p className="text-red-500">{errorMessage}</p>}
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validate={validate}
      >
        {({
          setFieldValue,
          isSubmitting,
          errors,
          touched,
          resetForm,
          values,
        }) => (
          <Form>
            <div className="relative mt-5 grid grid-cols-1 gap-x-10 gap-y-4 text-xs lg:grid-cols-2">
              <div
                className={`text-2xs relative w-full md:text-xs ${hidden && "hidden"}`}
              >
                <Field
                  as={FormCompanySelectField}
                  name="organization"
                  label="Organization"
                  customClass={`relative h-fit w-full items-center rounded-md ${
                    touched.organization && errors.organization
                      ? "border-1 border border-red-500"
                      : values.organization
                        ? "border-1 border border-blue-900"
                        : ""
                  }`}
                  errors={
                    errors.organization ? String(errors.organization) : ""
                  }
                  touched={Boolean(touched.organization)}
                  placeholder={"Select Organization"}
                  flexible={true}
                  withNinja={true}
                  required
                  onChange={(sourceValue: any) => {
                    setFieldValue("organization", sourceValue);
                    if (error) {
                      setError(undefined);
                    }
                  }}
                />
              </div>

              <div className="flex w-full flex-col">
                <label className="mt-0.5 w-28 shrink-0 text-left font-semibold">
                  Fund
                </label>
                <div className="w-full">
                  <div className="relative">
                    <Field name="investment_funds">
                      {({ field, form: { setFieldValue } }: FieldProps) => (
                        <AutocompleteMultiselect
                          placeholder="Select Fund"
                          id="fundField"
                          className={`relative h-fit w-full items-center rounded-md ${
                            touched.investment_funds && errors.investment_funds
                              ? "border-1 border-red-500"
                              : values.investment_funds.length > 0
                                ? "border-1 border-blue-900"
                                : ""
                          }`}
                          autocompleteEndpoint="/api/people_map/autocomplete/investment_funds"
                          selected={field.value}
                          onChange={(newValue) =>
                            setFieldValue(field.name, newValue)
                          }
                          creatable={false}
                        />
                      )}
                    </Field>
                  </div>
                  <FieldError name="investment_funds" />
                </div>
              </div>
              <Field>
                {({ form: { values, touched, errors } }: FieldProps) => {
                  return (
                    <FormUserSelectField
                      name="owner"
                      label="Owner"
                      customClass={`relative h-fit w-full items-center rounded-md ${
                        touched.owner && errors.owner
                          ? "border-1 border border-red-500"
                          : values.owner
                            ? "border-1 border border-blue-900"
                            : ""
                      }`}
                      errors={errors.owner ? String(errors.owner) : ""}
                      touched={Boolean(touched.owner)}
                      required
                      isClearable={true}
                    />
                  );
                }}
              </Field>
              <Field>
                {({ form: { values, touched, errors } }: FieldProps) => {
                  return (
                    <FormUserSelectField
                      name="investment_partner"
                      label="Partner"
                      placeholder="Select Partner"
                      customClass={`relative h-fit w-full items-center rounded-md ${
                        touched.investment_partner && errors.investment_partner
                          ? "border-1 border border-red-500"
                          : "border-1 border border-blue-900"
                      }`}
                      errors={
                        errors.investment_partner
                          ? String(errors.investment_partner)
                          : ""
                      }
                      touched={Boolean(touched.investment_partner)}
                      required
                      isClearable={true}
                    />
                  );
                }}
              </Field>

              <div className="flex w-full flex-col justify-center">
                <label className="flex w-28 shrink-0 flex-row text-left font-semibold">
                  Group
                  <div className="text-sm font-semibold text-red-600">*</div>
                </label>
                <div
                  className={`relative h-fit w-full items-center rounded-md ${
                    touched.group && errors.group
                      ? "border-1 border-red-500"
                      : values.group
                        ? "border-1 border-blue-900"
                        : ""
                  }`}
                >
                  <Field name="group">
                    {({ field, form: { setFieldValue } }: FieldProps) => (
                      <GroupSelectAll
                        id="groupsField"
                        {...field}
                        onChange={(newValue: any) => {
                          setFieldValue(field.name, newValue);
                          if (error) {
                            setError(undefined);
                          }
                        }}
                      />
                    )}
                  </Field>
                </div>

                <FieldError name="group" />
              </div>
              <div className="flex w-full flex-col">
                <label className="flex w-28 flex-row text-left font-semibold">
                  Health
                  <div className="text-sm font-semibold text-red-600">*</div>
                </label>
                <Field name="health">
                  {({ field, form: { setFieldValue } }: FieldProps) => (
                    <Select
                      value={field.value}
                      className={`rounded-md ${
                        touched.health && errors.health
                          ? "border-1 border-red-500!"
                          : "border-1 border-blue-900!"
                      }`}
                      options={healthOptions}
                      onChange={(newValue: any) => {
                        setFieldValue(field.name, newValue);
                      }}
                    />
                  )}
                </Field>
                <FieldError name="health" />
              </div>
              <div className="flex w-full flex-col">
                <label className="flex w-28 shrink-0 flex-row text-left font-semibold">
                  Currency
                  <div className="text-sm font-semibold text-red-600">*</div>
                </label>
                <Field name="currency">
                  {({ field, form: { setFieldValue } }: FieldProps) => (
                    <Select
                      value={field.value}
                      className={`rounded-md ${
                        touched.currency && errors.currency
                          ? "border-1 border-red-500!"
                          : "border-1 border-blue-900!"
                      }`}
                      options={currencyArr}
                      onChange={(newValue: any) => {
                        setFieldValue(field.name, newValue);
                      }}
                    />
                  )}
                </Field>
                <FieldError name="curency" />
              </div>
              <div className="flex w-full flex-col">
                <div className="shrink-0 text-left">
                  <label className="mb-1 inline-flex flex-wrap font-semibold md:mb-0">
                    <span className="w-full">
                      Fiscal Start Month
                      <span className="text-sm font-semibold text-red-600">
                        *
                      </span>
                    </span>
                  </label>
                </div>
                <Field name="fiscal_start_month">
                  {({ field, form: { setFieldValue } }: FieldProps) => (
                    <Select
                      value={field.value}
                      className={`rounded-md ${
                        touched.fiscal_start_month && errors.fiscal_start_month
                          ? "border-1 border-red-500!"
                          : "border-1 border-blue-900!"
                      }`}
                      options={monthOptions}
                      onChange={(newValue: any) => {
                        setFieldValue(field.name, newValue);
                      }}
                    />
                  )}
                </Field>
                <FieldError name="fiscal_start_month" />
              </div>
              <div className="mb-3 flex flex-col">
                {portfolio && (
                  <div className="mt-3 flex w-full flex-row gap-x-4">
                    <label className="shrink-0 text-left font-semibold">
                      Exited
                    </label>
                    <Field name="exited">
                      {({ field, form: { setFieldValue } }: FieldProps) => (
                        <input
                          type="checkbox"
                          id="exited"
                          checked={values.exited}
                          className="ml-1 h-5 w-5 cursor-pointer rounded-full text-xs text-red-600 focus:ring-transparent"
                          {...field}
                          onChange={(e) => {
                            setFieldValue(field.name, e.currentTarget.checked);
                            setFieldValue(
                              "exited_at",
                              e.currentTarget.checked
                                ? format(new Date(), "yyyy-MM-dd")
                                : null,
                            );
                          }}
                        />
                      )}
                    </Field>
                  </div>
                )}

                {portfolio && values.exited && (
                  <div>
                    <div className="mt-2 flex w-full items-center">
                      <label className="flex w-32 shrink-0 flex-row text-left font-semibold">
                        Exit Date
                        <div className="text-sm font-semibold text-red-600">
                          *
                        </div>
                      </label>
                      <div className="w-full">
                        <div className="relative flex flex-row items-center">
                          <Field name="exited_at">
                            {({ field }: FieldProps) => (
                              <input
                                id="exited_at"
                                type="date"
                                className="rounded-md border-gray-300 text-xs"
                                {...field}
                              />
                            )}
                          </Field>
                        </div>
                      </div>
                    </div>
                    <div className="mt-4 flex w-full flex-row">
                      <div className="w-32 shrink-0 text-left">
                        <label className="mt-3 mb-1 inline-flex flex-wrap font-semibold md:mb-0">
                          <span className="w-full">
                            Exit Description
                            <span className="text-sm font-semibold text-red-600">
                              *
                            </span>
                          </span>
                        </label>
                      </div>
                      <div className="flex w-full flex-col gap-y-1">
                        <Field
                          name="exited_description"
                          type="text"
                          id="exited_description"
                          placeholder="Reason"
                          className={`w-full rounded-md border-gray-300 text-xs ${
                            touched.exited_description &&
                            errors.exited_description
                              ? "border-1 border-red-500"
                              : values.exited_description.length > 0
                                ? "border-1 border-blue-900"
                                : ""
                          }`}
                        />
                        <FieldError name="exited_description" />
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="flex w-full flex-col">
                <label className="mb-1 shrink-0 text-left font-semibold">
                  Tags
                </label>
                <Field name="tags" className="">
                  {({
                    field: { name, value },
                    form: { setFieldValue, touched, errors, setFieldTouched },
                  }: FieldProps) => (
                    <>
                      <AutocompleteMultiselect
                        id="tags"
                        placeholder="Select Tags"
                        autocompleteEndpoint="/api/people_map/autocomplete/meeting_note_tags"
                        selected={value}
                        onChange={(newValue) => setFieldValue(name, newValue)}
                        creatable={true}
                        isClearable
                        className={`${
                          touched && value?.length
                            ? "border border-blue-900!"
                            : ""
                        }`}
                        onBlur={() => {
                          if (!values.tags?.length) {
                            setFieldTouched("tags", false);
                          } else {
                            setFieldTouched("tags", true);
                          }
                        }}
                      />
                      <div
                        className={classNames(
                          touched && errors
                            ? "absolute ml-2 text-xs"
                            : "hidden",
                        )}
                      >
                        {errors.tags as any}
                      </div>
                    </>
                  )}
                </Field>
                <FieldError name="tags" />
              </div>
            </div>
            <div className="mt-6 mr-5 flex flex-row justify-end space-x-4">
              <button
                type="button"
                className={`text-xs text-blue-900`}
                onClick={() => {
                  resetForm({ values: emptyForm });
                }}
              >
                Clear Form
              </button>
              <button type="submit" className={`btn-primary`}>
                {isSubmitting ? (
                  <div className="flex flex-row items-center gap-x-2 text-white">
                    <div>
                      <Spinner className="h-4 w-4 text-white" />
                    </div>
                    <div>Saving...</div>
                  </div>
                ) : hidden === true ? (
                  "Save"
                ) : (
                  "Submit"
                )}
              </button>
            </div>
            <ConfirmationModal
              open={confirmDelete}
              title="Remove Organization from Portfolio?"
              subtitle="This action cannot be undone"
              processing={isLoading}
              processingText={"Deleting..."}
              submitButtonText={"Delete"}
              onClose={() => {
                if (setIsOpen) {
                  setIsOpen(false);
                }
                setConfirmDelete(false);
              }}
              onSubmit={() => clickDeletePortfolioOrganization(pk)}
            />
          </Form>
        )}
      </Formik>
      <ErrorModal
        open={errorModal}
        setOpen={setErrorModal}
        errorMessage={errorMessage}
      />
    </div>
  );
};

export default PortfolioForm;
