import { ColumnState } from "ag-grid-community";
import { createContext, Dispatch } from "react";
import { getOpportunityResearchResults } from "../../../../api";
import { BaseParams } from "../../../../api/types";

type translateParamsProps = {
  startRow: number | undefined;
  filterModel: { [key: string]: any };
  sortModel?: ColumnState[];
  paginationPageSize: number;
};

// build api params
export const translateParams = ({
  startRow,
  filterModel,
  sortModel,
  paginationPageSize,
}: translateParamsProps) => {
  var nextPage: number = 1;

  if (startRow) {
    nextPage = Math.floor(startRow / paginationPageSize) + 1;
  }

  const params: BaseParams = {
    page: nextPage,
    limit: paginationPageSize,
  };

  return params;
};

export const fetchOpportunityResearchResults = (
  agGridOption: any,
  OpportunityResearchResultsParams: BaseParams,
  paginationPageSize: number,
  OpportunityResearchId: number,
) => {
  getOpportunityResearchResults(
    OpportunityResearchId,
    OpportunityResearchResultsParams,
  ).then((response) => {
    if (response && !agGridOption.api.isDestroyed()) {
      const data = response.data.data;
      //set total pages
      agGridOption.api.totalPages = data.pages;
      agGridOption.api.totalItems = data.total;
      // get lastrow, Will not show loading icon in case row has no data
      const lastRow =
        data.items.length === paginationPageSize &&
        OpportunityResearchResultsParams.page < agGridOption.api.totalPages
          ? -1
          : (agGridOption.api.totalPages - 1) * paginationPageSize +
            data.items.length;

      agGridOption.success({
        rowData: data.items,
        rowCount: lastRow,
      });
    }
  });
};

export interface OpportunityResearchResultsContextData {
  refreshResult: boolean;
  setRefreshResult: Dispatch<any>;
}

// opportunity context
export const OpportunityResearchResultsContext =
  createContext<OpportunityResearchResultsContextData>({
    refreshResult: false,
    setRefreshResult: (() => undefined) as Dispatch<any>,
  });
