import { Tab } from "@headlessui/react";
import {
  ChevronRightIcon,
  ClipboardDocumentIcon,
  PencilIcon,
} from "@heroicons/react/24/outline";
import { AxiosResponse } from "axios";
import DOMPurify from "dompurify";
import { useState } from "react";
import { UserStub } from "../api/types";
import Avatar from "./Avatar";
import { MarkdownContent } from "./Chatbot/ChatbotUtils";
import ErrorModal from "./ErrorModal";
import Spinner from "./Spinner";
import { dateFormatISO } from "./utils";
import { ShowSnackBar } from "./Utils/supportMessage";

type ContentViewerProps = {
  title?: string;
  subTitle?: string;
  date: string;
  content: string;
  user?: UserStub;
  pk?: number;
  onUpdateContent?: (
    pk: number,
    content: string,
  ) => Promise<AxiosResponse<any, any>>;
  onSuccess?: () => void;
};

const RawAndMarkdownTabs = [
  {
    index: 0,
    id: "raw",
    headerName: "Raw",
  },
  {
    index: 1,
    id: "markdown",
    headerName: "Markdown",
  },
];

export const ContentViewer = ({
  title,
  subTitle,
  date,
  content,
  user,
  pk,
  onUpdateContent,
  onSuccess,
}: ContentViewerProps) => {
  const [currentTab, setCurrentTab] = useState(RawAndMarkdownTabs[0]);

  const [editingContent, setEditingContent] = useState<string>();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const [errorModal, setErrorModal] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  return (
    <div className="mt-4 flex h-fit max-h-[85vh] w-full flex-col gap-y-3 pb-4 pl-4">
      <Tab.Group>
        <div className="flex flex-col items-start gap-y-1">
          <div className="flex w-full flex-row items-center justify-between">
            <div className="flex flex-row items-center gap-x-4">
              {title && (
                <div className="flex flex-row items-center gap-x-1">
                  <div className="text-sm font-semibold">{title}</div>
                  {subTitle && (
                    <>
                      <div className="h-4 w-4">
                        <ChevronRightIcon className="h-4 w-4 stroke-2" />
                      </div>
                      <div className="text-sm font-semibold">{subTitle}</div>
                    </>
                  )}
                </div>
              )}
              <div className="font-semibold text-gray-500">
                {title ? `(${dateFormatISO(date)})` : dateFormatISO(date)}
              </div>
              <div className="flex gap-x-2">
                <div
                  className="group relative flex h-7 w-7 cursor-pointer flex-col items-center justify-center rounded-full bg-gray-100 opacity-100 hover:bg-gray-200"
                  onClick={() => {
                    navigator.clipboard.writeText(content);
                    ShowSnackBar("Copied to Clipboard!");
                  }}
                >
                  <ClipboardDocumentIcon className="h-5 w-5 cursor-pointer text-gray-400 duration-300 group-hover:text-gray-500" />
                  <div className="z-top text-2xs invisible absolute top-8 -right-1 inline-block w-max rounded bg-gray-900 px-2 py-1 font-medium text-white shadow-sm group-hover:visible">
                    Copy to clipboard
                  </div>
                </div>
                {currentTab?.index === 0 && pk && onUpdateContent && (
                  <div
                    className="group relative flex h-7 w-7 cursor-pointer flex-col items-center justify-center rounded-full bg-gray-100 opacity-100 hover:bg-gray-200"
                    onClick={() => {
                      setEditingContent(content);
                    }}
                  >
                    <PencilIcon className="h-5 w-5 cursor-pointer text-gray-400 duration-300 group-hover:text-gray-500" />
                    <div className="z-top text-2xs invisible absolute top-8 -right-1 inline-block w-max rounded bg-gray-900 px-2 py-1 font-medium text-white shadow-sm group-hover:visible">
                      Edit content
                    </div>
                  </div>
                )}
              </div>
            </div>
            <Tab.List className={"relative z-10"}>
              <div className="flex items-center">
                {RawAndMarkdownTabs.map((tab) => (
                  <Tab key={tab.id} className={"focus:outline-none"}>
                    <div
                      className={`flex h-10 w-max items-center justify-center border-b-4 px-4 pt-1 text-lg leading-7 font-normal ${
                        currentTab.id === tab.id
                          ? "border-b-blue-900 bg-blue-50 font-semibold text-blue-900"
                          : "border-transparent text-gray-900"
                      }`}
                      onClick={() => {
                        setCurrentTab(tab);
                        setEditingContent(undefined);
                      }}
                    >
                      {tab.headerName}
                    </div>
                  </Tab>
                ))}
              </div>
            </Tab.List>
          </div>
          {user && (
            <div className="flex h-full items-center justify-start">
              <Avatar
                name={user.name}
                size={32}
                url={user.image_url}
                className="hidden sm:flex"
              />
              <span className="ml-2 max-w-xs text-xs font-light text-slate-800">
                {user.name}
              </span>
            </div>
          )}
        </div>
        <Tab.Panels className={`-mr-4`}>
          <Tab.Panel>
            {editingContent && pk && onUpdateContent && onSuccess ? (
              <div className="-mb-4 flex w-full flex-col">
                <textarea
                  className="focus:ring-primary-500 focus:border-primary-500 h-[calc(75vh-10rem)] w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-xs shadow-sm"
                  value={editingContent}
                  onChange={(event) => {
                    setEditingContent(event.target.value);
                  }}
                />
                <div className="mt-3 flex w-full justify-end gap-x-2">
                  <button
                    className="btn-secondary"
                    onClick={() => {
                      setEditingContent("");
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="btn-primary"
                    onClick={() => {
                      setIsSubmitting(true);
                      onUpdateContent(pk, editingContent)
                        .then((response) => {
                          onSuccess();
                        })
                        .finally(() => setIsSubmitting(false));
                    }}
                  >
                    {isSubmitting ? (
                      <div className="flex flex-row items-center gap-x-2 text-white">
                        <div>
                          <Spinner className="h-4 w-4 text-white" />
                        </div>
                        <div>{"Submitting"}</div>
                      </div>
                    ) : (
                      "Submit"
                    )}
                  </button>
                </div>
              </div>
            ) : (
              <div
                style={{
                  maxHeight: "75vh",
                }}
                className="no-tailwindcss-base show-scrollbar no-height flex !h-[calc(100vh-16rem)] w-full border-0 pr-4 text-xs break-words whitespace-pre-wrap"
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(content, {
                    FORBID_TAGS: ["style"],
                  }),
                }}
              />
            )}
          </Tab.Panel>
          <Tab.Panel>
            <div
              style={{
                maxHeight: "75vh",
              }}
              className="no-tailwindcss-base show-scrollbar !flex !h-[calc(100vh-16rem)] w-fit flex-col gap-y-4 rounded-lg pr-4 text-xs break-words text-black"
            >
              <MarkdownContent message={content} />
            </div>
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
      <ErrorModal
        open={errorModal}
        setOpen={setErrorModal}
        errorMessage={errorMessage}
      />
    </div>
  );
};
