import { ColDef } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { OrganizationResearch } from "../../../api/types";
import { ContentViewer } from "../../ContentViewer";
import { getFormattedDateTime } from "../../DateTime";
import NewModal from "../../NewModal";
import { getContextMenuItems, useTotalBar } from "../../Utils/agGridUtils";
import { OrganizationResearchContext } from "./OrganizationResearchUtils";

type OrganizationResearchTableProps = {
  columnDefs: ColDef[];
  paginationPageSize: number;
  serverSideDataSource: any;
};

export const OrganizationResearchTable = ({
  columnDefs,
  paginationPageSize,
  serverSideDataSource,
}: OrganizationResearchTableProps) => {
  const {
    agGrid,
    setAgGrid,
    refreshOrganizationResearch,
    setRefreshOrganizationResearch,
  } = useContext(OrganizationResearchContext);

  const [showFullPrompt, setShowFullPrompt] = useState<boolean>(false);
  const [organizationResearch, setOrganizationResearch] =
    useState<OrganizationResearch>();

  const statusBar = useMemo(() => {
    return {
      statusPanels: [
        {
          statusPanel: useTotalBar,
          statusPanelParams: {
            paginationPageSize: paginationPageSize,
          },
        },
      ],
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (refreshOrganizationResearch) {
      agGrid.api.updateGridOptions({
        serverSideDatasource: serverSideDataSource,
      });
      setRefreshOrganizationResearch(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshOrganizationResearch]);

  // Initial Data
  const onGridReady = useCallback(async (params: any) => {
    setAgGrid(params);
    params.toolPanelSuppressValues = true;
    params.api.updateGridOptions({
      serverSideDatasource: serverSideDataSource,
    });
    // eslint-disable-next-line
  }, []);

  const getRowId = (params: { data: { id: string } }) =>
    params.data.id.toString();

  return (
    <div
      className={`ag-theme-quartz ag-status-bar-top mt-3`}
      style={{
        height: "88%",
        width: "100%",
      }}
    >
      <AgGridReact
        getRowId={getRowId}
        rowModelType="serverSide"
        columnDefs={columnDefs}
        defaultColDef={{
          flex: 1,
          wrapText: true,
          autoHeight: true,
          sortable: true,
          resizable: true,
          wrapHeaderText: true,
          autoHeaderHeight: true,
          cellStyle: { display: "flex", alignItems: "center" },
        }}
        columnMenu={"legacy"}
        onCellDoubleClicked={(event: any) => {
          if (["prompt"].includes(event.column.getColId())) {
            setShowFullPrompt(true);
            setOrganizationResearch(event.data);
          }
        }}
        animateRows={true}
        tooltipShowDelay={100}
        paginationPageSize={paginationPageSize}
        cacheBlockSize={paginationPageSize}
        onGridReady={onGridReady}
        maxConcurrentDatasourceRequests={4}
        alwaysShowVerticalScroll={true}
        cacheOverflowSize={paginationPageSize * 2}
        multiSortKey={"ctrl"}
        statusBar={statusBar}
        maintainColumnOrder={true}
        stopEditingWhenCellsLoseFocus={true}
        tooltipHideDelay={1000000}
        getContextMenuItems={(params) =>
          getContextMenuItems(
            params,
            `organization_research_${getFormattedDateTime()}`,
          )
        }
      ></AgGridReact>

      <NewModal
        open={showFullPrompt}
        onClose={setShowFullPrompt}
        customClass="overflow-visible!"
        expanded={false}
      >
        {organizationResearch && (
          <ContentViewer
            title={organizationResearch.name}
            date={organizationResearch.updated_on}
            content={organizationResearch.prompt}
            user={organizationResearch.updated_by}
          />
        )}
      </NewModal>
    </div>
  );
};
