export const SpinnerCustom = ({ className = "" }) => {
  return (
    <div className={`flex h-64 items-center justify-center ${className}`}>
      <div className="flex w-full max-w-sm flex-row justify-center p-2 whitespace-pre-line">
        <div className="flex flex-row gap-x-2">
          <div className="h-4 w-4 animate-[bounce_1s_infinite_0ms] rounded-full bg-blue-200"></div>
          <div className="h-4 w-4 animate-[bounce_1s_infinite_50ms] rounded-full bg-blue-300"></div>
          <div className="h-4 w-4 animate-[bounce_1s_infinite_100ms] rounded-full bg-blue-400"></div>
        </div>
      </div>
    </div>
  );
};
export default SpinnerCustom;
