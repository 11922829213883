import { AgGridReact } from "ag-grid-react";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { updateOpportunityResearchResult } from "../../../../api";
import { OpportunityResearchResult } from "../../../../api/types";
import { ContentViewer } from "../../../ContentViewer";
import NewModal from "../../../NewModal";
import {
  getFilterModel,
  getSortModel,
  paginationPageSize,
  useTotalBar,
} from "../../../Utils/agGridUtils";
import { ShowSnackBar } from "../../../Utils/supportMessage";
import { OpportunityResearchContext } from "../OpportunityResearchUtils";
import { OpportunityResearchResultsColumns } from "./OpportunityResearchResultsColumns";
import {
  fetchOpportunityResearchResults,
  OpportunityResearchResultsContext,
  translateParams,
} from "./OpportunityResearchResultsUtils";

const OpportunityResearchResultsTable = () => {
  const { OpportunityResearch } = useContext(OpportunityResearchContext);
  const { refreshResult, setRefreshResult } = useContext(
    OpportunityResearchResultsContext,
  );

  const [agGridResult, setAgGridResult] = useState<any>();
  const [showFullContent, setShowFullContent] = useState<boolean>(false);
  const [opportunityResearchResult, setOpportunityResearchResult] =
    useState<OpportunityResearchResult>();

  const statusBar = useMemo(() => {
    return {
      statusPanels: [
        {
          statusPanel: useTotalBar,
          statusPanelParams: {
            paginationPageSize: paginationPageSize,
          },
        },
      ],
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (refreshResult) {
      agGridResult.api.updateGridOptions({
        serverSideDatasource: serverSideDataSource(),
      });
      setRefreshResult(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshResult]);

  const serverSideDataSource = useCallback(
    () => {
      return {
        getRows(params: any) {
          const {
            request: { startRow },
          } = params;

          let filterModel = getFilterModel(params);
          let sortModel = getSortModel(params);

          // building api params
          var experimentParams = translateParams({
            startRow,
            filterModel: filterModel,
            sortModel: sortModel,
            paginationPageSize,
          });

          // Check if is last page, api will not call
          if (
            !params.api.totalPages ||
            (params.api.totalPages &&
              experimentParams.page <= params.api.totalPages)
          ) {
            //fetch data base on new params
            fetchOpportunityResearchResults(
              params,
              experimentParams,
              paginationPageSize,
              OpportunityResearch?.id,
            );
          }
        },
      };
      // eslint-disable-next-line
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  // Initial Data
  const onGridReady = useCallback(async (params: any) => {
    setAgGridResult(params);
    params.toolPanelSuppressValues = true;
    params.api.updateGridOptions({
      serverSideDatasource: serverSideDataSource(),
    });
    // eslint-disable-next-line
  }, []);

  const getRowId = (params: { data: { id: string } }) => params.data.id;
  return (
    <div
      className={`ag-theme-quartz ag-status-bar-top mt-3`}
      style={{
        height: "94%",
        width: "100%",
      }}
    >
      <AgGridReact
        getRowId={getRowId}
        rowModelType="serverSide"
        columnDefs={OpportunityResearchResultsColumns()}
        defaultColDef={{
          flex: 1,
          wrapText: true,
          autoHeight: true,
          sortable: true,
          resizable: true,
          wrapHeaderText: true,
          autoHeaderHeight: true,
          cellStyle: { display: "flex", alignItems: "center" },
        }}
        columnMenu={"legacy"}
        onCellDoubleClicked={(event: any) => {
          if (["content"].includes(event.column.getColId())) {
            setShowFullContent(true);
            setOpportunityResearchResult(event.data);
          }
        }}
        animateRows={true}
        tooltipShowDelay={100}
        paginationPageSize={paginationPageSize}
        cacheBlockSize={paginationPageSize}
        onGridReady={onGridReady}
        maxConcurrentDatasourceRequests={4}
        alwaysShowVerticalScroll={true}
        cacheOverflowSize={paginationPageSize * 2}
        multiSortKey={"ctrl"}
        statusBar={statusBar}
        maintainColumnOrder={true}
        stopEditingWhenCellsLoseFocus={true}
        tooltipHideDelay={1000000}
      ></AgGridReact>
      <NewModal
        open={showFullContent}
        onClose={setShowFullContent}
        customClass="overflow-visible!"
        expanded={false}
      >
        {opportunityResearchResult && (
          <ContentViewer
            title={OpportunityResearch.name}
            subTitle={opportunityResearchResult.opportunity.name}
            date={opportunityResearchResult.updated_at}
            content={opportunityResearchResult.content}
            user={opportunityResearchResult.updated_by}
            pk={opportunityResearchResult.id}
            onUpdateContent={updateOpportunityResearchResult}
            onSuccess={() => {
              setRefreshResult(true);
              setShowFullContent(false);
              setOpportunityResearchResult(undefined);
              ShowSnackBar("Content updated successfully!");
            }}
          />
        )}
      </NewModal>
    </div>
  );
};

export default OpportunityResearchResultsTable;
