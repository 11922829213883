import { PencilIcon, XCircleIcon } from "@heroicons/react/24/solid";
import { ICellRendererParams } from "ag-grid-community";
import { useState } from "react";
import { deleteOpportunityResearch } from "../../../api";
import { OpportunityResearch } from "../../../api/types";
import { ConfirmationModal } from "../../ConfirmationModal";
import NewModal from "../../NewModal";
import { getInitialGlobalGroupSelected } from "../../Utils/commons";
import { ShowSnackBar } from "../../Utils/supportMessage";
import OpportunityResearchForm from "./OpportunityResearchForm";

type ConfigModalProps = {
  params: ICellRendererParams<OpportunityResearch>;
};

const OpportunityResearchEditingPopUp = ({ params }: ConfigModalProps) => {
  const currentGroup = getInitialGlobalGroupSelected();
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <div className="h-5 w-5">
        <PencilIcon
          className="h-5 w-5 cursor-pointer fill-blue-900"
          onClick={() => {
            setIsOpen(true);
          }}
        />
      </div>
      <NewModal
        open={isOpen}
        onClose={setIsOpen}
        expanded={false}
        title={`${params.data?.name} (${currentGroup.label})`}
      >
        <OpportunityResearchForm
          OpportunityResearch={params.data}
          onSuccess={(OpportunityResearch: OpportunityResearch) => {
            params.api.applyServerSideTransaction({
              update: [OpportunityResearch],
            });
            setIsOpen(false);
          }}
        />
      </NewModal>
    </>
  );
};

const OpportunityResearchDeletePopUp = ({ params }: ConfigModalProps) => {
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const deleteOrgSearch = () => {
    if (params.data) {
      setIsLoading(true);
      deleteOpportunityResearch(params.data.id)
        .then(() => {
          setConfirmDelete(false);
          params.api.applyServerSideTransaction({ remove: [params.node] });
          ShowSnackBar(`You deleted ${params.data?.name}.`);
          setConfirmDelete(false);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => setIsLoading(false));
    }
  };
  return (
    <>
      <div className="h-5 w-5">
        <XCircleIcon
          className="h-5 w-5 cursor-pointer fill-blue-900"
          onClick={() => {
            setConfirmDelete(true);
          }}
        />
      </div>
      <ConfirmationModal
        open={confirmDelete}
        title={`Are you sure to delete ${params.data?.name}?`}
        subtitle="This action cannot be undone"
        processing={isLoading}
        processingText={"Deleting..."}
        submitButtonText={"Delete"}
        onClose={() => {
          setConfirmDelete(false);
        }}
        onSubmit={deleteOrgSearch}
      />
    </>
  );
};

export const OpportunityResearchNameRenderer = (
  params: ICellRendererParams<OpportunityResearch>,
) => {
  if (params.data) {
    return (
      <div className="flex w-full flex-row items-center gap-x-4">
        <div className="flex h-5 w-5 flex-row gap-x-1">
          <OpportunityResearchEditingPopUp params={params} />
          <OpportunityResearchDeletePopUp params={params} />
        </div>
        <div
          className="ml-5 flex w-full cursor-pointer flex-row items-center gap-x-2 text-blue-700"
          onClick={() => {
            document.dispatchEvent(
              new CustomEvent("SHOW_Opportunity_RESEARCH_RESULTS", {
                detail: {
                  showOpportunityResearchResults: true,
                  OpportunityResearch: params.data,
                },
              }),
            );
          }}
        >
          {params.data?.name}
        </div>
      </div>
    );
  } else {
    return <></>;
  }
};
