import {
  ArchiveBoxIcon,
  ClipboardIcon,
  FireIcon,
  QuestionMarkCircleIcon,
} from "@heroicons/react/24/outline";
import Link from "next/link";
import { useRouter } from "next/router";
import useSWR from "swr";
import ActivityIcon from "./Icons/ActivityIcon";
import { Llama } from "./Llama";
import ProfileMenu from "./ProfileMenu";
import { classNames } from "./utils";
import { setNavigation } from "./Utils/commons";
import { GLOBAL_GROUP_KEY } from "./Utils/constant";

type GlobalGroupProps = {
  label: string;
  value: string;
};

const DropDownMenu = () => {
  return <ProfileMenu navOpen={true} />;
};

type SideBarProps = {
  closeSidebar: () => void;
};

const Sidebar = ({ closeSidebar }: SideBarProps) => {
  const { data: selectedGroup } = useSWR<GlobalGroupProps>(GLOBAL_GROUP_KEY);

  const router = useRouter();
  const envString = `${process.env.NEXT_PUBLIC_LABS_INSTANCE}` || "default";

  var updatedNavigation = setNavigation("default");

  if (selectedGroup) {
    updatedNavigation = setNavigation(selectedGroup.label);
  }

  const current = updatedNavigation.find(
    (item) =>
      item?.href === router.pathname ||
      item.prefixes?.includes(router.pathname),
  );

  return (
    <div className="sm:max-w flex h-full flex-col justify-between bg-white py-3 text-blue-900 lg:max-w-xs">
      <img
        src="/labs-icon-blue-900-transparent.png"
        alt="Samurai logo"
        className="mt-2 mb-5 ml-[30px] h-[60px] w-[102px] cursor-pointer pt-2"
        onClick={() =>
          router.push(
            envString === "vvseai" || envString === "vvhc" ? "/dealflow" : "/",
          )
        }
      />
      <div className="no-scrollbar flex grow flex-col overflow-x-auto">
        <div className="justify-between">
          <nav className="flex-1 lg:space-y-6">
            {updatedNavigation.map((item) => (
              <Link
                href={item.href}
                key={item.key}
                className={classNames(
                  item.key === current?.key
                    ? "border-blue-900 font-medium text-white"
                    : `border-transparent font-light text-blue-900`,
                  "flex items-center gap-x-2 border-l-4 px-3 py-2 text-xs font-light font-medium tracking-wide lg:text-sm",
                )}
                onClick={closeSidebar}
              >
                <div
                  className={`flex h-9 w-9 flex-col items-center justify-center duration-300 ${
                    item.key === current?.key
                      ? "rounded-full bg-blue-900"
                      : "hover:rounded-full hover:bg-white/50 hover:text-black"
                  }`}
                >
                  <item.icon className="h-6 w-6 shrink-0" aria-hidden="true" />
                </div>
                <div
                  className={`origin-left text-center text-blue-900 ${
                    item.key === current?.key ? "font-semibold" : "font-normal"
                  }`}
                >
                  {item.name}
                </div>
              </Link>
            ))}
          </nav>
          <div>
            <div className="justify-left mt-6 flex items-baseline px-3 text-left text-xs">
              <div className="flex flex-1 cursor-pointer">
                <div
                  className="group relative flex w-full flex-col items-center"
                  onClick={() => {
                    window.sessionStorage.setItem("showValStatus", "true");
                    window.dispatchEvent(new Event("storage"));
                    closeSidebar();
                  }}
                >
                  <Llama
                    customClass="w-6 h-6 mr-2"
                    svgCustomClass="-mt-1"
                    color="rgb(30 58 138 / 1)"
                  />
                  <div className="text-3xs ml-1 text-center text-blue-900">
                    Ask Val
                  </div>
                </div>
              </div>
              <Link href={`/collab`} className="flex flex-1">
                <div className="group relative flex h-[41px] w-full flex-col items-center justify-end">
                  <div>
                    <ArchiveBoxIcon className="h-6 w-6 stroke-1 text-blue-900" />
                  </div>
                  <div className="text-3xs text-blue-900">Collab</div>
                </div>
              </Link>
              <Link href={`/tasks`} className="flex flex-1">
                <div className="group relative flex h-[41px] w-full flex-col items-center justify-end">
                  <ClipboardIcon className="h-6 w-6 stroke-1 text-blue-900" />
                  <div className="text-3xs text-blue-900">Tasks</div>
                </div>
              </Link>
              <Link href={`/activity_logs`} className="flex flex-1">
                <div className="group relative flex h-[41px] w-full flex-col items-center justify-end">
                  <ActivityIcon className="h-6 w-6 fill-blue-900 stroke-1" />
                  <div className="text-3xs text-blue-900">Activity</div>
                </div>
              </Link>
              <Link href={`/help`} className="flex flex-1">
                <div className="group relative flex h-[41px] w-full flex-col items-center justify-end">
                  <QuestionMarkCircleIcon className="h-6 w-6 stroke-1 text-blue-900 text-gray-500" />
                  <div className="text-3xs text-blue-900">Help</div>
                </div>
              </Link>
              <Link href={`/changelog`} className="flex flex-1">
                <div className="group relative flex h-[41px] w-full flex-col items-center justify-end">
                  <FireIcon className="h-6 w-6 shrink-0 stroke-1 text-blue-900 text-gray-500" />
                  <div className="text-3xs text-center text-blue-900">
                    Change Log
                  </div>
                </div>
              </Link>
            </div>
          </div>
          <div className="justify-left mt-6 ml-6 flex items-center text-left text-xs">
            <DropDownMenu />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
