import { Dialog } from "@headlessui/react";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import React, { PropsWithChildren } from "react";
import NewModal from "./NewModal";

type ModalProps = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  errorMessage?: string;
};

export const ErrorModal = ({
  open,
  setOpen = () => {},
  errorMessage,
  children,
}: PropsWithChildren<ModalProps>) => {
  return (
    <NewModal open={open} onClose={setOpen} expanded={false}>
      <div className="sm:flex sm:items-start">
        <div className="mx-auto flex h-12 w-12 shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
          <ExclamationTriangleIcon
            className="h-6 w-6 text-red-600"
            aria-hidden="true"
          />
        </div>
        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
          <Dialog.Title className="text-sm leading-6 font-semibold text-gray-900">
            Error
          </Dialog.Title>
          <div className="mt-2">
            <p className="text-xs text-gray-500">{errorMessage}</p>
          </div>
          {children}
        </div>
      </div>
    </NewModal>
  );
};

export default ErrorModal;
