import { AxiosError } from "axios";

import {
  ErrorMessage,
  ErrorMessageProps,
  Field,
  FieldProps,
  Form,
  Formik,
  FormikErrors,
} from "formik";
import { useEffect, useState } from "react";
import {
  createOpportunityResearch,
  getPersonaTools,
  getValModels,
  updateOpportunityResearch,
} from "../../../api";
import {
  OpportunityResearch,
  OpportunityResearchParams,
  Option,
  PersonaTool,
} from "../../../api/types";
import ErrorModal from "../../ErrorModal";
import FormTextAreaField from "../../Form/FormTextAreaField";
import FormTextField from "../../Form/FormTextField";
import Select from "../../Select/Select";
import Spinner from "../../Spinner";
import Tooltip from "../../Tooltip";
import { parseAxiosError } from "../../utils";
import { getInitialGlobalGroupSelected } from "../../Utils/commons";

interface FieldErrorProps extends ErrorMessageProps {}

const FieldError = ({ name }: FieldErrorProps) => (
  <ErrorMessage name={name}>
    {(error) => <div className="text-2xs mt-1 text-red-600">{error}</div>}
  </ErrorMessage>
);

export type OpportunityResearchFormValues = {
  name: string;
  prompt: string;
  persona: string;
  model: Option | null;
  tools: string[];
  group: Option | null;
};

type OpportunityResearchFormProps = {
  OpportunityResearch?: OpportunityResearch;
  onSuccess: (OpportunityResearch: OpportunityResearch) => void;
};

const OpportunityResearchForm = ({
  OpportunityResearch,
  onSuccess,
}: OpportunityResearchFormProps) => {
  const currentGroup = getInitialGlobalGroupSelected();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [tools, setTools] = useState<PersonaTool[]>([]);
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [models, setModels] = useState<Option[]>([]);

  const defaultFormValue: OpportunityResearchFormValues = {
    name: "",
    prompt: "",
    persona: "",
    model: null,
    tools: [],
    group: currentGroup,
  };

  const OpportunityResearchFromValues = (
    OpportunityResearch: OpportunityResearch,
  ): OpportunityResearchFormValues => {
    return {
      name: OpportunityResearch.name,
      prompt: OpportunityResearch.prompt,
      persona: OpportunityResearch.persona,
      model:
        models.filter(
          (model) => model.value === OpportunityResearch.model,
        )[0] ?? null,
      tools: OpportunityResearch.tools,
      group: {
        label: OpportunityResearch.group.name,
        value: OpportunityResearch.group.id,
      },
    };
  };

  let initialValues = OpportunityResearch
    ? OpportunityResearchFromValues(OpportunityResearch)
    : defaultFormValue;

  useEffect(() => {
    getPersonaTools()
      .then((response) => {
        if (response.data) {
          setTools(response.data.data);
        }
      })
      .catch((error: AxiosError) => {
        setErrorMessage(parseAxiosError(error));
        setShowErrorModal(true);
      });
    getValModels()
      .then((res: any) => {
        const models: Option[] = [];
        res.data.forEach((model: any) => {
          const temp = {
            value: model.model_name,
            label: model.name,
          };
          models.push(temp);
        });
        setModels(models);
        if (OpportunityResearch) {
          initialValues.model =
            models.filter(
              (model) => model.value === OpportunityResearch.model,
            )[0] ?? null;
        }
      })
      .catch((error: AxiosError) => {
        setErrorMessage(parseAxiosError(error));
        setShowErrorModal(true);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = (values: OpportunityResearchFormValues) => {
    setIsSubmitting(true);

    let payload: OpportunityResearchParams = {
      name: values.name,
      prompt: values.prompt,
      persona: values.persona,
      model: values.model?.value as string,
      tools: values.tools,
      group: Number(values.group?.value),
    };

    const apiCaller = OpportunityResearch
      ? updateOpportunityResearch(OpportunityResearch.id, payload)
      : createOpportunityResearch(payload);

    apiCaller
      .then((res) => {
        if (res.data) {
          onSuccess(res.data.data);
        }
      })
      .catch((error: AxiosError) => {
        setErrorMessage(parseAxiosError(error));
        setShowErrorModal(true);
        setIsSubmitting(false);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const validate = (values: OpportunityResearchFormValues) => {
    let errors: FormikErrors<OpportunityResearchFormValues> = {};

    if (!values.name) {
      errors.name = "Name is required";
    }

    if (!values.prompt) {
      errors.prompt = "Prompt is required";
    }

    if (!values.persona) {
      errors.persona = "Persona is required";
    }

    if (!values.model) {
      errors.model = "Model is required";
    }

    return errors;
  };
  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validate={validate}
        enableReinitialize
      >
        {({ errors, touched, values, setFieldTouched, setFieldValue }) => (
          <Form className="mt-5 flex flex-col gap-y-3">
            <Field
              as={FormTextField}
              name="name"
              label="Name"
              customClass="w-full!"
              errors={errors.name}
              touched={touched.name}
              required
            />

            <div className="w-full text-xs">
              <label className="mb-0.5 flex w-36 items-center">
                <div className="text-xs font-semibold">Models</div>
                <div className="text-sm font-semibold text-red-600">*</div>
              </label>
              <Field name="model">
                {({ field, form: { setFieldValue } }: FieldProps) => {
                  return (
                    <Select
                      value={values.model}
                      className={`rounded-md border-1 text-xs ${
                        touched.model
                          ? errors.model
                            ? "border-red-500"
                            : values.model
                              ? "border-blue-900"
                              : "border-gray-300"
                          : "border-gray-300"
                      }`}
                      options={models}
                      onChange={(newValue: any) => {
                        setFieldValue("model", newValue);
                      }}
                      onBlur={() => {
                        setFieldTouched("model", true);
                      }}
                    />
                  );
                }}
              </Field>
              <FieldError name="model" />
            </div>
            <Field
              as={FormTextAreaField}
              name="persona"
              label="Persona"
              rows={3}
              errors={errors.persona}
              touched={touched.persona}
              required
            />
            <Field
              as={FormTextAreaField}
              name="prompt"
              label="Prompt"
              rows={10}
              errors={errors.prompt}
              touched={touched.prompt}
              required
            />
            <div className="mt-3 flex w-full flex-col gap-y-2">
              <div className="flex text-xs font-semibold">Tools</div>
              <div className="grid grid-cols-1 gap-3 md:grid-cols-2">
                {tools.map((tool: PersonaTool) => (
                  <div
                    key={tool.name}
                    className="flex flex-row items-center justify-between gap-x-5 md:justify-start"
                  >
                    <div className="flex flex-1 flex-row items-center gap-x-2">
                      <div className="w-fit text-left">{tool.display}</div>
                      <div className="h-4 w-4">
                        <Tooltip content={tool.description} />
                      </div>
                    </div>
                    <label className="relative inline-flex flex-1 cursor-pointer items-center">
                      <input
                        type="checkbox"
                        checked={values.tools.includes(tool.name)}
                        className="peer sr-only"
                        onChange={(event) => {
                          setFieldValue(
                            "tools",
                            event.target.checked
                              ? [...values.tools, tool.name]
                              : values.tools.filter(
                                  (item: string) => item !== tool.name,
                                ),
                          );
                        }}
                      />
                      <div className="peer h-5 w-9 rounded-full bg-gray-200 peer-checked:bg-blue-900 peer-focus:ring-0 peer-focus:outline-hidden after:absolute after:top-[2px] after:left-[2px] after:h-4 after:w-4 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:after:translate-x-full peer-checked:after:border-white"></div>
                    </label>
                  </div>
                ))}
              </div>
            </div>
            <div className="flex w-full flex-row justify-end gap-x-2">
              <button className="btn-primary">
                {isSubmitting ? (
                  <div className="flex flex-row items-center gap-x-2 text-white">
                    <div>
                      <Spinner className="text-blue h-4 w-4" />
                    </div>
                    <div className="">Submitting</div>
                  </div>
                ) : (
                  "Submit"
                )}
              </button>
            </div>
          </Form>
        )}
      </Formik>
      <ErrorModal
        open={showErrorModal}
        setOpen={setShowErrorModal}
        errorMessage={errorMessage}
      />
    </>
  );
};

export default OpportunityResearchForm;
