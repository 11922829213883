import { Popover, Transition } from "@headlessui/react";
import {
  BuildingOfficeIcon,
  ChevronDownIcon,
  DocumentIcon,
  GiftIcon,
  PlusIcon,
  RocketLaunchIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";
import router from "next/router";
import { Fragment } from "react";
import { useMediaQuery } from "react-responsive";

const CreateDropDownMenu = () => {
  const isTabletOrDesktop = useMediaQuery({
    minWidth: 769,
  });

  return (
    <Popover className="relative">
      {({ open, close }) => (
        <>
          <Popover.Button className="flex items-center outline-hidden">
            {isTabletOrDesktop ? (
              <div className="flex h-8 flex-row items-center">
                <div
                  className="text-2xs flex h-full w-max flex-row items-center rounded-l-lg bg-blue-900 px-3 font-medium text-white md:text-xs"
                  onClick={(event) => {
                    event.stopPropagation();
                    router.push("/opportunity/create");
                  }}
                >
                  Create Opportunity
                </div>
                <div className="flex h-full items-center rounded-r-lg border-l-2 border-white bg-blue-900 px-2">
                  <ChevronDownIcon
                    className="h-4 w-4 fill-white text-white"
                    id="create-dropdown-menu-desktop"
                  />
                </div>
              </div>
            ) : (
              <PlusIcon
                className="h-5 w-5 text-blue-900"
                id="create-dropdown-menu-mobile"
              />
            )}
          </Popover.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute top-11 right-0 w-max rounded-md bg-white p-1 text-black shadow-lg sm:z-101!">
              <button
                className={`group flex w-full items-center rounded-md px-2 py-2 text-sm hover:bg-blue-100 hover:text-blue-900`}
                onClick={() => {
                  router.push("/opportunity/create");
                  close();
                }}
              >
                <GiftIcon className="mr-2 h-5 w-5" aria-hidden="true" />
                Create Opportunity
              </button>
              <button
                className={`group flex w-full items-center rounded-md px-2 py-2 text-sm hover:bg-blue-100 hover:text-blue-900`}
                onClick={() => {
                  router.push("/people/create");
                  close();
                }}
              >
                <UsersIcon className="mr-2 h-5 w-5" aria-hidden="true" />
                Create Contact
              </button>
              <button
                className={`group flex w-full items-center rounded-md px-2 py-2 text-sm hover:bg-blue-100 hover:text-blue-900`}
                onClick={() => {
                  router.push("/organizations/create");
                  close();
                }}
              >
                <BuildingOfficeIcon
                  className="mr-2 h-5 w-5"
                  aria-hidden="true"
                />
                Create Organization
              </button>
              <button
                className={`group flex w-full items-center rounded-md px-2 py-2 text-sm hover:bg-blue-100 hover:text-blue-900`}
                onClick={() => {
                  router.push("/meeting_notes/create");
                  close();
                }}
              >
                <DocumentIcon className="mr-2 h-5 w-5" aria-hidden="true" />
                Create Note
              </button>
              <button
                className={`group flex w-full items-center rounded-md px-2 py-2 text-sm hover:bg-blue-100 hover:text-blue-900`}
                onClick={() => {
                  router.push("/gtm_project/create");
                  close();
                }}
              >
                <RocketLaunchIcon className="mr-2 h-5 w-5" aria-hidden="true" />
                Create GTM Project
              </button>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};
export default CreateDropDownMenu;
