import {
  ArrowPathIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { OrganizationResearch } from "../../../api/types";
import NewModal from "../../NewModal";
import {
  getFilterModel,
  getSortModel,
  paginationPageSize,
  UPDATE_GRID_OPTIONS_DELAY,
} from "../../Utils/agGridUtils";
import { getInitialGlobalGroupSelected } from "../../Utils/commons";
import { OrganizationResearchColumns } from "./OrganizationResearchColumns";
import OrganizationResearchForm from "./OrganizationResearchForm";
import { OrganizationResearchTable } from "./OrganizationResearchTable";
import {
  fetchOrganizationResearchs,
  OrganizationResearchContext,
  translateParams,
} from "./OrganizationResearchUtils";
import { OrganizationResearchResultsView } from "./Results/OrganizationResearchResultsView";

export const OrganizationResearchView = () => {
  const currentGroup = getInitialGlobalGroupSelected();
  const [query, setQuery] = useState<string>("");
  const [agGrid, setAgGrid] = useState<any>();
  const inputRef = useRef<HTMLInputElement>(null);

  const [showOrganizationResearchResults, setShowOrganizationResearchResults] =
    useState<false>();
  const [organizationResearch, setOrganizationResearch] =
    useState<OrganizationResearch>();

  const [refreshOrganizationResearch, setRefreshOrganizationResearch] =
    useState<boolean>(false);

  const receiveMessage = (e: any) => {
    setShowOrganizationResearchResults(
      e.detail.showOrganizationResearchResults,
    );
    setOrganizationResearch(e.detail.organizationResearch);
  };

  useEffect(() => {
    document.addEventListener(
      "SHOW_ORGANIZATION_RESEARCH_RESULTS",
      receiveMessage,
    );

    return () => {
      document.removeEventListener(
        "SHOW_ORGANIZATION_RESEARCH_RESULTS",
        receiveMessage,
      );
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //New Organization Research
  const [showCreateOrganizationResearch, setShowCreateOrganizationResearch] =
    useState(false);

  // Search bar
  useEffect(() => {
    if (agGrid && inputRef && inputRef.current) {
      //filter opportunity
      const filterOpportunity = (event: any) => {
        const timeout = setTimeout(() => {
          if (agGrid.api.searchValue !== event.target.value) {
            setQuery(event.target.value);
            agGrid.api.searchValue = event.target.value;
            agGrid.api.updateGridOptions({
              serverSideDatasource: serverSideDataSource(event.target.value),
            });
          }
        }, UPDATE_GRID_OPTIONS_DELAY);
        return () => clearTimeout(timeout);
      };

      //init values
      inputRef.current.value = query as string;
      inputRef.current.addEventListener("keyup", filterOpportunity);

      //remove event
      return () => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        inputRef.current?.removeEventListener("keyup", filterOpportunity);
      };
    }
    // eslint-disable-next-line
  }, [inputRef, agGrid]);

  const serverSideDataSource = useCallback(
    (queryString = "") => {
      return {
        getRows(params: any) {
          const {
            request: { startRow },
          } = params;

          let filterModel = getFilterModel(params);
          let sortModel = getSortModel(params);

          // building api params
          var experimentParams = translateParams({
            startRow,
            filterModel: filterModel,
            sortModel: sortModel,
            paginationPageSize,
            queryString: queryString,
          });

          // Check if is last page, api will not call
          if (
            !params.api.totalPages ||
            (params.api.totalPages &&
              experimentParams.page <= params.api.totalPages)
          ) {
            //fetch data base on new params
            fetchOrganizationResearchs(
              params,
              experimentParams,
              paginationPageSize,
            );
          }
        },
      };
      // eslint-disable-next-line
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const value = useMemo(
    () => ({
      refreshOrganizationResearch,
      setRefreshOrganizationResearch,
      organizationResearch,
      agGrid,
      setAgGrid,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [agGrid, organizationResearch, refreshOrganizationResearch],
  );

  return (
    <OrganizationResearchContext.Provider value={value}>
      <div className="h-full p-3">
        <div
          className={`${showOrganizationResearchResults ? "" : "hidden"} flex h-full w-full`}
        >
          {organizationResearch && (
            <OrganizationResearchResultsView
              organizationResearch={organizationResearch}
            />
          )}
        </div>
        <div
          className={`flex h-full flex-col ${showOrganizationResearchResults ? "hidden" : ""}`}
        >
          <div className="flex flex-row items-center justify-between">
            <h1 className="text-xl font-bold">Organization Research</h1>
            <div className="flex flex-row items-center gap-x-2">
              <div className="group relative h-7 w-7 rounded-full bg-yellow-200 p-1">
                <ArrowPathIcon
                  className="h-5 w-5 cursor-pointer"
                  onClick={() => setRefreshOrganizationResearch(true)}
                />
                <div className="z-top text-2xs invisible absolute top-8 right-0 inline-block w-max rounded-lg bg-gray-900 px-2 py-1 font-medium text-white shadow-sm group-hover:visible">
                  Reload Organization Research
                </div>
              </div>
              <button
                className="btn-primary w-fit"
                onClick={() => setShowCreateOrganizationResearch(true)}
              >
                New Organization Research
              </button>
            </div>
          </div>

          <div className="relative mt-5 flex">
            <MagnifyingGlassIcon
              className="absolute top-2/4 left-2 h-4 w-4 -translate-y-2/4 transform text-gray-400 hover:text-blue-900"
              aria-hidden="true"
            />
            <input
              ref={inputRef}
              className="peer block h-8 w-full truncate rounded-full border border-gray-400 pr-9 pl-9 text-xs hover:border-blue-900 focus:outline-hidden"
              placeholder="Search for Organization Research"
            />
          </div>
          <OrganizationResearchTable
            paginationPageSize={paginationPageSize}
            columnDefs={OrganizationResearchColumns()}
            serverSideDataSource={serverSideDataSource(query)}
          />
        </div>
      </div>
      <NewModal
        title={`Create New Organization Research (${currentGroup.label})`}
        onClose={setShowCreateOrganizationResearch}
        open={showCreateOrganizationResearch}
      >
        <OrganizationResearchForm
          onSuccess={() => {
            agGrid.api.refreshServerSide({ purge: true });
            setShowCreateOrganizationResearch(false);
          }}
        />
      </NewModal>
    </OrganizationResearchContext.Provider>
  );
};
